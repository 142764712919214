// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function ProjectInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);
  const [warehouseValue, setWarehouseValue] = useState(null);
  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  const [warehouseList, setWarehouseList] = useState("");

  const [parenthierachicalValue, setparenthierachicalValue] = useState(null);
  const [parenthierachicalList, setparenthierachicalList] = useState("");
  const { formField, values, errors, touched } = formData;

  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option?.state?.name + " (" + option?.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option?.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option?.subdistrict?.name + " (" + option?.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentpincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option?.village?.name + " (" + option?.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparenthierachical = {
    options: !parenthierachicalList
      ? [{ name: "Loading...", id: 0 }]
      : parenthierachicalList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropswarehouse ={
    options: !warehouseList
    ? [{ name: "Loading...", id: 0 }]
    : warehouseList,
  getOptionLabel: (option) => option?.name,
  isOptionEqualToValue: (option, value) => option.id === value.id,
  }

  const {
    name,
    description,
    state,
    district,
    subDistrict,
    pincode,
    projecthirarichalcode,
    warehouse
  } = formField;
  var {
    name: nameV,
    description: descriptionV,
    pincode: pincodeV,
    parenthierachical: parenthierachicalV,
  } = values;
  async function getData() {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setparenthierachicalList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
      // warehouse list------------------
      await axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
      )
      .then((response) => {
        setWarehouseList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
      
  }

  async function getDistrictData(stateCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }



  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Project</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
          </Grid>
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparenthierachical}
                value={parenthierachicalValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setparenthierachicalValue(newValue);

                  values.projecthirarichalcode =
                    newValue?.projecthirarichalcode;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={projecthirarichalcode.label}
                  />
                )}
              />
            </Grid>
            
             <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropswarehouse}
                value={warehouseValue}              
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setWarehouseValue(newValue);

                  values.warehouse = newValue;
                  console.log("337",newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={warehouse.label}
                  />
                )}
              />
            </Grid>
            </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  getDistrictData(newValue?.state?.code);
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label={state.label} />
                )}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={(event, newValue) => {
                  setdistrictValue(newValue);
                  getSubDistrictData(
                    newValue?.state?.code,
                    newValue?.district?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={district.label}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  setSubDistrictValue(newValue);
                  getVillageData(
                    newValue?.state?.code,
                    newValue?.district?.code,
                    newValue?.subdistrict?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={subDistrict.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentpincode}
                value={pincodeValue}
                onChange={(event, newValue) => {
                  setPincodeValue(newValue);
                  values.pincode = newValue;
                  console.log("village", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={pincode.label}
                  />
                )}
              />
            </Grid>
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectInfo
ProjectInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectInfo;

