import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
// @mui material components
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
const initialValues = {
  state: "",
  districname: "",
  shortname: "",
  districcode: ""
}

const Districcomponet = (props) => {
  const { tittle, children,childToDistric, openpopupdistric, setopenpopupdistric } = props
  const [stateValue, setStateValue] = useState(null);
  const [stateList, setStateList] = useState("");
  
  function autocompleteValuenull(){
   
    setStateValue(null);
    
  }

  async function createDistric(pinCodeObject) {
    axios
      .post(
        "pincode-service/api/pincode/v1/create",
        pinCodeObject
      )

      .then((response) => {
        console.log("gfgfgf", response);

        setopenpopupdistric(false)
        autocompleteValuenull()
        if (response) {
          console.log("40", response.data.data.dto)
          childToDistric(response.data.data.dto)

        }




      })
      .catch((error) => {
        console.log(error);


      });

  }


  const defaultPropsState = {
    options: !stateList
      ? [
        {
          state: {
            shortname: "- -",
            name: "Loading...",
          },
          id: 0,
        },
      ]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log("26", values)
      const pinCodeObject = {
        id: null,
        createdby:
          JSON.parse(localStorage.getItem("mmu-auth")).firstname +
          " " +
          JSON.parse(localStorage.getItem("mmu-auth")).lastname,

        modifiedby: "",
        approvedby: "",

        recstatus: "OPEN",
        syncstatus: "synced",
        status: "ACTIVE",
        documentofproof: "",

        tempid: "",
        state: values.state,
        district: {
          code: values.districcode,
          shortname: values.shortname,
          name: values.districname,
        },
        village: null,
        pincode: values.pincode,

        resourcecode: "DPF",
        type: "DISTRICT",
      };
      createDistric(pinCodeObject)
      swal("Alert!", "...your Distric has been sucessfully create!", "success")
        .then((value) => {
          // window.location.reload(true)

          action.resetForm();

        });

    }
  })



  const handleClose = () => {
    console.log("dsfsdfs");
    setopenpopupdistric(false);
  };

  async function getData() {
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=state`
      )
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);

      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={openpopupdistric} onClose={handleClose} >
      <MDBox mx={2} mt={2}>

        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-2}
          p={2}
          mb={-2}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="small"
            color="white"
            mt={1}
          >
            Distric
          </MDTypography>
        </MDBox>
      </MDBox>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ width: 550, height: 170 }}>
          <Grid container spacing={3} mt={.5}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  values.state = newValue?.state;

                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type="text"
                    autocomplete="off"
                    name="state"
                    label="State"
                    id="state"

                    onChange={handleChange}
                    onBlur={handleBlur}

                    variant="standard"


                  />
                )}
              />
            </Grid>


            <Grid item xs={12} sm={6}>
              <MDInput

                type="text"
                autocomplete="off"
                name="districname"
                label="Districname"
                id="districname"
                variant="standard"
                fullWidth
                value={values.districname}
                onChange={handleChange}
                onBlur={handleBlur}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3} mt={.5}>
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                name="shortname"
                label="Shortname"
                id="shortname"
                variant="standard"
                fullWidth
                value={values.shortname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                name="districcode"
                label="Districcode"
                variant="standard"
                fullWidth
                value={values.districcode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >create</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}










export default Districcomponet;