// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function LabInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [value, setValue] = React.useState(null);
  const [mmuList, setMmuList] = useState("");
  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { testName, testResult, presId, mmu, detailResult } = formField;
  var {
    testName: testNameV,
    testResult: testResultV,
    presId: presIdV,
  
    detailResult: detailResultV,
  } = values;

  async function getData() {
    await axios
      .get(`mmucreation-service/api/MMUCreation/v1/get`)
      .then((response) => {
        setMmuList(response?.data?.data?.dtoList);

        setLoading(false);
     
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Lab Test</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={testName.type}
                label={testName.label}
                name={testName.name}
                value={testNameV}
                placeholder={testName.placeholder}
                error={errors.testName && touched.testName}
                success={testNameV.length > 0 && !errors.testName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={testResult.type}
                label={testResult.label}
                name={testResult.name}
                value={testResultV}
                placeholder={testResult.placeholder}
                error={errors.TestResult && touched.testResult}
                success={testResultV.length > 0 && !errors.testResult}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={presId.type}
                label={presId.label}
                name={presId.name}
                value={presIdV}
                placeholder={presId.placeholder}
                error={errors.presId && touched.presId}
                success={presIdV.length > 0 && !errors.presId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/*<select value={value} onChange={handleChange}>
              <option value="fruit">Fruit</option>
              <option value="vegetable">Vegetable</option>
              <option value="meat">Meat</option>
            </select>*/}

              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);

                  values.mmu = newValue?.code;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.mmu && touched.mmu}
                    success={mmu.length > 0 && !errors.mmu}
                    variant="standard"
                    label={mmu.label}
                  />
                )}
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
  </Grid>*/}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={detailResult.type}
                label={detailResult.label}
                name={detailResult.name}
                value={detailResultV}
                placeholder={detailResult.placeholder}
                error={errors.detailResult && touched.detailResult}
                success={detailResultV.length > 0 && !errors.detailResult}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for LabInfo
LabInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LabInfo;
