// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import AddBoxIcon from '@mui/icons-material/AddBox';
import Projectcomponet from "./Projectcomponet";
import Statecomponent from "./Statecomponent";
import Districcomponet from "./Districcomponet";
import Subdistriccomponet from "./Subdistriccomponet";
import Pincodecomponent from "./Pincodecomponent";

function PincodeprojectInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [projectValue, setProjectValue] = useState(null);
  const [projectList, setProjectList] = useState("");

  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);

  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupstate, setopenpopupstate] = useState(false);
  const [openpopupdistric, setopenpopupdistric] = useState(false);
  const [openpopupsubdistric, setopenpopupsubdistric] = useState(false);
  const [openpopuppincode, setopenpopuppincode] = useState(false);

  ///state object============
  function childToparent(data) {
    console.log("51", data)
    stateList.push(data)
  }
  // project object===============
function childToProject(data){
  console.log("project", data)
  projectList.push(data)
}
// distric object=================
function childToDistric(data){
  console.log("distric", data)
  districtList.push(data)
}
// sub-distric object========
function childToSubdistric(data){
  console.log("sub-distric", data)
  subDistrictList.push(data)
}
// pincode object==========
function childTopincode(data){
  console.log("pincode", data)
  pincodeList.push(data)
}
  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentpincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsProject = {
    options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const dataObj = {
    columns: [
      { Header: "project", accessor: "projectname" },
      { Header: "State", accessor: "pincode.state.name" },
      { Header: "district", accessor: "pincode.district.name" },
      { Header: "Sub-district", accessor: "pincode.subdistrict.name" },
      { Header: "village", accessor: "pincode.village.name" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  const removeItem = function (id) {
    const objWithIdIndex = tableData.rows.findIndex(
      (obj) => obj.pincode.id === id
    );
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.projectPinCodeList = tableData.rows;
  };
  const { formField, values, errors, touched } = formData;

  const {
    createproject,
    projectselection,
    parenthierachical,
    state,
    district,
    subDistrict,
    pincode,
  } = formField;
  var { address: addressV, createproject: createprojectV } = values;

  async function getDistrictData(stateCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const addData = () => {
    console.log("69", projectValue);

    const projectPinCode = {
      projectcode: projectValue?.code,
      projectname: projectValue?.name,
      pincode: pincodeValue,
    };
    projectPinCode.action = (
      <span>
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeItem(projectPinCode?.pincode?.id)}
          />
        </Link>
      </span>
    );

    let filter = tableData.rows.filter(
      (a) => a.pincode.id == projectPinCode.pincode.id
    );
    console.log("181", projectPinCode);
    if (filter.length < 1) {
      tableData.rows.push(projectPinCode);

      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.projectPinCodeList = tableData.rows;
      console.log("value", tableData.rows);
    }
  };
  async function getData() {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setProjectList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Project</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                {...defaultPropsProject}
                value={projectValue}
                onChange={(event, newValue) => {
                  setProjectValue(newValue);
                  values.project = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={projectselection.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1}>

              <span>
                <Link to={{}}>
                  <AddBoxIcon
                    fontSize="large"
                    onClick={() => {
                      setopenpopup(true);
                    }}
                  />
                </Link>
              </span>


            </Grid>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  if(newValue!=null){
                    getDistrictData(newValue?.state?.code);
                  }
                  
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label={state.label} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1}>

              <span>
                <Link to={{}}>
                  <AddBoxIcon
                    fontSize="large"
                    onClick={() => {
                      setopenpopupstate(true);
                    }}
                  />
                </Link>
              </span>


            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={(event, newValue) => {
                  setdistrictValue(newValue);
                  if(newValue!=null){
                    getSubDistrictData(
                    newValue?.state?.code,
                    newValue?.district?.code
                  );
                  }
                 
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={district.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1}>

              <span>
                <Link to={{}}>
                  <AddBoxIcon
                    fontSize="large"
                    onClick={() => {
                      setopenpopupdistric(true);
                    }}
                  />
                </Link>
              </span>


            </Grid>

            <Grid item xs={12} sm={5}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  setSubDistrictValue(newValue);
                  getVillageData(
                    newValue?.state?.code,
                    newValue?.district?.code,
                    newValue?.subdistrict?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={subDistrict.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <span>
                <Link to={{}}>
                  <AddBoxIcon
                    fontSize="large"
                    onClick={() => {
                      setopenpopupsubdistric(true);
                    }}
                  />
                </Link>
              </span>

            </Grid>
          </Grid>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                {...defaultPropsparentpincode}
                value={pincodeValue}
                onChange={(event, newValue) => {
                  setPincodeValue(newValue);
                  values.pincode = newValue;
                  console.log("village", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={pincode.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={1} mt={1}>
              <span>
                <Link to={{}}>
                  <AddBoxIcon
                    fontSize="large"
                    onClick={() => {
                      setopenpopuppincode(true);
                    }}
                  />
                </Link>
              </span>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "25px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={tableData}
              />
            </Grid>
          </Grid>
          <Projectcomponet
            openpopup={openpopup}
            setopenpopup={setopenpopup}
            childToProject={childToProject}
            >

          </Projectcomponet>
          <Statecomponent
            openpopupstate={openpopupstate}
            setopenpopupstate={setopenpopupstate}
            childToparent={childToparent}>

          </Statecomponent>

          <Districcomponet
            openpopupdistric={openpopupdistric}
            setopenpopupdistric={setopenpopupdistric}
            childToDistric={childToDistric}
            >

          </Districcomponet>
          <Subdistriccomponet
            openpopupsubdistric={openpopupsubdistric}
            setopenpopupsubdistric={setopenpopupsubdistric}
           
            childToSubdistric={childToSubdistric}>

          </Subdistriccomponet>
          <Pincodecomponent
            openpopuppincode={openpopuppincode}
            setopenpopuppincode={setopenpopuppincode}
            childTopincode={childTopincode}
            >

          </Pincodecomponent>

        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for PincodeprojectInfo
PincodeprojectInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PincodeprojectInfo;
