// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Department/new-department/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function DesignationInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);
  const [value, setValue] = React.useState(null);


  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDsignationList] = useState("");
  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };


  const defaultPropDesignation = {
    options: !designationList ? [{ name: "Loading...", id: 0 }] :  designationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { designationName, designationDescription, department, designation} = formField;
  var {
    designationName: designationNameV,
    designationDescription: designationDescriptionV,
    hierarchicalCode: hierarchicalCodeV,
   
 
  } = values;
 
  async function getData() {
    await axios
      .get(`department-service-mongo/api/department/v2/get`)
      .then((response) => {
        setDepartmentList(response?.data?.data?.dtoList);

        setLoading(false);
        console.log("====================================");
        console.log("de", defaultProps);
        console.log("====================================");
      })

      .catch((e) => {
        console.log("error", e);
      });
      //setting DEGINATON=====================
    await axios
    .get(
         `designation-service-mongo/api/designation/v2/get`
    )
    .then((response) => {
      setDsignationList(response?.data?.data?.dtoList);
      setLoading(false);
      console.log("====================================");
      console.log("de", defaultPropDesignation);
      console.log("====================================");
    })
    .catch((e) => {
      console.log("error", e);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Designation Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={designationName.type}
                label={designationName.label}
                name={designationName.name}
                value={designationNameV}
                placeholder={designationName.placeholder}
                error={errors.designationName && touched.designationName}
                success={designationNameV.length > 0 && !errors.designationName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={designationDescription.type}
                label={designationDescription.label}
                name={designationDescription.name}
                value={designationDescriptionV}
                placeholder={designationDescription.placeholder}
                error={errors.designationDescription && touched.designationDescription}
                success={designationDescriptionV.length > 0 && !errors.designationDescription}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
             

              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);

                  values.department = newValue?.code;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.department && touched.departmentList}
                    success={department.length > 0 && !errors.department}
                    variant="standard"
                    label={department.label}
                  />
                )}
              />
              </Grid>
               <Grid item xs={12} sm={6}>
               <Autocomplete
                {...defaultPropDesignation}
                value={hierarchicalCode}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setHierarchicalCode(newValue);

                  values.designation  = newValue?.hierarchicalcode;
                  console.log(newValue);   
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.designation && touched.designation}
                    success={designation.length > 0 && !errors.designation}
                    variant="standard"
                    label={designation.label}
                  />
                )}
              />
          
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DesignationInfo
DesignationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DesignationInfo;
