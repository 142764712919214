


import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import { Autocomplete } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

const AssociationList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const dataObj = {
    columns: [
      { Header: "MMU", accessor: "mmuname"},
      {
        Header: "Role",
        accessor: "rolename"
          },
      { Header: "Username", accessor: "username"},
      { Header: "Name", accessor: "fullname"},
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = { fontSize: "1.5em", color: "#D91029", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const [mmucode, setMmuCode] = React.useState(null);
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option?.vehicle?.name ? option?.name + " (" + option?.vehicle?.name + ")":option?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const removeItem = function (id) {
    setLoading(true);
    const objWithIdIndex = tableDatas.rows.findIndex((obj) => obj.id === id);
    tableDatas.rows.splice(objWithIdIndex, 1);
    setTableDatas(tableDatas);
    setLoading(false);
  };
  

  async function onDelete(item) {
    if (
      window.confirm("Are you sure that you want to delete that user record")
    ) {
      await axios
        .put(
          "mmuassociation-service/api/MMUAssociation/v1/delete",
          item
        )
        .then((response) => {
          axios

            .get(
              `profile-service-mongo/api/ProfileEntity/v2/updateByProfileId?newrecstatus=APPROVED&recstatus=ASSIGNED&profileid=${response.data.data.dto.profileid}`
            )

            .then((response) => {
              console.log("gfgfgf", response);
            })
            .catch((error) => {
              console.log(error);
            });
          removeItem(item.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  // async function getData() {
  //   await axios
  //     .get(
  //       `https://apithf.softwaremathematics.com/mmucreation-service/api/MMUCreation/v1/get`
  //     )
  //     .then((response) => {
  //       setMmuList(response?.data?.data?.dtoList);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });

  // }

  // useEffect(() => {
  //   getData();

  // }, []);

  const getassociation = (mmucode) => {
    setLoading(true);
    axios
      .get(
        `mmuassociation-service/api/MMUAssociation/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Association List
        </MDTypography>
        <MDBox pl={3} pr={3} lineHeight={1}>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            ></span>
            {JSON.parse(localStorage.getItem("mmu-auth")).rolecode !==
              "PRM5350" && (
              <Link
                style={{
                  alignSelf: "flex-end",
                  marginRight: "10px",
                  marginTop: "-23px",
                  color: "#344767",
                }}
                to={"/mmu/component/create-association"}
              >
                <MdOutlineLibraryAdd />
              </Link>
            )}
          </div>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
              {...defaultPropsMMU}
              onChange={(event, newValue) => {
                getassociation(newValue?.code);
                // handleChange(event);
                // onSelectionMMU(newValue);
                // setMmuCode(newValue?.code);
                // setMmuValue(newValue);

                // values.mmu = newValue?.code;
                console.log(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="MMU" />}
            />
          </Grid>
        </Grid>
        <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
};

export default AssociationList;