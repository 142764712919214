import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
import { MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";


import { Autocomplete, Grid, TextField } from "@mui/material";

const LabKit=()=>{
  const comp = "SM";
  const [loading, setLoading] = useState(false);

  const [color] = useState("#344767");
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);

  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);

  const [mmuvalue, setMmuvalue] = useState(null);

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };


  const dataObj = {
    columns: [
        { Header: "s.no", accessor: "name" },
        { Header: "Lab Kits AND EQUIPMENTS", accessor: "uom" },
       { Header: "aVAIL", accessor: "currentstock" },
       { Header: "QTY", accessor: "itemtype" },
     
    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);


  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };





  async function getstock(mmucode) {
    console.log("88", mmucode);

    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "s.no", accessor: "sno" },
         { Header: "Lab Kits AND EQUIPMENTS", accessor: "name" },
        { Header: "available QTY", accessor: "currentstock" },
        // { Header: "QTY", accessor: "itemtype" },
      ],
      rows: [],
    };


    await axios
      .get(
        `stock-service/api/Stock/v1/get?mmucode=${mmucode}&itemtype=REAGENT`
        // `https://apithf.softwaremathematics.com/stock-service/api/Stock/v1/get`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item, i) => {
          item.sno=i+1;
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getstock(mmucode);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                  Lab-Kit-Status
                  </MDTypography>
                </span>

                {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginLeft: "20px" }}
                      sx={{ width: 300 }}
                      {...defaultPropsprojectMMU}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getqueuestatus(newValue?.code)
                        setMmuvalue(newValue);
                        console.log(newValue);
                      }}

                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>

                </Grid> */}


              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

export default LabKit;