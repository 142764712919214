// prop-type is a library for typechecking of props
import PropTypes, { func } from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/mmucreation/new mmucreation/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { DataObject } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
function MmucreationInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [VehicleAssignvalue, setVehicleAssignvalue] = React.useState(null);
  const [AssignEquipmentvalue, setAssignEquipmentvalue] = React.useState(null);
  const [mapwarevalue, setmapwarevalue] = React.useState(null);
  const [raspberryvalue, setraspberryvalue] = React.useState(null);
  const [quantity, setQuantity] = useState("0");
  const [assetNo, setAssetNo] = useState("");
  let [QuantityId, setQuantityId] = useState("0");
  const [QTY, setQTY] = useState("");
  const [asset, setAsset] = useState("");

  const [VehicleAssignList, setVehicleAssignList] = useState("");
  const [AssignEquipmentList, setAssignEquipmentList] = useState("");
  const [mapwareList, setmapwareList] = useState("");
  const [raspberryList, setRasberryList] = useState("");
  const [open, setOpen] = React.useState(false);

  const [inputData, setInputData] = useState({
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  });
  const mmuObj = {
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  };
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

  const [equipment, setEquipment] = useState();
  const [equipmentList, setEquipmentList] = useState([]);
  const dataObj = {
    columns: [
      { Header: "sapcode", accessor: "sapcode", width: "25%" },
      { Header: "Equiment Name", accessor: "name", width: "30%" },
      { Header: "Qty", accessor: "inputQty", width: "12%" },
      { Header: "Asset No", accessor: "inputSerialNo" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableData, setTableData] = useState(dataObj);

  const [givenQty, setGivenQty] = useState();
  const [select, setSelect] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  const [err, setErr] = useState("");
  const { formField, values, errors, touched } = formData;

  // vechicle assigned
  const defaultPropssetVehicleAssign = {
    options: !VehicleAssignList
      ? [{ name: "Loading...", id: 0 }]
      : VehicleAssignList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // Assign Equipment
  const defaultPropssetAssignEquipment = {
    options: !AssignEquipmentList
      ? [{ name: "Loading...", id: 0 }]
      : AssignEquipmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // Map Ware-house
  const defaultPropssetMapWarehouse = {
    options: !mapwareList ? [{ name: "Loading...", id: 0 }] : mapwareList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // Raspberry Assign==========
  const defaultPropssetRaspberryAssign = {
    options: !raspberryList ? [{ name: "Loading...", id: 0 }] : raspberryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    MMUName,
    MMUDescription,
    VehicleAssign,
    AssignEquipment,
    mapware,
    RaspberryAssign,
    EquipmentList,
  } = formField;
  var {
    MMUName: MMUNameV,
    
    EquipmentList: EquipmentListV,
  } = values;

  const removeItem = function (id) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.id === id);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.EquipmentList = tableData.rows;
  };
  const EditItem = function (id) {
    console.log("136", id);
    setQuantityId(id)
    setQTY(quantity);
    setAsset(assetNo);
    setOpen(true);
  };
  const changequantityassetno = function () {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.id === QuantityId);
    
    tableData.rows[objWithIdIndex].inputQty = QTY;
    tableData.rows[objWithIdIndex].inputSerialNo = asset;
   
    
    dataObj.rows = tableData.rows;
    setTableData(dataObj);
    setOpen(false);
  };
  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };


  const addData = () => {

    if(AssignEquipmentvalue == null){
      swal("Oops!", 
        "Assign Equipment value is required!.",
      "warning"
    );
    }else{
    AssignEquipmentvalue.action = (
      <span>
      
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",

            }}
            onClick={() => removeItem(AssignEquipmentvalue?.id)}
          />
        </Link>

      </span>
    );

    AssignEquipmentvalue.inputQty = quantity;



    AssignEquipmentvalue.inputSerialNo = assetNo;

    let filter = tableData?.rows?.filter(
      (a) => a.name == AssignEquipmentvalue?.name
    );

    if (filter.length < 1) {

      tableData.rows.push(AssignEquipmentvalue);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.EquipmentList = tableData.rows;

    }
    setAssetNo("")
    setQuantity("")
    setAssignEquipmentvalue(null)
 
  }
  };

  async function submitHandle() {
    console.log("sub", tableData);


  }
  async function getData() {
    await axios
      .get(
        `itemmaster-service/api/itemMaster/v1/get?type=VEHICLE`
      )
      .then((response) => {
        setVehicleAssignList(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });

    await axios
      .get(
        `itemmaster-service/api/itemMaster/v1/get?type=EQUIPMENT`
      )
      .then((response) => {
        setAssignEquipmentList(response?.data?.data?.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get`
      )
      .then((response) => {
        setmapwareList(response?.data?.data?.dtoList);

        setLoading(false);

      })

      .catch((e) => {
        console.log("error", e);
      });
      // rasbeery asgin
      await axios
      .get(
        `itemmaster-service/api/itemMaster/v1/get?type=RASPBERRY-PI`
      )
      .then((response) => {
        setRasberryList(response?.data?.data?.dtoList);

        setLoading(false);
 
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">MMU Creation</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={MMUName.type}
                label=<span>{MMUName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={MMUName.name}
                value={MMUNameV}
                placeholder={MMUName.placeholder}
                error={errors.MMUName && touched.MMUName}
                success={MMUNameV?.length > 0 && !errors.MMUName}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetRaspberryAssign}
                value={raspberryvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setraspberryvalue(newValue);

                  values.RaspberryAssign = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.mapware && touched.mapware}
                    // success={mapware.length > 0 && !errors.mapware}
                    variant="standard"
                    label={RaspberryAssign.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetMapWarehouse}
                value={mapwarevalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setmapwarevalue(newValue);

                  values.mapware = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.mapware && touched.mapware}
                    // success={mapware.length > 0 && !errors.mapware}
                    variant="standard"
                    label={mapware.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetVehicleAssign}
                value={VehicleAssignvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setVehicleAssignvalue(newValue);

                  values.VehicleAssign = newValue;
                  // console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.VehicleAssign && touched.VehicleAssign}
                    // success={VehicleAssign.length > 0 && !errors.VehicleAssign}
                    variant="standard"
                    label={VehicleAssign.label}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetAssignEquipment}
                value={AssignEquipmentvalue}
                onChange={(event, newValue) => {
                  setAssignEquipmentvalue(newValue);
                  values.AssignEquipment = newValue?.name;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.AssignEquipment && touched.AssignEquipment}
                    // success={
                    //   AssignEquipment.length > 0 && !errors.AssignEquipment
                    // }
                    variant="standard"
                    label={AssignEquipment.label}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormField
                name="quantity"
                type="number"
                label="Quantity"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => {
                  // addEquipmentFromAsset(AssignEquipmentvalue, e.target.value);
                  setQuantity(e.target.value);
                  // AssignEquipmentvalue.assetno = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormField
                name="assetNo"
                type="text"
                label="AssetNo"
                placeholder="AssetNo"
                value={assetNo}
                onChange={(e) => {
                  // addEquipmentFromAsset(AssignEquipmentvalue, e.target.value);
                  setAssetNo(e.target.value);
                  // AssignEquipmentvalue.assetno = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
        </MDBox>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Assign Equipment </DialogTitle>
        <DialogContent style={{ width: 270, height: 180 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="QTY"
              variant="standard"
              fullWidth
              value={QTY}
              onChange={(e) => setQTY(e.target.value)}
            />

          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Asset no"
              variant="standard"
              fullWidth
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
            />
          </MDBox>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <Button onClick={changequantityassetno}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

// typechecking props for MmucreationInfo
MmucreationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MmucreationInfo;
