// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Department/new-department/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function DepartmentInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);
  const [departmentList, setDepartmentList] = useState("");
  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { departmentName, departmentDescription, department,parenthierarchical } = formField;
  var {
    departmentName: departmentNameV,
    departmentDescription: departmentDescriptionV,
  
    
  } = values;

  async function getData() {
    await axios
      .get(`department-service-mongo/api/department/v2/get`)
      .then((response) => {
        setDepartmentList(response?.data?.data?.dtoList);

        setLoading(false);
        console.log("====================================");
        console.log("de", defaultProps);
        console.log("====================================");
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Department Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={departmentName.type}
                label={departmentName.label}
                name={departmentName.name}
                value={departmentNameV}
                placeholder={departmentName.placeholder}
                error={errors.departmentName && touched.departmentName}
                success={departmentNameV.length > 0 && !errors.departmentName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={departmentDescription.type}
                label={departmentDescription.label}
                name={departmentDescription.name}
                value={departmentDescriptionV}
                placeholder={departmentDescription.placeholder}
                error={errors.TestResult && touched.departmentDescription}
                success={departmentDescriptionV.length > 0 && !errors.departmentDescription}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultProps}
                value={hierarchicalCode}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setHierarchicalCode(newValue);

                  values.department = newValue?.code;
                  values.parenthierarchical=newValue?.hierarchicalcode;
                
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.department && touched.departmentList}
                    // success={department.length > 0 && !errors.department}
                    variant="standard"
                    label={department.label}
                  />
                )}
              />
          
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DepartmentInfo
DepartmentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DepartmentInfo;
