import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd, MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";


import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import MDBadge from "components/MDBadge";

const TestQueue = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);
    const [loginUser, setLoginUser] = useState(
      JSON.parse(localStorage.getItem("mmu-auth")));
  const [mmuvalue, setMmuvalue] = useState(null);
  const [getResponse, setResponse] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [name, setName] = useState("");
  const [uom, setUom] = useState("");
  const [range, setRange] = useState("");
  const [value, setValue] = useState("");
  const [comment, setComment] = useState("");
  const [investigation, setInvestigation] = useState({});

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsmmuCode = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.name + " (" + option.vehicle.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function updateLabTest(object) {
    console.log("65", object)
    
    setLoading(true);

    await axios
      .put(

        `labtests-serv/api/labTests/v1/update`, object
      )
      .then((response) => {
        console.log(response?.data?.data?.dtoList);
        getLabresult(mmucode);
      })
      .catch((e) => {
        console.log("error", e);
        getLabresult(mmucode);
      });
    setLoading(false);
  }

  function handleUpdate(object) {
    var investigation = object?.investigationlist[0];
    delete object.action
    setInvestigation(object)
    if (investigation != null) {
      setName(investigation?.name)
      setUom(investigation?.uom)
      setRange(investigation?.range)

    }
  }

  async function getLabresult(mmucode) {
    console.log("88", mmucode);

    // setLoading(true);
    const dataObj2 = {
      columns: [

        { Header: "test name", accessor: "patient.patientid" },

        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };


    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/getLabTestByVisit?recstatus=PRESCRIBED&mmucode=${mmucode}`
      )
      .then((response) => {
        console.log("91", response.data.data.labTestAggregationList);
        response.data.data.labTestAggregationList.map((item) => {
          for (let i = 0; i < item.labtestslist.length; i++) {
            var isSampleCollected = false;
            var isValueUpdate = false;
            if (item.labtestslist[i].recstatus == "COMPLETED" || item.labtestslist[i].recstatus == "SAMPLE_COLLECTED") {
              isSampleCollected = true
            }
            if (item.labtestslist[i].recstatus == "COMPLETED" ||item.labtestslist[i]?.recstatus =="OPEN") {
              isValueUpdate = true
            }
            item.labtestslist[i].action = (
              <span>
                <MDButton
                  disabled={isSampleCollected}
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => {
                    item.labtestslist[i].recstatus = "SAMPLE_COLLECTED";
                    updateLabTest(item.labtestslist[i])
                    // console.log("update 105")
                  }}
                >
                  {item.labtestslist[i].recstatus == "COMPLETED" ? "Completed" : "Sample Collected"}
                </MDButton>
                <MDButton
                  style={{marginLeft:"1em"}}
                  disabled={isValueUpdate}
                  type="submit"
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => {
                    handleUpdate(item.labtestslist[i])
                    setOpen(true)
                  }}
                >
                  Update
                </MDButton>


              </span>
            );
          }
          item.dataObj = {
            columns: [

              { Header: "test name", accessor: "testname" },

              { Header: "action", accessor: "action" },
            ],
            rows: item.labtestslist,
          };


        });
        console.log("res70", dataObj2);

        setResponse(response.data.data.labTestAggregationList)
        // setTableDatas2(dataObj2);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitResult = (object) => {
    console.log("195", object)
    // object.investigationlist[0]?.value =value;
    
    const finalLabTestObj = {
      approvedby
        :
        object.approvedby,
      approveddate
        :
        object.approveddate,
      approver
        :
        object.approver,
      approvertype
        :
        object.approvertype,
      classname
        :
        object.classname,
      code
        :
        object.code,
      createdby
        :
        object.createdby,
      createddate
        :
        object.createddate,
      detailedresult
        :
        object.detailedresult,
      documentofproof
        :
        object.documentofproof,
      id
        :
        object.id,
      investigationlist
        :
        object.investigationlist,

      location
        :
        object.location,
      mmucode
        :
        object.mmucode,
      modifiedby
        :
        loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,

      presid
        :
        object.presid,
      ptnumber
        :
        object.ptnumber,
      recstatus
        :
        "COMPLETED",
      status
        :
        object.status,
      syncstatus
        :
        object.syncstatus,
      tempid
        :
        object?.tempid,
      testid
        :
        object.testid,
      testname
        :
        object.testname,
      testresult
        :
        comment,
      visitid
        :
        object.visitid
    }
    if(finalLabTestObj.investigationlist.length > 0){
      var investigation = finalLabTestObj.investigationlist[0];
      investigation.value = value;
    }
    updateLabTest(finalLabTestObj);
    console.log("195", finalLabTestObj)
    setOpen(false);
  };

  useEffect(() => {
    getLabresult(mmucode);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Lab Test Queue
                  </MDTypography>
                </span>



              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div style={{marginTop:"5px"}}>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Name :
                          </text>
                          {" " + values.patient?.name}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Age :
                          </text>
                          {" " + values.patient?.age}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Prescription :
                          </text>
                          {" " + values.visit?.presid}
                        </div>


                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
       
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            backgroundColor: "blue",
            color: "#FFFFFF",
          }}
        >
        Lab Test
        </DialogTitle>
        <DialogContent style={{ width: 550, height: 325 }}>
          <MDBox mb={2}>
            <MDInput
              disabled={true}
              type="text"
              label=" Name"
              variant="standard"
              fullWidth
              value={name}

            />
          </MDBox>
          <MDBox mb={2}>


            <MDInput
              disabled={true}
              type="text"
              label=" UOM"
              variant="standard"
              fullWidth
              value={uom}

            />
          </MDBox>
          <MDBox mb={2}>


            <MDInput
              disabled={true}
              type="text"
              label="Range"
              variant="standard"
              fullWidth
              value={range}

            />

          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Value"
              variant="standard"
              fullWidth
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}

            />

          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Comment"
              variant="standard"
              placeholder="Add Comment"
              fullWidth
              multiline
              value={comment}
              onChange={(e) => setComment(e.target.value)}

            />

          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <MDButton onClick={() => handleSubmitResult(investigation)}      variant="gradient"
                                color="info"
                                size='small'
                                type="submit">Update</MDButton>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};

export default TestQueue;