

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit, FaRegTimesCircle } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd, MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";


const Stockstatus = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);


  const [color] = useState("#344767");

 
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));


  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
 
  
  const dataObj = {
    columns: [
              { Header: "s.no", accessor: "name" },
         { Header: "UOM", accessor: "uom" },
        { Header: "15daysCONSUMED", accessor: "currentstock" },
        { Header: "QTY", accessor: "itemtype" },
    ],
    rows: [],
  };


  

  const [tableDatas, setTableDatas] = useState(dataObj);
 
 



  const getstockstatus = (mmucode) => {
    setLoading(true);
    axios
      .get(
      
        `stock-service/api/Stock/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        console.log("hii", response)
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  

 
  useEffect(() => {
    getstockstatus(mmucode);
    
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                   Stock status
                  </MDTypography>
                </span>
                    </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

     
      <Footer />
    </DashboardLayout>
  );
};









export default Stockstatus;