import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

import { FaRegTimesCircle } from "react-icons/fa";

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import MDDatePicker from "components/MDDatePicker";
import Testlist from "../Test master/Testlist";



const FollowUps = () => {
    const comp = "SM";
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openline, setOpenLine] = React.useState(false);
    const [updatestatus, setUpdatestatus] = React.useState(false);
    const [openhistory, setOpenhistory] = React.useState(false);

    const [doctorList, setDoctorList] = useState("");
    const [aggregiatepatient, setAggregiatepatient] = useState("");
    const [visittypeList, setvisittypelist] = useState("");
    const [visitcategoryList, setvisitcategoryList] = useState("");
    const [treatedlist, setTreatedlist] = useState("");
    const [diseaseList, setDiseaseList] = useState("");
    const [medList, setmedList] = useState("");
    const [selectmed, setSelectmed] = useState(null);
    const [frequencyList, setFrequencyList] = useState("");
    const [chiefcomplaint, setChiefcomplaint] = useState("");
    const [remark, setRemark] = useState("");
    const [dose, setDose] = useState("");
    const [duration, setDuration] = useState("");
    const [selecttreated, setSelecttreated] = useState(null);
    const [patientId, setPatientId] = useState();
    const [selectfrequency, setSelectfrequency] = useState(null);
    const [diseasevalue, setDiseasevalue] = useState([]);
    const [searched, setSearched] = useState(null);
    const [loginUser, setLoginUser] = useState(
        JSON.parse(localStorage.getItem("mmu-auth")));
    const [mmucode, setMmucode] = useState(
        JSON.parse(localStorage.getItem("association"))[0].mmucode);
    const [color] = useState("#344767");

    const [item, setItem] = useState({});
    

    const [mmuList, setMmuList] = useState(
        JSON.parse(localStorage.getItem("project-Detail")).mmulist);

    const [mmuvalue, setMmuvalue] = useState(null);
    const [getApiResponse, setApiResponse] = useState([]);


    const [getResponse, setResponse] = useState([]);
    const [uom, setUom] = useState("");

    const [givenQty, setGivenQty] = useState("");

    const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
    const addButtonStyle = {
        display: "flex",
        fontSize: "2em",
        flexDirection: "column",
        marginRight: "10px",
    };

    const dataObj = {
        columns: [
            { Header: "Medicine", accessor: "medicinename" },
            { Header: "frequency", accessor: "frequency" },
            { Header: "dose", accessor: "dose" },
            { Header: "duration", accessor: "duration" },
            { Header: "Action", accessor: "action" },
        ],
        rows: [],
    };
    const [tableData, setTableData] = useState(dataObj);
    const [startDate, setStartDate] = useState();
    const [finalLabtest, setFinalLabtest] = useState([]);
    // const [loginUser, setLoginUser] = useState(
    //     JSON.parse(localStorage.getItem("mmu-auth")));
    const [createdBy, setCreatedBy] = useState(loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname);
    
    const completePrescrptionObject = {
        additionalnotes: remark,
        age: aggregiatepatient?.patient?.age,

        chiefcomplaint: chiefcomplaint,
        diagnosistext: remark,

        createdby: createdBy,

        diagnosiscategory: "",


        doctor: loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,

        history: "",
        id: null,
        labtestlist: finalLabtest,
        medicinelist: tableData.rows,
        mmucode: mmucode,
        name: aggregiatepatient?.patient?.name,
        nextfollowupdate: new Date(startDate),
        parentpresid: "",
        patientid: aggregiatepatient?.patient?.patientid,
        prestype: "",
        reasontoreferal: "",
        recstatus: "PRESCRIBED",
        refereddoctor: "",
        status: "ACTIVE",
        syncstatus: "SYNCED",

        visitData: aggregiatepatient?.visit,
        visitid: aggregiatepatient?.visit?.visitid,

    }
    const removeItem = function (uniqueKey) {

        const objWithIdIndex = tableData.rows.findIndex((obj) => obj.medicineid === uniqueKey);
        tableData.rows.splice(objWithIdIndex, 1);
        setTableData(tableData);
        // values.EquipmentList = tableDatasMed.rows;
      };

    const addData = (doseData) => {
        console.log("155", doseData);
        const dose = {
            countperday: doseData.countperday,
            createdby: createdBy,
            documentofproof: "",
            dosage: doseData.name,
            dose: doseData.dose,
            duration: doseData.duration,
            form: "",
            frequency: doseData.frequency,
            id: null,
            medicineid: doseData.id,
            medicinename: doseData.name,
            mmucode: mmucode,

            quantity: "",
            recstatus: "OPEN",
            status: "ACTIVE",
            syncstatus: "NOT_SYNCED",
            tempid: "",
            uom: doseData.uom,
            visitid: aggregiatepatient?.visit?.visitid
        }

        
        console.log("182", dose);
        // console.log(listOfMedicine);
        dose.action = (
            <span>

                <Link to={{}}>
                    <FaRegTimesCircle
                        style={{
                            color: "#f44336",
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",

                        }}
                        onClick={() => removeItem(dose.medicineid)}
                    />
                </Link>

            </span>
        );

     

        let filter = tableData.rows.filter(
            (a) => a.medicineid == dose.medicineid
        );

        if (filter.length < 1) {

            tableData.rows.push(dose);
            dataObj.rows = tableData.rows;
            setTableData(dataObj);
            // values.listOfMedicine = tableData.rows;
        }

    };



    const [historyDataList, setHistoryDataList] = useState()
    async function historyData(patientObject) {
        console.log("233",patientObject)
      var mmucode = JSON.parse(localStorage.getItem("association"))[0].mmucode;
     
      setPatientId(patientObject.patientid)
      // var patientId = patientObject.patientid;
    
  
      await axios
        .get(
  
          `aggregation-service/api/Aggregation/v1/get?mmucode=${mmucode}&patientid=${patientObject.patientid}&recstatus=PRESCRIBED`
        )
        .then((response) => {
          console.log("247", response);
          setHistoryDataList(response.data.data.visitAggregationDTOList);
          // var docArr = []
          // response?.data?.data?.dtoList?.map((item) => {
          //   if (item.rolecode == "DO75") {
          //     docArr.push(item);
          //     console.log("198", item);
          //   }
          // })
          // if (response?.data?.data?.dtoList.length > 0) {
          //   setMmucode(response?.data?.data?.dtoList[0].mmucode);
          //   getpatient(response?.data?.data?.dtoList[0].mmucode)
          // }
          // setDoctorList(docArr);
          // // setLoading(false);
        })
        .catch((e) => {
          console.log("error", e);
        });
    }



    async function createPrescription(object){
        await (await axios.post(`aggregation-service/api/Aggregation/v1/createPrescription`, object))
        .then((response)=>{})
        .catch((e)=>{console.log(e);})
    }
    const handleSubmitPrescription = () => {
        setFinalLabtest([])
        console.log("225",selectedTestlist);
        for(let i=0; i<selectedTestlist.length; i++){
            const labTestObj=  {
                createdby: createdBy,
                detailedresult: null,
                id: null,
                investigationlist:selectedTestlist[i].investigationlist,
                
                mmucode: mmucode,
                recstatus: "OPEN",
                status: "ACTIVE",
                syncstatus: "NOT_SYNC",
                testid: selectedTestlist[i].id,
                testname: selectedTestlist[i].testname,
                testresult: "",
                visitid: aggregiatepatient?.visit?.visitid
              }
              finalLabtest.push(labTestObj);
        }
        console.log("244",finalLabtest);
        for(let i=0; i<completePrescrptionObject.medicinelist.length; i++){
            delete completePrescrptionObject.medicinelist[i].action;
        }
        completePrescrptionObject.visitData.recstatus = "PRESCRIBED";
        console.log("complet Pres OBJ", completePrescrptionObject);
        createPrescription(completePrescrptionObject);
    }

    const defaultPropsprojectMMU = {
        options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const defaultPropsdisease = {
        options: !diseaseList ? [{ name: "Loading...", id: 0 }] : diseaseList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const defaultPropsmediciene = {
        options: !medList ? [{ name: "Loading...", id: 0 }] : medList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const defaultPropsfrequency = {
        options: !frequencyList ? [{ name: "Loading...", id: 0 }] : frequencyList,
        getOptionLabel: (option) => option.description,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };


    async function getDiseaseData() {
        await axios
            .get(
                `disease-service/api/Disease/v1/get`
            )
            .then((response) => {
                setDiseaseList(response?.data?.data?.dtoList);

                console.log("168", response?.data?.data?.dtoList);
            })
            .catch((e) => {
                console.log("error", e);
            });
        await axios
            .get(
                `itemmaster-service/api/itemMaster/v1/get?type=MEDICINE`
            )
            .then((response) => {
                setmedList(response?.data?.data?.dtoList);
                console.log("44", response?.data?.data?.dtoList);
                // setLoading(false);
            })

            .catch((e) => {
                console.log("error", e);
            });
        await axios
            .get(
                `frequencymaster-service/api/frequencyMaster/v1/get`
            )
            .then((response) => {
                setFrequencyList(response?.data?.data?.dtoList)

            })
            .catch((e) => {
                console.log("error", e);
            });
            await axios
            .get(
                `testmaster-service/api/testMaster/v1/get`
            )
            .then((response) => {
                setTestlist(response?.data?.data?.dtoList)

            })
            .catch((e) => {
                console.log("error", e);
            });
    }


    // assign doctor popup drop down :- for select doctor=======================
    const defaultPropsDoctor = {
        options: !doctorList
            ? [
                { name: "Doctor User", code: "X" },

            ]
            : doctorList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option?.code === value?.code,
    };
    const defaultPropsvisittype = {
        options: !visittypeList
            ? [
                { name: "New", code: "X" },
                { name: "FollowUp", code: "y" },

            ]
            : visitcategoryList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option?.code === value?.code,
    };

    const defaultPropsVisitcategory = {
        options: !visitcategoryList
            ? [
                { name: "MMU Visit", code: "X" },
                { name: "Home Visit", code: "y" },

            ]
            : visittypeList,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option?.code === value?.code,
    };

    const defaultPropsTreated = {
        options: !treatedlist
            ? [
                { name: "TREATED", code: "X" },
                { name: "TREATMENT_IN_PROGRESS", code: "y" },

            ]
            : treatedlist,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option?.code === value?.code,
    };
    const[testlist, setTestlist] = useState();
    const[selectedTestlist, setSelectedTestlist] = useState();
    const defaultPropsLabTest = {
        options: !testlist
            ? [
                { name: "Loading", id: "X" },

            ]
            : testlist,
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    
    function getPrescriptionData(item) {
        console.log("200", item)
        setAggregiatepatient(item)
        getDiseaseData();
        console.log("260", completePrescrptionObject)
    }
  //search button
 const requestSearch = (keyData) => {
    console.log("444", keyData)
    //setSearched(key);
    const searchdata = getResponse.filter((key) => {
      if ( key.patient.name.toLowerCase().includes(keyData.toLowerCase()) || key.patient.age.includes(keyData)  ) {
        return key;
      }

    });
    if (keyData === null || keyData.length === 0) {
      setResponse(getApiResponse);
    }
    else
      setResponse(searchdata);
  };



    const handleClose = () => {
        console.log("dsfsdfs");
        setOpen(false);
    };
    // setUpdatestatus handle close====================

    const handleCloseupdatestatus = () => {
        console.log("dsfsdfs");
        setUpdatestatus(false);
    };
    const handleCloseline = () => {
        console.log("dsfsdfs");
        setOpenLine(false);
    };

    // history handle close====================

    const handleClosehistory = () => {
        console.log("dsfsdfs");
        setOpenhistory(false);
    };
    async function getpatient(mmucode) {
        setLoading(true);

        await axios
            .get(
                `aggregation-service/api/Aggregation/v1/get?mmucode=${mmucode}&recstatus=RESULT_AWAITED`
            )
            .then((response) => {
                response.data.data.visitAggregationDTOList.map((item) => {

                });
                setResponse(response.data.data.visitAggregationDTOList);
                setApiResponse(response.data.data.visitAggregationDTOList);

                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    useEffect(() => {
        getpatient(mmucode);

    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={3}>
                <MDBox>
                    <Card>
                        <MDBox pl={3} pr={3} lineHeight={1}>
                        <Grid container spacing={3} mt={2}>
                    <Grid item xs={12} sm={6}>
                      <MDTypography variant="h5" fontWeight="medium">
                       Follow-Ups
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDBox width="12rem" ml="auto">
                        <MDInput
                          placeholder="Search..."
                          value={searched}

                          onChange={(e) => {
                            requestSearch(e.target.value)
                            setSearched(e.target.value)
                          }}


                        />
                      </MDBox>
                    </Grid>
                 
              
                </Grid>
                        </MDBox>
                        <BarLoader
                            color={color}
                            loading={loading}
                            aria-label="Loading Spinner"
                        />

                        <div>
                            {getResponse.map((values) => (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <div style={{ fontSize: "15px", color: "#7b809a" }}>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Name :
                                                    </text>
                                                    {" " + values.patient.name}

                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Age :
                                                    </text>
                                                    {" " + values.patient.age}

                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Village :
                                                    </text>
                                                    {" " + values.patient.village}

                                                </div>
                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Document Type :
                                                    </text>
                                                    {" " + values.patient.documenttype}


                                                </div>

                                                <div>
                                                    <text style={{ fontWeight: "bold" }}>
                                                        Doc.No. :
                                                    </text>
                                                    {" " + values.patient.documentno}


                                                </div>



                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={3}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    size='small'
                                                    type="submit"
                                                    onClick={() => {
                                                        setOpen(true);
                                                        getPrescriptionData(values);
                                                    }}
                                                >
                                                    Update
                                                </MDButton>
                                            </Grid>

                                            <Grid item xs={12} sm={3}>
                                                <MDButton
                                                    style={{ marginLeft: "-47px" }}
                                                    variant="gradient"
                                                    color="info"
                                                    size='small'
                                                    type="submit"
                                                    onClick={() => {
                                                        setUpdatestatus(true);
                                                    }}
                                                >
                                                    Update Status
                                                </MDButton>
                                                {/* popup view history============================= */}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    size='small'
                                                    type="submit"
                                                    onClick={() => {
                                                        historyData(values.patient)
                                                        setOpenhistory(true);
                                                    }}
                                                >
                                                    View History
                                                </MDButton>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </Card>
                </MDBox>
            </MDBox>

            {/* Prescription popup================================================= */}
            {aggregiatepatient && <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ textAlign: "center" }} >Prescription</DialogTitle>
                <DialogContent style={{ width: 580, height: 820 }}>
                    {/* this one for upper layer */}
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            border: "1px solid #727375",
                            borderRadius: "5px",
                            marginTop: "15px",
                            marginBottom: "15px"
                        }}
                    >


                        <Grid item container spacing={1} style={{ margin: "2px" }}>

                            <Grid item xs={12} sm={11} >
                                <MDTypography variant="h5" fontWeight="medium">
                                    Patient ID : {aggregiatepatient.patient.patientid}
                                </MDTypography>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* This one for down layer */}
                    <Grid container spacing={4} style={{ marginTop: ".1px" }}>
                        {/* Name Input box */}
                        <Grid item xs={12} sm={6}>

                            <MDInput
                                style={{ width: "100%" }}
                                disabled="true"
                                label="Name"
                                size="large"
                                value={aggregiatepatient.patient?.name}


                            />
                        </Grid>

                        {/* Village Input box */}
                        <Grid item xs={12} sm={6} >

                            <MDInput
                                style={{ width: "100%" }}
                                disabled="true"
                                label="Village"
                                size="large"
                                value={aggregiatepatient.patient?.village}


                            />
                        </Grid>

                    </Grid>


                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                style={{ width: "100%" }}
                                disabled="true"
                                label="Gender"
                                size="large"
                                value={aggregiatepatient.patient?.gender}

                            // multiline
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <MDInput
                                style={{ width: "100%" }}
                                disabled="true"
                                label="Age"
                                size="large"
                                value={aggregiatepatient.patient?.age}


                            />
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                style={{ width: "100%" }}
                                disabled="true"
                                label="Address..."
                                size="large"
                                value={aggregiatepatient.patient.address}

                                multiline
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} >

                            <MDInput
                                style={{ width: "100%" }}
                                label="Chief Complaint..."
                                size="large"
                                value={chiefcomplaint}
                                onChange={(e) => setChiefcomplaint(e.target.value)}
                                multiline
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item xs={12} sm={6}>

                            {/* Here is chang for styling */}

                            <Autocomplete
                                multiple
                                id="tags-standard"
                                // options={diseaseLabel}
                                // getOptionLabel={(option) => option.title}
                                {...defaultPropsdisease}
                                //defaultValue={[diseaseLabel[6]]}
                                // value={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Disease"
                                        placeholder="Search..."
                                    />
                                )}
                            />

                        </Grid>


                        <Grid item xs={12} sm={6} >
                            <MDInput
                                style={{ width: "100%" }}
                                label="Remark"
                                size="medium"
                                multiline
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid>
                        <h4 style={{ textAlign: "center", marginTop: "20px" }}> Medicine </h4>
                    </Grid>



                    {/* Change in this one  */}

                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>

                        {/* ===== 1st box ===== */}
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                {...defaultPropsmediciene}
                                value={selectmed}
                                onChange={(event, newValue) => {
                                    // handleChange(event);


                                    console.log("711", newValue)

                                    setSelectmed(newValue)
                                    setUom(newValue?.uom)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        label="Medicine "
                                        placeholder="Select Medicine"
                                    />
                                )}
                            />
                        </Grid>

                        {/* ====== 2nd Box ======= */}
                        <Grid item xs={12} sm={2} >
                            <MDInput
                                style={{ width: "100%" }}
                                value={uom}
                                disabled="true"
                                label="UOM"
                                size="large"
                            />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete

                                {...defaultPropsfrequency}
                                value={selectfrequency}
                                onChange={(event, newValue) => {
                                    // handleChange(event);


                                    console.log("756", newValue)

                                    setSelectfrequency(newValue)
                                    selectmed.frequency = newValue?.description;
                                    selectmed.countperday = newValue?.countperday;

                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        label="Frequency"
                                        placeholder="Select Frequency"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>

                        {/* First Box */}
                        <Grid item xs={12} sm={3}>

                            <MDInput
                                disabled="true"
                                style={{ width: "100%" }}
                                label="FORM"
                                size="large"
                            />
                        </Grid>


                        <Grid item xs={12} sm={3}>
                            <MDInput
                                name="dose"
                                type="number"
                                label="Dose"
                                onChange={(e) => {
                                    setDose(e.target.value)
                                    selectmed.dose = e.target.value
                                }
                                }



                            />
                        </Grid>


                        <Grid item xs={12} sm={3}>
                            <MDInput
                                name="Duration"
                                type="number"
                                label="Duration days"
                                onChange={(e) => {
                                    setDuration(e.target.value)
                                    selectmed.duration = e.target.value
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                style={{ marginTop: "10px" }}
                                onClick={() => addData(selectmed)}
                            >
                                Add line
                            </MDButton>
                        </Grid>
                    </Grid>




                    {tableData && <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item
                            xs={12}
                            sm={12}
                            style={{
                                border: "1px solid #727375",
                                borderRadius: "5px",
                                marginTop: "25px",
                                marginLeft: "25px",
                            }}
                        >
                            <DataTable
                                showTotalEntries={false}
                                entriesPerPage={false}
                                table={tableData}
                            />
                        </Grid>
                    </Grid>
                    }

                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item xs={12} sm={6}>
                           
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                defaultValue={[]}
                                onChange={(event, newValue) => {
                                    console.log("909", newValue)
                                    setSelectedTestlist(newValue)
                                }}
                                {...defaultPropsLabTest}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Lab-Test"
                                        placeholder="Select"
                                    />
                                )}
                            />

                        </Grid>


                        <Grid item xs={12} sm={6} >

                            <MDDatePicker selected={startDate} onChange={setStartDate} input={{ placeholder: "  Select Follow-Up Date" }} />

                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                        <Grid item xs={12} sm={6} style={{ marginTop: "7px" }}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                size='small'
                                type="submit"
                            onClick={() => {
                                handleSubmitPrescription();
                                setOpen(false);
                            }}
                            >
                                Submit Prescription
                            </MDButton>

                        </Grid>


                        <Grid item xs={12} sm={6} style={{ marginTop: "7px" }}>

                            <MDButton
                                variant="gradient"
                                color="info"
                                size='small'
                                type="submit"
                            // onClick={() => {
                            //     setOpen(true);
                            // }}
                            >
                                Result Awaited
                            </MDButton>

                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
            }


            {/* create vitlas popup================================================= */}

            <Dialog open={updatestatus} onClose={handleCloseupdatestatus}>
                <Grid container spacing={3} >
                    <Grid item xs={12} sm={10}  >
                        <DialogTitle style={{ textAlign: "centre" }}>Update status</DialogTitle>
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ marginTop: "12px" }}  >
                        <span>
                            <Link to={{}}>
                                <CancelIcon
                                    style={iconStyle}
                                    onClick={handleCloseupdatestatus}
                                />
                            </Link>

                        </span>
                    </Grid>
                </Grid>
                <DialogContent style={{ width: 350, height: 150 }}>

                    <Grid item xs={12} sm={9} style={{ marginTop: "2px" }} >
                        <Autocomplete
                            {...defaultPropsTreated}
                            value={selecttreated}
                            onChange={(event, newValue) => {
                                // handleChange(event);
                                item.type = newValue?.name;

                                setSelecttreated(newValue);
                                console.log("515", selecttreated)

                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}


                                    label="Treated"
                                />
                            )}
                        />
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: "2px" }}>
                        <Grid item xs={12} sm={6}>
                            <MDButton
                                variant="gradient"
                                color="info"
                                size='small'
                                type="submit"

                            >
                                Update
                            </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <MDButton
                                style={{ marginLeft: "-47px" }}
                                variant="gradient"
                                color="info"
                                size='small'
                                type="submit"


                            >
                                Refer
                            </MDButton>
                            {/* popup view history============================= */}
                        </Grid>


                    </Grid>

                </DialogContent>

            </Dialog>
            {/* edit popup================================================= */}
            {/* view history popup ===============================================*/}
      <Dialog open={openhistory} onClose={handleClosehistory}>
        <DialogTitle style={{ textAlign: "centre" }}>View History</DialogTitle>
        {historyDataList && <DialogContent style={{ width: 550, height: 350 }}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",

            }}
          >


            <Grid item container spacing={3} >
              <Grid item xs={12} sm={1} >

                <ExpandCircleDownIcon fontSize="medium" style={{ marginTop: "18px" }}></ExpandCircleDownIcon>,
              </Grid>
              <Grid item xs={12} sm={11} >
                <MDTypography variant="h5" fontWeight="medium" style={{ marginTop: "18px" }}>
                  Patient ID : {patientId}
                </MDTypography>
              </Grid>

            </Grid>


          </Grid>

          <h5 style={{ margin: "1px" }}>#Visit ID: {historyDataList[0]?.visit?.visitid}</h5>
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              border: "1px solid #727375",
              borderRadius: "9px",
              marginTop: "25px",
              marginLeft: "35px"

            }}
          >
            <h5 style={{ margin: "1px", textAlign: "center" }}>Prescription Result:</h5>



            {historyDataList[0]?.prescriptionlist.map((i)=>(
              <Grid item xs={12} sm={11} style={{ margin: "5px" }} >
                <h5>
                  Prescription ID : {i.presid}
                </h5>
              </Grid>
            ))
          }
           


          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            style={{
              border: "1px solid #727375",
              borderRadius: "9px",
              marginTop: "25px",
              marginLeft: "35px"

            }}
          >
            <h5 style={{ margin: "1px", textAlign: "center" }}>Lab Test Result:</h5>



            {historyDataList[0]?.prescriptionlist.map((i)=>(
              <Grid item xs={12} sm={11} style={{ margin: "5px" }} >
                <h5>
                  Prescription ID : {i.presid}
                </h5>
              </Grid>
            ))
          }
           


          </Grid>
        </DialogContent>}
        <DialogActions>
          <Button onClick={handleClosehistory}>Cancel</Button>
          <Button >Update</Button>
        </DialogActions>
      </Dialog>



            <Dialog open={openline} onClose={handleCloseline}>

                <DialogContent style={{ width: 350, height: 365 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>Medicine</h5>
                        </Grid>

                        <Grid item xs={12} sm={9} style={{ marginTop: "15px" }}>

                            <Autocomplete



                                {...defaultPropsmediciene}
                                // value={mmuvalue}
                                // onChange={(event, newValue) => {
                                //     getpatient(newValue?.code)
                                //     setMmuvalue(newValue);
                                //     console.log(newValue);
                                // }}

                                renderInput={(params) => (
                                    <TextField {...params} label="Medicine" />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>Frequency</h5>
                        </Grid>

                        <Grid item xs={12} sm={9} style={{ marginTop: "8px" }}>

                            <Autocomplete



                                {...defaultPropsfrequency}
                                // value={mmuvalue}
                                // onChange={(event, newValue) => {
                                //     getpatient(newValue?.code)
                                //     setMmuvalue(newValue);
                                //     console.log(newValue);
                                // }}

                                renderInput={(params) => (
                                    <TextField {...params} label="frequency jjjjjjjjjj" />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>UOM</h5>
                        </Grid>

                        <Grid item xs={12} sm={9} style={{ marginTop: "18px" }}>
                            {" " + "UOM"}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>Form</h5>
                        </Grid>

                        <Grid item xs={12} sm={9} style={{ marginTop: "18px" }}>
                            {" " + "Form"}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>Dose</h5>
                        </Grid>

                        <Grid item xs={8} sm={5} style={{
                            border: "1px solid #727375",
                            borderRadius: "5px",
                            marginTop: "35px",

                        }}
                        >
                            {"1"}

                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>

                            <h5 style={{ marginTop: "22px" }}>Duration Days</h5>
                        </Grid>

                        <Grid item xs={8} sm={5} style={{
                            border: "1px solid #727375",
                            borderRadius: "5px",
                            marginTop: "35px",

                        }}
                        >
                            {"1"}

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseline}>Cancel</Button>
                    <Button >Submit</Button>
                </DialogActions>
            </Dialog>

            <Footer />
        </DashboardLayout>
    );
};



export default FollowUps;

