// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function ProjectmmuInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [projectValue, setProjectValue] = useState(null);
  const [projectList, setProjectList] = useState("");

  const [mmuValue, setMmuValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);

  const [mmuList, setMmuList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  const defaultPropsProject = {
    options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsmmu = {
    options: !mmuList ? [{ id: 0, name: "Loading..." }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const dataObj = {
    columns: [
      { Header: "project", accessor: "projectname" },
      { Header: "mmu", accessor: "mmu.name" },
      { Header: "mmu code", accessor: "mmu.code" },
      // { Header: "created by", accessor: "createdby" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  const removeItem = function (code) {
    const objWithIdIndex = tableData.rows.findIndex(
      (obj) => obj.mmu.code === code
    );
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.projectMMUList = tableData.rows;
  };
  const { formField, values, errors, touched } = formData;

  const { projectselection, mmu, state, district, subDistrict, pincode } =
    formField;
  var { address: addressV, createproject: createprojectV } = values;

  const addData = () => {
    console.log("69", projectValue);

    const mmuObj = {
      name: mmuValue.name,
      code: mmuValue?.code,
    };
    const projectMMU = {
      projectcode: projectValue?.code,
      projectname: projectValue?.name,
      mmu: mmuObj,
    };
    projectMMU.action = (
      <span>
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeItem(projectMMU?.mmu?.code)}
          />
        </Link>
      </span>
    );

    let filter = tableData.rows.filter(
      (a) => a.mmu.code == projectMMU.mmu.code
    );

    if (filter.length < 1) {
      tableData.rows.push(projectMMU);

      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.projectMMUList = tableData.rows;
      console.log("value", tableData.rows);
    }
  };
  async function getData() {
    //setting pincode===========
    await axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get`
      )
      .then((response) => {
        setMmuList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setProjectList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add MMU to Project</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsProject}
                value={projectValue}
                onChange={(event, newValue) => {
                  setProjectValue(newValue);
                  values.project = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={projectselection.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                {...defaultPropsmmu}
                value={mmuValue}
                onChange={(event, newValue) => {
                  setMmuValue(newValue);
                  values.mmu = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label={mmu.label} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "25px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={tableData}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectmmuInfo
ProjectmmuInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectmmuInfo;
