import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

const StockList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [wareHouseList, setWarehouseList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).childwarehouselist
  );
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const [warevalue, setWarevalue] = useState(null);

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const dataObj = {
    columns: [
      { Header: "name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Item Type", accessor: "itemtype" },
      { Header: "mmu code", accessor: "mmucode" },
      { Header: "quantity pending", accessor: "quantitypndg" },
      { Header: "quantity rcvd", accessor: "quantityrcvd" },
      { Header: "quantity Rqst", accessor: "quantityrqst" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);

  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + " (" + option.vehicle?.name + ")":option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // async function onDelete(item) {
  //   if (
  //     window.confirm("Are you sure that you want to delete that user record")
  //   ) {
  //     await axios
  //       .put(
  //         "https://apithf.softwaremathematics.com/pincode-service/api/pincode/v1/delete",
  //         item
  //       )
  //       .then((response) => {
  //         console.log(response);
  //         getVillageData(item.state?.code, item.district?.code);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }

  // async function updateData() {
  //   item.name = Updatename;
  //   item.description = description;
  //   await axios
  //     .put(
  //       "https://apithf.softwaremathematics.com/designation-service-mongo/api/designation/v2/update",
  //       item
  //     )
  //     .then((response) => {
  //       setOpen(false);
  //       getData();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  async function getDataBywharehouse(wharehouse) {
    console.log("127", wharehouse);

    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },

        { Header: "UOM", accessor: "uom" },
        { Header: "current stock", accessor: "currentstock" },
        { Header: "Quantity demand", accessor: "quantityindemand" },
        { Header: "Item Type", accessor: "itemtype" },
        { Header: "mmu code", accessor: "mmucode" },
        { Header: "quantity pending", accessor: "quantitypndg" },
        { Header: "quantity rcvd", accessor: "quantityrcvd" },
        { Header: "quantity Rqst", accessor: "quantityrqst" },
      ],
      rows: [],
    };
    axios
      .get(
        `stock-service/api/Stock/v1/get?whid=${wharehouse}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDataBymmu(mmulist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },

        { Header: "UOM", accessor: "uom" },
        { Header: "current stock", accessor: "currentstock" },
        { Header: "Item Type", accessor: "itemtype" },
        { Header: "mmu code", accessor: "mmucode" },
        { Header: "quantity pending", accessor: "quantitypndg" },
        { Header: "quantity rcvd", accessor: "quantityrcvd" },
        { Header: "quantity Rqst", accessor: "quantityrqst" },
      ],
      rows: [],
    };
    axios
      .get(
        `stock-service/api/Stock/v1/get?.mmucode=${mmulist}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  // useEffect(() => {
  //   getWarehouseData();
  // }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Stock-List
                  </MDTypography>
                </span>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        console.log("248", newValue);
                        getDataBywharehouse(newValue?.whmmucode);
                        getMmuDataByWarehouse(newValue?.whmmucode);
                        setWarevalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280, marginLeft: "24px" }}
                      {...defaultPropsmmuCode}
                      onChange={(event, newValue) => {
                        console.log("267", newValue);
                        getDataBymmu(newValue?.code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/page/component/create-sub-distric"}
                >
                  <MdOutlineLibraryAdd />
                </Link> */}
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Designation List</DialogTitle>
        <DialogContent style={{ width: 550, height: 150 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog> */}
      <Footer />
    </DashboardLayout>
  );
};
export default StockList;
