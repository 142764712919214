// prop-type is a library for typechecking of props
import PropTypes, { func } from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";


// NewUser page components
import FormField from "layouts/pages/mmucreation/new mmucreation/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { DataObject } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

function TestInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [uomvalue, setUomvalue] = React.useState(null);
  const [AssignEquipmentvalue, setAssignEquipmentvalue] = React.useState(null);
 
  let [QuantityId, setQuantityId] = useState("0");
  const [QTY, setQTY] = useState("");
  const [asset, setAsset] = useState("");

  const [uomList, setUomList] = useState("");
  const [AssignEquipmentList, setAssignEquipmentList] = useState("");
  const [mapwareList, setmapwareList] = useState("");
  const [open, setOpen] = React.useState(false);

  const [inputData, setInputData] = useState({
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  });
  const mmuObj = {
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  };
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

 
  const dataObj = {
    columns: [
     
      { Header: "investigation name", accessor: "name" },
      { Header: "Uom", accessor: "uom" },
      { Header: "Range", accessor: "range" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableData, setTableData] = useState(dataObj);

  const [givenQty, setGivenQty] = useState();
  const [select, setSelect] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

 
  const { formField, values, errors, touched } = formData;

  // uom
  const defaultPropssUOM = {
    options: !uomList
      ? [
          { name: "Loading", id: "x" }]
      : uomList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

 
  const {
    name,testname,investigationname,uom,range
  } = formField;
  var {
    name: nameV,
    testname: testnameV,
    investigationname:investigationnameV,
    range:rangeV,
    EquipmentList: EquipmentListV,
  } = values;

  const removeItem = function (name) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.name === name);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.investigationList = tableData.rows;
  };
  // const EditItem = function (id) {
  //   console.log("136", id);
  //   setQuantityId(id)
  //   setQTY(quantity);
  //   setAsset(assetNo);
  //   setOpen(true);
  // };
  const changequantityassetno = function () {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.id === QuantityId);
    
    tableData.rows[objWithIdIndex].inputQty = QTY;
    tableData.rows[objWithIdIndex].inputSerialNo = asset;
   
    
    dataObj.rows = tableData.rows;
    setTableData(dataObj);
    setOpen(false);
  };
  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };


  const addData = () => {
    const investigationObject={
      
      name: values.investigationname,
      range: values.range,
      uom: values.uom,
      
    }
    console.log("147",investigationObject);
    investigationObject.action = (
      <span>
        <Link to={{}}>
          {/* <FaRegEdit
            style={{
              color: "#008CBA",
              width: "25px",
              height: "25px",
              cursor: "pointer",
              marginRight: "18px"
            }}
            onClick={() => EditItem(investigationObject.id)}

          /> */}
        </Link>
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",

            }}
            onClick={() => removeItem(investigationObject.name)}
          />
        </Link>

      </span>
    );

  

    let filter = tableData.rows.filter(
      (a) => a.name == investigationObject.name
    );

    if (filter.length < 1) {

      tableData.rows.push(investigationObject);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.investigationList = tableData.rows;

    }
   
  };

  async function submitHandle() {
    console.log("sub", tableData);


  }
  async function getData() {
 await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=TEST_UOM`
      )
      .then((response) => {
        setUomList(response?.data?.data?.dtoList);
        setLoading(false);
      })
  
      .catch((e) => {
        console.log("error", e);
      });
 
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Test master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={testname.type}
                label={testname.label}
                name={testname.name}
                value={testnameV}
                placeholder={testname.placeholder}
                error={errors.testname && touched.testname}
                success={testnameV.length > 0 && !errors.testname}
              />
            </Grid>
          </Grid>
          <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="black"
                          
                          mt={1}
                        >
                          Investigation list
                        </MDTypography>
          <Grid container spacing={3}>
           <Grid item xs={12} sm={6}>
              <FormField
                type={investigationname.type}
                label={investigationname.label}
                name={investigationname.name}
                value={investigationnameV}
                placeholder={investigationname.placeholder}
                error={errors.investigationname && touched.investigationname}
                success={investigationnameV.length > 0 && !errors.investigationname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssUOM}
                value={uomvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setUomvalue(newValue);

                  values.uom = newValue?.name;
                  // console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.uom && touched.uom}
                    // success={uom.length > 0 && !errors.uom}
                    variant="standard"
                    label={uom.label}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={range.type}
                label={range.label}
                name={range.name}
                value={rangeV}
                placeholder={range.placeholder}
                // error={errors.range && touched.range}
                // success={rangeV.length > 0 && !errors.range}
              />
            </Grid>

           
          
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
        </MDBox>
      )}
     
    </MDBox>
  );
}

// typechecking props for TestInfo
TestInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TestInfo;
