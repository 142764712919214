// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// NewUser page components
import FormField from "layouts/pages/Requsition/new Requsition/componets/FormField";
import { Autocomplete, Link } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { style } from "@mui/system";
import { FaRegTimesCircle } from "react-icons/fa";

function RequisitionInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [MRNumberValue, setMRNumberValue] = React.useState(null);
  const [WHIDValue, setWHIDValue] = React.useState(null);
  const [OWHIDValue, setOWHIDValue] = React.useState(null);
  const [flowTypeValue, setflowTypeValue] = React.useState(null);
  const [reqLineItemIdValue, setreqLineItemIdValue] = React.useState(null);
  const [mmuCodeValue, setmmuCodeValue] = React.useState(null);
  const [typeList, setTypelist] = useState("");
  const [typeValue, setTypeValue] = React.useState(null);
  const [itemvalue, setItemvalue] = React.useState(null);
  const [itemList, setItemList] = useState("");
  const [MRNNumberList, setMRNNumberList] = useState("");
  const [itype, setItype]=useState("");
  const [WHIDList, setWHIDList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).parentwarehouselist
  );
  const [flowTypeList, setflowTypeList] = useState("");
  const [reqLineItemIdList, setreqLineItemIdList] = useState("");
  const [showhide, setShowhide] = useState("");
  const [mmuCodeList, setmmuCodeList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );

  const { formField, values, errors, touched } = formData;
  
  const defaultProps = {
    options: !itemList ? [{ name: "Loading...", id: 0 }] : itemList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsWHID = {
    options: !WHIDList ? [{ name: "Loading...", id: 0 }] : WHIDList,
    getOptionLabel: (option) => (option ? option?.name : ""),
   
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList ? [{ name: "Loading...", id: 0 }] : mmuCodeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropsreqLineItemId = {
    options: !reqLineItemIdList
      ? [{ name: "Loading...", id: 0 }]
      : reqLineItemIdList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropstype = {
    options: !typeList ? [{ name: "Loading...", id: 0 }] : typeList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  var { quantityRqst: quantityRqstV } = values;
  const [quantityRequest, setQuantityRequest] = useState(null);
  const [unitprice, setUnitprice] = useState(null);
  const [cuurentQuantity, setCuurentQuantity] = useState("");
  
  const [reqQuantity, setReqQuantity] = useState("");
  const [demand, setDemand] = useState("");
  const [vendorDetail, setVendorDetail] = useState("");

  // const [quantityRequest, setQuantityRequest] = useState(null);
  // const [quantityRequest, setQuantityRequest] = useState(null);
  // const [quantityRequest, setQuantityRequest] = useState(null);
  const [medRequisitionGenArr, setMedRequisitionGenArr] = useState([]);
  const [inputData, setInputData] = useState({
    approvedby: "",
    approveddate: "",
    blockid: "",
    classname: "",
    createdby:
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname,
    createddate: null,
    documentofproof: "",
    id: null,
    itemtype: "",
    latlong: "",
    location: null,
    mmucode: "",
    mmuid: "",
    mmuitemcode: "",
    mmuname: "",
    modifiedby: "",
    modifieddate: null,
    mrnumber: "",
    name: "",
    quantityapproved: "",
    quantitypndg: "",
    quantityrcvd: "",
    quantityrqst: quantityRequest,
    recstatus: "OPEN",
    remarks: "",
    sapitemcode: "",
    status: "ACTIVE",
    syncstatus: "SYNCED",
    tempid: "",
    uom: "",
    whid: "",
    whname: "",
    owhid: "",
    owhname: "",
  });

  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "Current Qty", accessor: "currentstock" },
      { Header: "Stock Request Qty", accessor: "stockquantityrqst" },
      { Header: "Requested Qty", accessor: "quantityrqst" },

      // { Header: "stock unit", accessor: "loc" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const dataObjWarehouse = {
    columns: [
      { Header: "Name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "Current Qty", accessor: "currentstock" },
      { Header: "Stock Request Qty", accessor: "stockquantityrqst" },
      { Header: "Requested Qty", accessor: "quantityrqst" },
      { Header: "unit price", accessor: "unitprice" },
      { Header: "net price", accessor: "netprice" },
      // { Header: "stock unit", accessor: "loc" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };

  //const [tableData, setTableData] = useState(dataObj);

  const [tableData, setTableData] = useState(dataObj);

  const { type, WHID, quantityRqst, mmuCode, MMUItemCode,itemtype } = formField;

  const removeItem = function (id) {
    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.id === id);
    console.log("i", objWithIdIndex);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.finalMedRequisitionGenArr = tableData.rows;
    console.log("rem", tableData.rows);
    console.log("140", values);
  };

  const addData = (item, quantity) => {
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },

        { Header: "UOM", accessor: "uom" },
        { Header: "Current Qty", accessor: "currentstock" },
        { Header: "Stock Request Qty", accessor: "stockquantityrqst" },
        { Header: "Requested Qty", accessor: "quantityrqst" },

        // { Header: "stock unit", accessor: "loc" },
        { Header: "Action", accessor: "action" },
      ],
      rows: [],
    };

    const finalObj = {
      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,

      id: item.id,
      itemtype: item.itemtype,

      mmucode: item.mmucode,
      mmuid: item.mmuid,
      mmuitemcode: item.mmuitemcode,
      mmuname: item.mmuname,

      name: item.name,

      quantityrqst: quantity,
      recstatus: "OPEN",
      remarks: "",
      sapitemcode: item.itemcode,
      status: "ACTIVE",
      syncstatus: "SYNCED",
      tempid: "",
      uom: item.uom,
      whid: item.whid,
      whname: item.whname,
      owhid: item.owhid,
      owhname: item.owhname,
      loc: item.whid ? item.whname : item.mmuname,
      // oloc: item.owhid ? item.owhname : item.mmuname,
      stockquantityrqst: item?.quantityrqst,
      currentstock: item?.currentstock,
      action: (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeItem(finalObj?.id)}
            />
          </Link>
        </span>
      ),
    };
    // console.log("188", quantity);

    // console.log("188", finalObj);
    let filter = tableData.rows.filter((a) => a.id == finalObj.id);

    if (filter.length < 1) {
      medRequisitionGenArr.push(finalObj);
      tableData.rows.push(finalObj);
      setMedRequisitionGenArr(medRequisitionGenArr);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.finalMedRequisitionGenArr = tableData.rows;
    }
    console.log("198", values);
  };
  const addData2 = (item, quantity, unitprice) => {
    var netprice = 1 * quantity * unitprice;
    const dataware = {
      columns: [
        { Header: "Name", accessor: "name" },

        { Header: "UOM", accessor: "uom" },
        { Header: "Current Qty", accessor: "currentstock" },
        { Header: "Stock Request Qty", accessor: "stockquantityrqst" },
        { Header: "Requested Qty", accessor: "quantityrqst" },
        { Header: "unit price", accessor: "unitprice" },
        { Header: "net price", accessor: "netprice" },
        // { Header: "stock unit", accessor: "loc" },
        { Header: "Action", accessor: "action" },
      ],
      rows: [],
    };
    const finalObj = {
      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,

      id: item.id,
      itemtype: item.itemtype,

      mmucode: item.mmucode,
      mmuid: item.mmuid,
      mmuitemcode: item.mmuitemcode,
      mmuname: item.mmuname,

      name: item.name,

      quantityrqst: quantity,
      recstatus: "OPEN",
      remarks: "",
      sapitemcode: item.itemcode,
      status: "ACTIVE",
      syncstatus: "SYNCED",
      tempid: "",
      uom: item.uom,
      whid: item.whid,
      whname: item.whname,
      owhid: item.owhid,
      owhname: item.owhname,
      loc: item.whid ? item.whname : item.mmuname,
      // oloc: item.owhid ? item.owhname : item.mmuname,
      stockquantityrqst: item?.quantityrqst,
      currentstock: item?.currentstock,
      vendor: vendorDetail.trim(),
      unitprice: unitprice,
      netprice: netprice,
      ponumber: "PO",
      action: (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeItem(finalObj?.id)}
            />
          </Link>
        </span>
      ),
    };
   
    let filter = tableData.rows.filter((a) => a.id == finalObj.id);

    if (filter.length < 1) {
      medRequisitionGenArr.push(finalObj);
      tableData.rows.push(finalObj);
      setMedRequisitionGenArr(medRequisitionGenArr);
      dataware.rows = tableData.rows;
      setTableData(dataware);
      values.finalMedRequisitionGenArr = tableData.rows;
    }
    console.log("198", values);
  };

  function changestatus(status) {
    console.log("168", status);
    setShowhide(status);
  }
  //setmmuCodeList(localStorage.getItem(('project-Detail', JSON.stringify(response.data.data.projectAggregation));
  async function getData() {
    await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=WAREHOUSE`
      )
      .then((response) => {
        setTypelist(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });


      await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=ITEM`
      )
      .then((response) => {
        setItemList(response?.data?.data?.dtoList);
        console.log("44", response?.data?.data?.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
   
    
  }

  async function getStockDataByMmu(mmucode) {
    await axios
      .get(
        `stock-service/api/Stock/v1/get?mmucode=${mmucode}&itemtype=${itype}`
      )
      .then((response) => {
        setreqLineItemIdList(response?.data?.data?.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getStockDataByWareHouse(whcode) {
    console.log("399",itype)
    await axios
      .get(
        `stock-service/api/Stock/v1/get?whid=${whcode}&itemtype=${itype}`
      )
      .then((response) => {
        setreqLineItemIdList(response?.data?.data?.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
    setLoading(false);
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Create Requisition</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
              <Autocomplete
                {...defaultProps}
                value={itemvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setItemvalue(newValue);
                  // attributes.keyList = newValue?.attributes;
                  // values.attributes = attributes;
                  // setAttributes(attributes);
                  // if (newValue?.attributes == null) {
                  //   setAttFlag(false);
                  // } else {
                  //   setAttFlag(true);
                  // }

                  values.itemtype = newValue?.name;
                  setItype(newValue?.name);
                  console.log("450", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.itemtype && touched.itemtype}
                    success={itemtype.length > 0 && !errors.itemtype}
                    variant="standard"
                    label={itemtype.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                {...defaultPropstype}
                value={typeValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setTypeValue(newValue);
                  if (newValue?.code === "WA9946") {
                    setTableData(dataObjWarehouse);
                  } else {
                    setTableData(dataObj);
                  }
                  values.type = newValue?.code;

                  changestatus(values.type);
                  console.log("252", values.type);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    //  error={errors.type && touched.type}
                    //success={type.length > 0 && !errors.type}
                    variant="standard"
                    label="Store Type"
                  />
                )}
              />
            </Grid>

            {showhide === "MM9185" && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  {...defaultPropsmmuCode}
                  value={mmuCodeValue}
                  onChange={(event, newValue) => {
                    // handleChange(event);

                    setmmuCodeValue(newValue);
                    inputData.mmucode = newValue?.code;
                    inputData.mmuname = newValue?.name;
                    inputData.mmuid = newValue?.id;
                    inputData.whname = "";
                    inputData.whid = "";
                    console.log(newValue);
                    getStockDataByMmu(newValue?.code);
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      // error={errors.mmuCode && touched.mmuCode}
                      // success={mmuCode.length > 0 && !errors.mmuCode}
                      variant="standard"
                      label={mmuCode.label}
                    />
                  )}
                />
              </Grid>
            )}

            {showhide === "HU5289" && (
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  {...defaultPropsWHID}
                  value={OWHIDValue}
                  onChange={(event, newValue) => {
                    // handleChange(event);

                    setOWHIDValue(newValue);

                    inputData.owhname = newValue?.name;
                    inputData.owhid = newValue?.whmmucode;
                    inputData.mmucode = "";
                    inputData.mmuname = "";
                    inputData.mmuid = "";
                    console.log(newValue);
                    getStockDataByWareHouse(newValue?.whmmucode);
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      // error={errors.WHID && touched.WHID}
                      // success={WHID.length > 0 && !errors.WHID}
                      variant="standard"
                      label="Requesting Warehouse"
                    />
                  )}
                />
              </Grid>
            )}
            {showhide === "WA9946" && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  {...defaultPropsWHID}
                  value={OWHIDValue}
                  onChange={(event, newValue) => {
                    // handleChange(event);

                    setOWHIDValue(newValue);

                    inputData.owhname = newValue?.name;
                    inputData.owhid = newValue?.whmmucode;
                    inputData.mmucode = "";
                    inputData.mmuname = "";
                    inputData.mmuid = "";
                    console.log(newValue);

                    getStockDataByWareHouse(newValue?.whmmucode);
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      // error={errors.WHID && touched.WHID}
                      // success={WHID.length > 0 && !errors.WHID}
                      variant="standard"
                      label="Requesting Warehouse"
                    />
                  )}
                />
              </Grid>
            )}

            {showhide === "HU5289" && (
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  {...defaultPropsWHID}
                  value={WHIDValue}
                  onChange={(event, newValue) => {
                    // handleChange(event);

                    setWHIDValue(newValue);

                    inputData.whname = newValue?.name;
                    inputData.whid = newValue?.whmmucode;
                    inputData.mmucode = "";
                    inputData.mmuname = "";
                    inputData.mmuid = "";
                    console.log(newValue);
                    // getStockDataByWareHouse(newValue?.whmmucode);
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      // error={errors.WHID && touched.WHID}
                      // success={WHID.length > 0 && !errors.WHID}
                      variant="standard"
                      label="Servicing Warehouse"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          {showhide === "WA9946" && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} mt={1}>
                <MDInput
                  style={{ width: "100%" }}
                  label="Vendor detail..."
                  size="large"
                  multiline
                  value={vendorDetail}
                  onChange={(e) => setVendorDetail(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              {/* item dropdown */}
              <Autocomplete
                {...defaultPropsreqLineItemId}
                value={reqLineItemIdValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setreqLineItemIdValue(newValue);
                  inputData.id = newValue?.id;
                  inputData.itemtype = newValue?.itemtype;
                  inputData.name = newValue?.name;
                  inputData.itemcode = newValue?.itemcode;
                  inputData.uom = newValue?.uom;
                  inputData.quantityrqst = newValue?.quantityrqst;
                  inputData.currentstock = newValue?.currentstock;
                  setReqQuantity(newValue?.quantityrqst);
                  setDemand(newValue?.quantityindemand);
                  setCuurentQuantity(newValue?.currentstock);
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={MMUItemCode.label}
                  />
                )}
              />
            </Grid>
            {(showhide === "MM9185" || showhide === "HU5289") && (
              <Grid item xs={12} sm={4}>
                <FormField
                  type="number"
                  label="Quantity"
                  name="quantity"
                  value={quantityRequest}
                  placeholder="Quantity"
                  onChange={(e) => {
                    console.log(e);
                    setQuantityRequest(e.target.value);
                  }}
                />
              </Grid>
            )}
            {(showhide === "MM9185" || showhide === "HU5289") && (
              <Grid item xs={12} sm={2}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  style={{ marginTop: "10px" }}
                  onClick={() => addData(inputData, quantityRequest)}
                >
                  Add
                </MDButton>
              </Grid>
            )}
            {showhide === "WA9946" && (
              <Grid item xs={12} sm={2}>
                <FormField
                  type="number"
                  label="Quantity"
                  name="quantity"
                  value={quantityRequest}
                  placeholder="Quantity"
                  onChange={(e) => {
                    console.log(e);
                    setQuantityRequest(e.target.value);
                  }}
                />
              </Grid>
            )}
            {showhide === "WA9946" && (
              <Grid item xs={12} sm={2}>
                <FormField
                  type="number"
                  label="Unit Price"
                  name="price"
                  value={unitprice}
                  placeholder="Unit Price"
                  onChange={(e) => {
                    console.log(e);
                    setUnitprice(e.target.value);
                  }}
                />
              </Grid>
            )}
            {showhide === "WA9946" && (
              <Grid item xs={12} sm={2}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  style={{ marginTop: "10px" }}
                  onClick={() =>
                    addData2(inputData, quantityRequest, unitprice)
                  }
                >
                  Add
                </MDButton>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                label="Current Quantity"
                name="current_quantity"
                // value="12"
                value={cuurentQuantity}
                placeholder="Cuurent Quantity"
                disabled="true"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                label="Requested Quantity"
                name="req_quantity"
                value={reqQuantity}
                disabled="true"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type="number"
                label="demand"
                name="demand_quantity"
                value={demand}
                disabled="true"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RequisitionInfo
RequisitionInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RequisitionInfo;
