// import React from "react";

// // @mui material components
// import Card from "@mui/material/Card";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// // Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// // Data
// // import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// import { useEffect, useState } from "react";
// // import { getRoles } from "@testing-library/react";

// import axios from "axios";
// import { BarLoader } from "react-spinners";
// // import { item } from "examples/Sidenav/styles/sidenavItem";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaRegEdit } from "react-icons/fa";
// import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";

// import Button from "@mui/material/Button";

// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";

// import DialogTitle from "@mui/material/DialogTitle";
// import { Autocomplete, TextField } from "@mui/material";
// import DateTimePicker from "react-datetime-picker";

// function ScheduleList() {
//   const comp = "SM";
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [color] = useState("#344767");
//   const [enddate, setEnddate] = useState(new Date());

//   const [startdate, setStartdate] = useState(new Date());
//   const [title, setTitle] = useState("");

//   const [pinCodeList, setPinCodeList] = useState([]);
//   const [pincodeValue, setPincodeValue] = useState('');
//   const [pincodeFinal, setPincodeFinal] = useState(null);
//   const [err, setErr] = useState("");
//   const [item, setItem] = useState({});
//   const [open, setOpen] = React.useState(false);
//   const [defaultMMU, setDefaultMMU] = useState();
//   const [mmucode, setMmucode] = useState("");
//   const [mmuList, setMmuList] = useState("");
//   const [updateObject, setUpdateObject] = useState({});
//   const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };

//   const rolecode = JSON.parse(localStorage.getItem("mmu-auth")).rolecode;

//   const dataObj = {
//     columns: [
//       { Header: "title", accessor: "title" },
//       { Header: "address", accessor: "finalAddress" },
//       { Header: "start date", accessor: "startdate" },
//       { Header: "end date", accessor: "enddate" },
//       { Header: "Status", accessor: "recstatuscolor" },
//       { Header: "action", accessor: "action" },
//     ],
//     rows: [],
//   };

//   const dataObjProjectCoordintor = {
//     columns: [
//       { Header: "title", accessor: "title" },
//       { Header: "address", accessor: "finalAddress" },
//       { Header: "start date", accessor: "startdate" },
//       { Header: "end date", accessor: "enddate" },
//       { Header: "Status", accessor: "recstatuscolor" },

//     ],
//     rows: [],
//   };
//   const defaultPropPincode = {
//     options: !pinCodeList
//       ? [
//         {
//           id: 0,

//           state: {
//             shortname: "--",
//             name: "Loading...",
//           },
//           district: {
//             shortname: "",
//             name: "",
//           },
//           village: {
//             shortname: "",
//             name: "",
//           },
//           subdistrict: {
//             shortname: "",
//             name: "",
//           },
//           pincode: "",
//         },

//       ]
//       : pinCodeList,

//     getOptionLabel: (option) =>
//       option.village?.name +
//       " " +
//       option.subdistrict?.name +
//       " (" +
//       option.pincode +
//       ") " +
//       option.district?.name +
//       " " +
//       option.state?.name,
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };

//   const [tableDatas, setTableDatas] = useState(dataObj);
//   const defaultPropsMMU = {
//     options: !mmuList ? [{ name: "Loading...", id: 0,vehicle:{name:''} }] : mmuList,
//     getOptionLabel: (option) => option.name +" ("+option.vehicle.name+")",
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };

//   async function onUpdatedata(object) {

//     setUpdateObject(object);
//     setTitle(object.title);
//     setPincodeValue(object.pincode);
//     setStartdate(new Date(object.startdate));

//     setEnddate(new Date(object.enddate));

//     setOpen(true);
//   }

//   const handleClose = () => {
//     console.log("dsfsdfs");
//     setOpen(false);
//   };

//   async function getMMUData() {
//     await axios
//       .get(
//         `https://apithf.softwaremathematics.com/mmucreation-service/api/MMUCreation/v1/get`
//       )
//       .then((response) => {
//         setMmuList(response?.data?.data?.dtoList);
//         if (response?.data?.data?.dtoList.length > 0) {
//           console.log("3", response?.data?.data?.dtoList[0]);
//       //    setDefaultMMU(response?.data?.data?.dtoList[0]);
//         //  setMmucode(response?.data?.data?.dtoList[0].code);
//          // getPinCode(response?.data?.data?.dtoList[0].code)
//            setLoading(false);
//         }
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function getPinCode(mmuCode) {
//     axios
//       .get(
//         `https://apithf.softwaremathematics.com/project-service/api/Project/v1/getPinCode?mmu.code=${mmuCode}`
//       )
//       .then((response) => {
//         console.log("pincode", response?.data?.data?.dtoList);
//         var finalPincodelist = response?.data?.data?.dtoList?.map((t) =>
//           t.pincode
//         )
//         console.log("208", finalPincodelist);

//         setPinCodeList(finalPincodelist);
//         getData(mmuCode);

//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function onDelete(item) {

//     if (
//       window.confirm("Are you sure that you want to delete that Schedule " + item.title )
//     ) {
//       delete item.action;
//       delete item.recstatuscolor;
//       delete item.finalAddress;
//       await axios
//         .put(
//           'https://apithf.softwaremathematics.com/event-service/api/event/v1/delete',
//           item
//         )
//         .then((response) => {
//           // console.log("del", response.data.data.dto);
//           // console.log("de", defaultMMU);
//           // console.log("dem", mmucode);
//             console.log(response);
//           getData(response.data.data.dto);
//           console.log("223",response.data.data.dto)
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }

//   }
//   async function updateEvent() {
//     console.log("up", updateObject);
//     updateObject.title = title;
//     updateObject.pincode=pincodeValue;

//     updateObject.startdate = startdate;
//     updateObject.enddate = enddate;
//     updateObject.modifiedby =
//       JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//       " " +
//       JSON.parse(localStorage.getItem("mmu-auth")).lastname;
//      delete updateObject.action;
//      delete updateObject.finalAddress;
//      delete updateObject.recstatuscolor;
//     console.log("238", updateObject);
//     await axios
//       .put(
//         'https://apithf.softwaremathematics.com/event-service/api/event/v1/update',
//         updateObject
//       )
//       .then((response) => {
//         setOpen(false);
//         //getData(mmucode);
//         getMMUData();

//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   function getData(mmucode) {
//     setLoading(true);
//     console.log("258", mmucode);

//     var finalDataObj = dataObj;
//     if (rolecode == "PRC23") {
//       finalDataObj = dataObjProjectCoordintor
//     }

//     axios
//       .get(
//         `https://apithf.softwaremathematics.com/event-service/api/event/v1/get?mmucode=${mmucode}`
//       )
//       .then((response) => {

//         response?.data?.data?.dtoList?.map((item) => {
//           item.startdate = new Date(item.startdate).toDateString();
//           item.enddate = new Date(item.enddate).toDateString();

//           if (item.recstatus === "APPROVED") {
//             item.recstatuscolor = <span style={{ color: "green" }}>
//               {item.recstatus}
//             </span>
//           }
//           else if (item.recstatus === "REJECTED") {
//             item.recstatuscolor = <span style={{ color: "RED" }}>
//               {item.recstatus}
//             </span>
//           }
//           else if (item.recstatus === "OPEN") {
//             item.recstatuscolor = <span style={{ color: "BLUE" }}>
//               {item.recstatus}
//             </span>
//           }
//           else {
//             item.recstatuscolor = <span style={{ color: "grey" }}>
//               {item.recstatus}
//             </span>
//           }

//           if (typeof item.pincode !== "undefined" && item.pincode !== null) {
//             item.finalAddress =
//               item.pincode?.village?.name +
//               " " +
//               item.pincode?.subdistrict?.name +
//               " (" +
//               item.pincode?.pincode +
//               ") " +

//               item.pincode?.district?.name +
//               " " +
//               item.pincode?.state?.name;
//           }
//           console.log("326",item.finalAddress)
//           item.action = (
//             <span>
//               <Link to={{}}>
//                 <FaRegEdit
//                   style={iconStyle}
//                   onClick={() => {
//                     onUpdatedata(item);
//                   }}
//                 />
//               </Link>
//               <Link to={{}}>
//                 <MdDelete
//                   disable="true"
//                   style={iconStyle}
//                   onClick={() => {
//                     onDelete(item);
//                   }}
//                 />
//               </Link>

//             </span>
//           );
//           finalDataObj.rows.push(item);
//         });
//         console.log("res70", dataObj);
//         // tableDatas.rows = dataObj.rows;
//         setTableDatas(dataObj);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   useEffect(() => {
//     // getData();
//     getMMUData();
//   }, []);
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       {!loading && (
//         <MDBox pt={3}>
//           <MDBox>
//             <Card>
//               <MDBox pl={3} pr={3} lineHeight={1}>
//                 <div style={addButtonStyle}>
//                   <span
//                     style={{
//                       alignSelf: "flex-start",
//                       marginTop: "20px",
//                     }}
//                   >
//                     <MDTypography variant="h5" fontWeight="medium">
//                       Schedule List
//                     </MDTypography>
//                   </span>
//                   <span
//                     style={{
//                       alignSelf: "flex-start",
//                       marginTop: "20px",
//                     }}
//                   >
//                     <Autocomplete
//                       defaultValue={defaultMMU}
//                       disablePortal
//                       id="combo-box-demo"
//                       sx={{ width: 280 }}
//                       {...defaultPropsMMU}
//                       onChange={(event, newValue) => {
//                        // getData(newValue?.code);
//                         getPinCode(newValue?.code)
//                         setMmucode(newValue?.code);
//                       }}
//                       renderInput={(params) => (
//                         <TextField {...params} label="MMU" />
//                       )}
//                     />
//                   </span>
//                   {JSON.parse(localStorage.getItem("mmu-auth")).rolecode !== "PRM5350" &&
//                     <Link
//                       style={{
//                         alignSelf: "flex-end",
//                         marginRight: "10px",
//                         marginTop: "-23px",
//                         color: "#344767",
//                       }}
//                       to={"/mmu/component/shceduler"}
//                     >
//                       <MdOutlineLibraryAdd disable="true" />
//                     </Link>
//                   }
//                 </div>
//               </MDBox>
//               <BarLoader
//                 color={color}
//                 loading={loading}
//                 aria-label="Loading Spinner"
//               />
//               <DataTable table={tableDatas} canSearch={true} />
//             </Card>
//           </MDBox>
//         </MDBox>
//       )}
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Update Schedule</DialogTitle>
//         <DialogContent style={{ width: 550, height: 400 }}>
//           <MDBox mb={2}>
//             <MDInput
//               type="text"
//               label="Title"
//               variant="standard"
//               fullWidth
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//             />
//           </MDBox>

//           <MDBox mb={2}>
//             <Autocomplete
//               {...defaultPropPincode}
//               value={pincodeValue}
//               onChange={(event, newValue) => {
//                 setPincodeValue(newValue);
//                 // setPincodeFinal(newValue?.pincode);
//                 console.log(newValue);
//               }}
//               renderInput={(params) => (
//                 <MDInput
//                   {...params}
//                   variant="standard"
//                   label="Select Location"
//                 />
//               )}
//             />
//           </MDBox>

//           <MDBox mb={2}>
//             <label>Select Start Date </label>
//             <DateTimePicker onChange={setStartdate} value={startdate} />
//           </MDBox>
//           <MDBox mb={2}>
//             <label>Select End Date </label>
//             <DateTimePicker onChange={setEnddate} value={enddate} />
//           </MDBox>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={updateEvent}>Submit</Button>
//         </DialogActions>
//       </Dialog>
//       <Footer />
//     </DashboardLayout>
//   );
// }
// export default ScheduleList;

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader, MoonLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import moment from "moment-timezone";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, TextField } from "@mui/material";
import DateTimePicker from "react-datetime-picker";
import AutorenewIcon from "@mui/icons-material/Autorenew";
function ScheduleList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [color] = useState("#344767");
  const [enddate, setEnddate] = useState(new Date());

  const [startdate, setStartdate] = useState(new Date());
  const [title, setTitle] = useState("");

  const [pinCodeList, setPinCodeList] = useState([]);
  const [pincodeValue, setPincodeValue] = useState("");
  const [pincodeFinal, setPincodeFinal] = useState(null);
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [defaultMMU, setDefaultMMU] = useState();
  const [mmucode, setMmucode] = useState("");
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );
  const [updateObject, setUpdateObject] = useState({});
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const iconStyle2 = { fontSize: "1.5em", color: "#D91029", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    var df =  moment(date, format).tz("Asia/Kolkata").format(format);
    return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
}
  const rolecode = JSON.parse(localStorage.getItem("mmu-auth")).rolecode;

  const dataObj = {
    columns: [
      { Header: "title", accessor: "title" },
      { Header: "address", accessor: "finalAddress" },
      { Header: "flag", accessor: "flag" },
      { Header: "start date", accessor: "startdateformated" },
      { Header: "end date", accessor: "enddateformated" },
      { Header: "Status", accessor: "recstatuscolor" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  };

  const dataObjProjectCoordintor = {
    columns: [
      { Header: "title", accessor: "title" },
      { Header: "address", accessor: "finalAddress" },
      { Header: "flag", accessor: "flag" },
      { Header: "start date", accessor: "startdateformated" },
      { Header: "end date", accessor: "enddateformated" },
      { Header: "Status", accessor: "recstatuscolor" },
    ],
    rows: [],
  };
  const defaultPropPincode = {
    options: !pinCodeList
      ? [
          {
            id: 0,

            state: {
              shortname: "--",
              name: "Loading...",
            },
            district: {
              shortname: "",
              name: "",
            },
            village: {
              shortname: "",
              name: "",
            },
            subdistrict: {
              shortname: "",
              name: "",
            },
            pincode: "",
          },
        ]
      : pinCodeList,

    getOptionLabel: (option) =>
      option?.village?.name +
      " " +
      option?.subdistrict?.name +
      " (" +
      option?.pincode +
      ") " +
      option?.district?.name +
      " " +
      option?.state?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) =>option?.vehicle?.name ? option?.vehicle?.name ? option?.name + " (" + option?.vehicle?.name + ")":option?.name:option?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onUpdatedata(object) {
    setUpdateObject(object);
    setTitle(object.title);
    setPincodeValue(object.pincode);
    setStartdate(new Date(object.startdate));

    setEnddate(new Date(object.enddate));

    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  // async function getMMUData() {
  //   await axios
  //     .get(
  //       `https://apithf.softwaremathematics.com/mmucreation-service/api/MMUCreation/v1/get`
  //     )
  //     .then((response) => {
  //       setMmuList(response?.data?.data?.dtoList);
  //       if (response?.data?.data?.dtoList.length > 0) {
  //         console.log("3", response?.data?.data?.dtoList[0]);
  //     //    setDefaultMMU(response?.data?.data?.dtoList[0]);
  //       //  setMmucode(response?.data?.data?.dtoList[0].code);
  //        // getPinCode(response?.data?.data?.dtoList[0].code)
  //          setLoading(false);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }
  async function getPinCode(mmuCode) {
    axios
      .get(
        `project-service/api/Project/v1/getPinCode?mmu.code=${mmuCode}`
      )
      .then((response) => {
        console.log("pincode", response?.data?.data?.dtoList);
        var finalPincodelist = response?.data?.data?.dtoList.map((t) => t.pincode);
        console.log("208", finalPincodelist);

        setPinCodeList(finalPincodelist);
        getData(mmuCode);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function onDelete(item) {
    if (
      window.confirm(
        "Are you sure that you want to delete that Schedule " + item.title
      )
    ) {
      delete item.action;
      delete item.recstatuscolor;
      delete item.finalAddress;
      await axios
        .put(
          "event-service/api/event/v1/delete",
          item
        )
        .then((response) => {
          // console.log("del", response.data.data.dto);
          // console.log("de", defaultMMU);
          // console.log("dem", mmucode);
          console.log(response);
          getData(response?.data?.data?.dto);
          console.log("223", response?.data?.data?.dto);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function updateEvent() {
    setLoading2(true)
    console.log("up", updateObject);
    // updateObject.title = title;
    updateObject.pincode = pincodeValue;
    updateObject.recstatus = "OPEN";
    // updateObject.startdate = startdate;
    // updateObject.enddate = enddate;
    updateObject.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    delete updateObject.action;
    delete updateObject.finalAddress;
    delete updateObject.recstatuscolor;
    console.log("238", updateObject);
    await axios
      .put(
        "event-service/api/event/v1/update",
        updateObject
      )
      .then((response) => {
        setOpen(false);
        setLoading2(false)
        getData(mmucode);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(mmucode) {
    setLoading(true);
    console.log("258", mmucode);

    var finalDataObj = dataObj;
    if (rolecode == "PRC23") {
      finalDataObj = dataObjProjectCoordintor;
    }

    axios
      .get(
        `event-service/api/event/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          // item.startdateformated = new Date(item.startdate).toDateString();
          // item.enddateformated = new Date(item.enddate).toDateString();

          item.startdateformated = convertTZ(item.startdate)
          item.enddateformated = convertTZ(item.enddate)
          

          if (item.recstatus === "APPROVED") {
            item.recstatuscolor = (
              <span style={{ color: "green" }}>{item.recstatus}</span>
            );
          } else if (item.recstatus === "REJECTED") {
            item.recstatuscolor = (
              <span style={{ color: "RED" }}>{item.recstatus}</span>
            );
          } else if (item.recstatus === "OPEN") {
            item.recstatuscolor = (
              <span style={{ color: "BLUE" }}>{item.recstatus}</span>
            );
          } else {
            item.recstatuscolor = (
              <span style={{ color: "grey" }}>{item.recstatus}</span>
            );
          }

          if (typeof item.pincode !== "undefined" && item.pincode !== null) {
            item.finalAddress =
              item?.pincode?.village?.name +
              " " +
              item?.pincode?.subdistrict?.name +
              " (" +
              item?.pincode?.pincode +
              ") " +
              item?.pincode?.district?.name +
              " " +
              item?.pincode?.state?.name;
          }
          // else {
          //   item.finalAddress = item.address;
          // }
       
          item.action = (
            <span>
              <Link to={{}}>
                <AutorenewIcon
                  fontSize="small"
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>
              <Link to={{}}>
                <MdDelete
                  disable="true"
                  style={iconStyle2}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          finalDataObj.rows.push(item);
        });
        console.log("res70", finalDataObj);
        // tableDatas.rows = dataObj.rows;
        setTableDatas(finalDataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    // getData(mmucode);
    // getMMUData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
    
        <MDBox pt={3}>
          <MDBox>
            <Card>
              <MDBox pl={3} pr={3} lineHeight={1}>
                <div style={addButtonStyle}>
                  <span
                    style={{
                      alignSelf: "flex-start",
                      marginTop: "20px",
                    }}
                  >
                    <MDTypography variant="h5" fontWeight="medium">
                      Schedule List
                    </MDTypography>
                  </span>
                  <span
                    style={{
                      alignSelf: "flex-start",
                      marginTop: "20px",
                    }}
                  >
                    <Autocomplete
                      defaultValue={defaultMMU}
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsMMU}
                      onChange={(event, newValue) => {
                        getData(newValue?.code);
                        // getPinCode(newValue?.code);
                        setMmucode(newValue?.code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="MMU" />
                      )}
                    />
                  </span>
                  {JSON.parse(localStorage.getItem("mmu-auth")).rolecode !==
                    "PRM5350" && (
                    <Link
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                      to={"/mmu/component/scheduler"}
                    >
                      <MdOutlineLibraryAdd disable="true" />
                    </Link>
                  )}
                </div>
              </MDBox>
              <BarLoader
                color={color}
                loading={loading}
                aria-label="Loading Spinner"
              />
                {!loading &&<DataTable table={tableDatas} canSearch={true} />}
            </Card>
          </MDBox>
        </MDBox>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Schedule</DialogTitle>
        <DialogContent style={{ width: 550, height: 400 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Title"
              variant="standard"
              fullWidth
              value={title}
              disabled="true"
              onChange={(e) => setTitle(e.target.value)}
            />
          </MDBox>

          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropPincode}
              value={pincodeValue}
              onChange={(event, newValue) => {
                setPincodeValue(newValue);
                // setPincodeFinal(newValue?.pincode);
                console.log(newValue);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Select Location"
                />
              )}
            />
          </MDBox>

          <MDBox mb={2}>
            <label>Select Start Date </label>
            <DateTimePicker
              onChange={setStartdate}
              value={startdate}
              disabled="true"
            />
          </MDBox>
          <MDBox mb={2}>
            <label>Select End Date </label>
            <DateTimePicker
              onChange={setEnddate}
              value={enddate}
              disabled="true"
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateEvent} variant="contained"> {loading2 ? <MoonLoader color="#FFFFFF" size={16} /> : 'Submit'}</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}
export default ScheduleList;
