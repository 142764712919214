// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { item } from "examples/Sidenav/styles/sidenavItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Accordians from "./Accordians";

function Requistionapproval() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [getResponse, setResponse] = useState([]);
  const [approvedQty, setApprovedQty] = useState(0);
  const [givenQty, setGivenQty] = useState("");
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [wareHouseList, setWarehouseList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).childwarehouselist
  );
  const [mmuCodeList, setmmuCodeList] = useState(null);
  // const [tableStatus, setTableStatus] = useState();
  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + " (" + option.vehicle?.name + ")":option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const tranactionalObj = {
    mmuid: "",
    mrnumber: "",
    ponnumber: "",
    whid: "",
    approvedby: "",
    approveddate: "",
    blockid: "",
    createdby: "",
    createddate: null,
    documentofproof: "",
    flowtype: "",
    id: null,
    itemname: "",
    itemtype: "",
    latlong: "",
    location: null,
    mmucode: "",
    modifiedby: "",
    modifieddate: "",
    quantity: approvedQty,
    referencenumber: "",
    reqLineitemid: "",
    sourcereference: "",
    targetreference: "",
    uom: "",
    recstatus: "OPEN",
    status: "ACTIVE",
    syncstatus: "SYNCED",
  };

  function returnResponse(getResponse) {
    return "";
  }
  function handleSubmit(medReqTxnDTO, recStatus) {
    console.log("obj", medReqTxnDTO);
    console.log("recStatus", recStatus);
    medReqTxnDTO.recstatus = recStatus;
    updateMedTxn(medReqTxnDTO);
  }

  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);

    await axios
      .get(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?recStatus=OPEN&owhid=${wharehouse}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "whid" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?recStatus=OPEN&mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "mmu", accessor: "mmucode" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const updateMedTxn = async (object) => {
    await axios
      .put(
        "medrequisitiongen-service/api/MedRequisitionGen/v1/updateRequisition",
        object
      )

      .then((response) => {
        console.log("107", response);
        var medReqTxnDTO = response.data.data.dto;
        if (
          medReqTxnDTO?.mmucode !== "" &&
          typeof medReqTxnDTO?.mmucode !== "undefined" &&
          medReqTxnDTO?.mmucode !== null
        ) {
          console.log("gettin by mmu");
          getDataBymmu(medReqTxnDTO?.mmucode);
        } else {
          console.log("gettin by ware");
          getDataBywharehouse(medReqTxnDTO?.whid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // navigate("pages/stock/requsition-list");
  };
  useEffect(() => {
    // getData();
    // getResponse;
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Requisition Approval
                  </MDTypography>
                </span>
                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getMmuDataByWarehouse(newValue?.whmmucode);
                        getDataBywharehouse(newValue?.whmmucode);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280, marginLeft: "24px" }}
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Requested Id :
                          </text>
                          {" " + values.medrequisitiongentxndto.mrnumber}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Submitted By :
                          </text>
                          {" " + values.medrequisitiongentxndto.createdby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>STATUS :</text>
                          {" " + values.medrequisitiongentxndto.recstatus}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Date :</text>

                          {"" +
                            new Date(
                              values.medrequisitiongentxndto.createddate
                            ).toDateString()}

                          <MDButton
                            circular="true"
                            color="success"
                            size="small"
                            style={{ marginLeft: "150px", marginRight: "15px" }}
                            variant="gradient"
                            onClick={() =>
                              handleSubmit(
                                values.medrequisitiongentxndto,
                                "APPROVED"
                              )
                            }
                          >
                            Approved
                          </MDButton>
                          <MDButton
                            size="small"
                            variant="gradient"
                            circular="true"
                            color="error"
                            onClick={() =>
                              handleSubmit(
                                values.medrequisitiongentxndto,
                                "REJECT"
                              )
                            }
                          >
                            Reject
                          </MDButton>
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Requistionapproval;
