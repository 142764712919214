/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";
import DatePicker from "react-flatpickr";

import moment from "moment-timezone";
import MDButton from "components/MDButton";
import { CSVLink } from "react-csv";
import { getFilter } from "service/reporting";
import { getComponent } from "service/inputComponents";


function MedicalRequisition() {
  function stringTokenizer(string, delim) {
    const st = string.split(delim);
    return st;
  }

  const { sales, tasks, patient, prescription } = reportsLineChartData;
  const [barData, setBarData] = useState(reportsBarChartData);
  const [patientData, setPatientData] = useState(patient);
  const [presData, setPresData] = useState(prescription);
  const [totalVisits, setTotalVisits] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalPres, setTotalPres] = useState(0);
  const [filterMmu, setFilterMmu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentyear, setCurrentYear] = useState(moment().format('YYYY'));
  const [currentyearvalue, setCurrentYearvalue] = useState(null);
  const [month, setMonth] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[1] : 0);
  const [year, setYear] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[0] : 0);
  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();
  const [color] = useState("#344767");
  const [showhide, setShowhide] = useState("");
  const [yearlist, setYearList] = useState(null);
  const [projectDetail, setProjectDetail] = useState(localStorage.getItem("project-Detail"));
  const [pincodeList, setPincodeList] = useState(
    // projectDetail ? JSON.parse(projectDetail).pincodelist : []
    []
  );

  const [mmuList, setMmuList] = useState(projectDetail ? JSON.parse(projectDetail).mmulist : [])
  const [projectList, setProjectList] = useState(projectDetail ? JSON.parse(projectDetail).projectlist : [])
  const dataObj = {
    columns: [
      { Header: "name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Item Type", accessor: "itemtype" },
      { Header: "warehouse code", accessor: "whid" },
      { Header: "mmu code", accessor: "mmucode" },
      { Header: "quantity pending", accessor: "quantitypndg" },
      { Header: "quantity rcvd", accessor: "quantityrcvd" },
      { Header: "quantity Rqst", accessor: "quantityrqst" },

    ],
    rows: [],
  };
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    var df = moment(date, format).tz("Asia/Kolkata").format(format);
    return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
  }
  const [tableDatas, setTableDatas] = useState(dataObj);
  const [projectValue, setProjectValue] = useState(null);
  const [filters, setFilters] = useState(getFilter("", ""));
  const [mmuvalue, setMmuvalue] = useState(null);
  const [typevalue, settypevalue] = useState(null);
  const [days, setDays] = useState(null);
  const [datas, setDatas] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [filename, setFilename] = useState("Patient_Repot.csv");
  const [engagementList, setEngagementList] = useState();


  

  
  async function getMmu(projectCode) {
    await axios
      .get(`/project-service/api/ProjectMMU/v1/get?projectcode=${projectCode}`)
      .then((response) => {
        console.log("149", response.data);
        setFilterMmu(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }

 
 
  function buildFilenameV2(type, code) {
    setFilename(type + "_" + code + "_" + moment().format('YYYY-MM-DD') + ".csv");
  }
  
  
  
  
  async function getData(object) {
    setLoading(true);
    await axios
   // .post(`http://194.163.159.164:9169/api/Reporting/v1/`, object)
    .post(`reporting-service/api/Reporting/v1/`, object)
      
       .then((response) => {

        console.log("132", response.data);
        dataObj.rows = response.data
        setHeaders([
          { label: "name", key: "name" },

          { label: "UOM", key: "uom" },
          { label: "current stock", key: "currentstock" },
          { label: "Item Type", key: "itemtype" },
          { label: "warehouse code", key: "whid" },
          { label: "mmu code", key: "mmucode" },
          { label: "quantity pending", key: "quantitypndg" },
          { label: "quantity rcvd", key: "quantityrcvd" },
          { label: "quantity Rqst", key: "quantityrqst" },
      ]);

      
         buildFilenameV2("medicalRequsition", "");
        setDatas(response.data);
        setTableDatas(dataObj)
        setLoading(false);

      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  function handleFilter() {
    console.log(mmuvalue);
   
   
    if (mmuvalue != null) {
      console.log(mmuObject)
      getData(mmuObject)
    }
    

  }

  const defaultPropsProject = {
    options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !filterMmu
      ? [{ mmu: { name: "Loading..." }, id: 0 }]
      : filterMmu,
    getOptionLabel: (option) => option.mmu?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsYear = {
    options: !yearlist ? [{ name: "Loading...", code: 0 }] : yearlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };
  function getYearList() {

    var yearlist = []
    for (let i = 2020; i <= currentyear; i++) {
      yearlist.push({ name: i.toString(), code: i })

    }
    console.log("540", yearlist)
    return yearlist;



  }
  
  const defaultPropsType = {
    options: [{ type: "Yearly", code: "x" }, { type: "Monthly", code: "y" },
    { type: "Datewise", code: "z" },
    ],
    getOptionLabel: (option) => option?.type,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };
  const mmuObject = {

    "page": "MED-REQ-REPORT-1",
    "queries": {
      "0": [{
        "field": "mmucode",
        "operation": "EQUAL",
        "values": [
          mmuvalue?.mmu?.code
        ]
      }, {
        "field": "createddate",
        "operation": "BETWEEN",
        "values": [
          startDate, endDate
        ]
      }



      ],
      "1": [{
        "field": "mmucode",
        "operation": "EQUAL",
        "values": [
          mmuvalue?.mmu?.code
        ]
      }, {
        "field": "createddate",
        "operation": "BETWEEN",
        "values": [
          startDate, endDate
        ]
      }]



    }


  }
  function dateFormat(date) {

    const d = new Date(date);

    return moment(d).format('YYYY-MM-DD');

  }

  function getStartEndDateByYear(year) {
    // var yearStart = moment().format('01/01/' + year);
    var yearStart = dateFormat('01/01/' + year)
    setStartDate(yearStart)
    console.log("549", yearStart)
    var yearEnd = dateFormat('12/31/' + year);
    console.log("END", yearEnd)
    setEndDate(yearEnd)


  }
  

  function getStartDateEndDatebymonth(month, year) {
    console.log("569", month, year)
   
    var firstDay = dateFormat(month + '/01/' + year)
    var lastDay = dateFormat(month + '/31/' + year)
   
    setStartDate(firstDay)
    setEndDate(lastDay)

  }
  function getStartDateEndDatebydatewise(date) {
    console.log(date)

    setStartDate(date)
    setEndDate(date)
    console.log("585", startDate)
  }
  const handleClick = () => {
    // 👇️ clear input value
    setCurrentMonth('');
    setCurrentYearvalue(null);
    setCurrentDate('')
  };

  const csvReport = {
    data: datas,
    headers: headers,
    filename: filename
  };

  useEffect(() => {


    setYearList(getYearList())

    console.log("138", filters);

  }, []);
  function changestatus(code) {
    console.log("557", code);
    setShowhide(code);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        {/* <Grid container spacing={3} my={1}>
                    {filters.map((x) => {
                        console.log(x)
                        return (<Grid item xs={12} sm={3}>{getComponent(x)}</Grid>)
                      
                    })}
                </Grid> */}

        <Grid container spacing={3} my={1}>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280 }}
              {...defaultPropsProject}
              value={projectValue}
              onChange={(event, newValue) => {
                console.log(newValue);
                setProjectValue(newValue)
                // getPatientDataByProject(newValue?.code, newValue?.name);
                getMmu(newValue?.code);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Project" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280, marginLeft: "24px" }}
              {...defaultPropsmmuCode}
              value={mmuvalue}
              onChange={(event, newValue) => {
                console.log(newValue);
                setMmuvalue(newValue);
                // getPatientDataByMmu(newValue?.mmu?.code, newValue?.mmu?.name);
                // getDataBymmu(newValue?.code);
                // setMmuvalue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select MMU" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280, marginLeft: "24px" }}
              {...defaultPropsType}
              value={typevalue}
              onChange={(event, newValue) => {
                console.log(newValue?.code);
                settypevalue(newValue);
                changestatus(newValue?.code);
               
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Data Type" />
              )}
            />
          </Grid>
          {showhide === "x" && (
            <Grid item xs={12} sm={2}>


              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // sx={{ width: 280, marginLeft: "24px" }}
                {...defaultPropsYear}
                onBlur={()=>handleClick()}
                value={currentyearvalue}
                onChange={(event, newValue) => {
                  
                  setCurrentYearvalue(newValue)
                  getStartEndDateByYear(newValue?.name)
                  // yearEndtDate(newValue?.name)
                  console.log("680", newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} label="year" />
                )}
              />

            </Grid>
          )}
          
          {showhide === "y" && (
            <Grid item xs={12} sm={2}>

              <MDInput type="month" label="Month" onBlur={()=>handleClick()} value={currentMonth} onChange={(e) => {
                setCurrentMonth(e.target.value)
                console.log(e.target.value);

                // monthEndDate
                var st = stringTokenizer(e.target.value, "-");
                setYear(st[0]);
                setMonth(st[1]);
                getStartDateEndDatebymonth(st[1], st[0])
                // console.log(st);
              }} />

            </Grid>
          )}
          {showhide === "z" && (
            <Grid item xs={12} sm={2}>

              <MDInput type="date" onBlur={()=>handleClick()} value={currentDate}
                onChange={(e) => {
                  setCurrentDate(e.target.value)
                  getStartDateEndDatebydatewise(e.target.value)
                  console.log(e.target.value);
                }} />

            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleFilter}
            >
              Filter
            </MDButton>
          </Grid>
        </Grid>

        
        <MDBox mt={3}>
          <Grid container spacing={3}>


            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>
                
                <MDBox m={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"

                  // onClick={handleFilter}
                  >
                    <CSVLink {...csvReport} style={{ color: "white" }}>Export to CSV</CSVLink>
                  </MDButton>
                </MDBox>
                <DataTable table={tableDatas} canSearch={true} />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}


export default MedicalRequisition;