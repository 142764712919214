import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/Project/new Project/schemas/validations";
import form from "layouts/pages/Project/new Project/schemas/form";
import initialValues from "layouts/pages/Project/new Project/schemas/initialValues";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProjectInfo from "layouts/pages/Project/new Project/componets/ProjectInfo";
import { MoonLoader } from "react-spinners";

function getSteps() {
  return ["Project Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProjectInfo formData={formData} />;

    default:
      return null;
  }
}

function Createproject() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const [loading,setLoading]=useState(false)
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
 setLoading(true)
    const projectObj = {
      name: values.name.trim(),
      desc: values.description.trim(),
      parentproject: values.projecthirarichalcode,
      projecthirarichalcode: values.projecthirarichalcode,
      pinCode: values.pincode,
      address: "",
      approvedby: "",
      approveddate: "",
      classname: "",
      code: "",
      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,
      createddate: null,
      warehouse: values.warehouse,
      documentofproof: "",
      id: null,
      latlong: "",
      location: null,
      mmulist: null,
      modifiedby: "",
      modifieddate: null,

      projectteam: null,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "synced",
      tempid: "",
    };
    await axios
      .post(
        "project-service/api/Project/v1/create",
        projectObj
      )

      .then((response) => {
      
        setLoading(false)
        navigate("/page/component/project-list");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-2}>

                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Project
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                         <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {loading ? <MoonLoader color="#f2fefa" size={16} /> : 'submit'}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Createproject;
