import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";

function MmuApproval() {
  const comp = "SM";
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function updateMMU(request, recstatus) {
    delete request.createddateV2;
    delete request.action;
    request.recstatus = recstatus;
    axios
      .put(
        `mmucreation-service/api/MMUCreation/v1/update`,
        request
      )
      .then((response) => {
        response?.data?.data?.dtoList;
        getData();
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },
        { Header: "code", accessor: "code" },
        { Header: "description", accessor: "description" },
        { Header: "createddate", accessor: "createddateV2" },
        { Header: "status", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get?recstatus=OPEN`
      )
      .then((response) => {
        response?.data?.data?.dtoList.map((item) => {
          item.action = (
            <span>
              <MDButton
                circular="true"
                color="success"
                size="small"
                style={{ marginRight: "5px" }}
                variant="gradient"
                onClick={() => updateMMU(item, "APPROVED")}
              >
                Approved
              </MDButton>
              <MDButton
                size="small"
                variant="gradient"
                circular="true"
                color="error"
                onClick={() => updateMMU(item, "REJECTED")}
              >
                Reject
              </MDButton>
            </span>
          );
          item.createddateV2 = new Date(item.createddate).toDateString();
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    MMU Approval
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/users/mmu-Creation"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default MmuApproval;
