import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import RegistrationInfo from "layouts/pages/nurseregistration/new-nurseregistration/components/RegistrationInfo";
import AddressInfo from "layouts/pages/nurseregistration/new-nurseregistration/components/AddressInfo";
// import Socials from "layouts/pages/users/new-user/components/Socials";
// import Profile from "layouts/pages/users/new-user/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/nurseregistration/new-nurseregistration/schemas/validations";
import form from "layouts/pages/nurseregistration/new-nurseregistration/schemas/form";
import initialValues from "layouts/pages/nurseregistration/new-nurseregistration/schemas/initialValues";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";


function getSteps() {
  return ["Registration Info", "Additional Information"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <RegistrationInfo formData={formData} />;
    case 1:
      return <AddressInfo formData={formData} />;

    default:
      return null;
  }
}

function CreateNewbeneficiary() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const [loading,setLoading]=useState(false)
  const currentValidation = validations[activeStep];
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));
  
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  

  const submitForm = async (values, actions) => {
    setLoading(true)
    const patientObject = {
      patienttempid: "",
      name: values.name,
      mobileno: values.mobileno,
      age: values.age,
      bpl: "",
      plw: "",
      disability: values.disability,
      documenttype: values.comdoc,
      documentno: values.docno,
      addressid: "",
      emergencycontact:  values.mobileno2,
      emergencyname:values.name2,
      visitid: "",
      village: values.village,
      address: values.address,
      gender: values.gender,
      emergencyaddress: values.address2,
      education: values.education,
      typeofrationcard:values.rationcard,
      pandlwomen: "",
      createdby: loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,
      recstatus: "OPEN",
      syncstatus: "NOT_SYNCED",
      status: "ACTIVE"
    };
    await axios
      .post(
        "patient-service/api/patient/v1/create",
        patientObject
      )

      .then((response) => {
        setLoading(false)
        navigate("/page/component/search-patient");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)

      });

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? <> {loading ? <MoonLoader color="#f2fefa" size={16} /> : 'submit'}</>  : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}




export default CreateNewbeneficiary