import React, { useState } from "react";
import "./Style.css";

import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import FileUpload from "react-material-file-upload";
// @mui material components
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import axios from "axios";
import MDAlert from "components/MDAlert";
import { Audio } from "react-loader-spinner";
import { ClipLoader, MoonLoader } from "react-spinners";
import { Autocomplete, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import MDBadgeDot from "components/MDBadgeDot";
const UploadSchedule = () => {
  const [files, setFiles] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [tableDatas, setTableDatas] = useState();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [defaultMMU, setDefaultMMU] = useState();
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );
  const [projectDetail, setprojectDetail] = useState(
    JSON.parse(localStorage.getItem("project-Detail"))
  );
  const [mmucode, setMmucode] = useState("");
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth"))
  );
  const [uploadBy, setUploadBy] = useState(
    loginUser.firstname + " " + loginUser.lastname
  );

  const [pincodeValue, setPincodeValue] = useState(null);

  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + " (" + option.vehicle?.name + ")":option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  function myTimeoutForSuccess(msg) {
    setSuccessMsg(msg);
    setSuccessAlert(true);
    setTimeout(enablingSuccess, 5000);
  }
  function enablingSuccess() {
    setSuccessAlert(false);
  }

  const dataObj = {
    columns: [
      // { Header: "", accessor: "badge" },
      { Header: "pincode", accessor: "pincodeDropDown", width: "50%" },
      { Header: "title", accessor: "title" },
      { Header: "mmucode", accessor: "mmucode" },
      { Header: "start date", accessor: "startdateFormat" },
      { Header: "end date", accessor: "enddateFormat" },
      { Header: "state shortname", accessor: "stateshortname" },
      { Header: "district", accessor: "district" },
      { Header: "sub-district shortname", accessor: "subdistrictshortname" },
      { Header: "pincode", accessor: "pincode" },
      { Header: "village name", accessor: "villagename" },
    ],
    rows: [],
  };

  function onfileChange(e) {
    setFiles(e);
    setSubmitFlag(true);
  }

  function getPincodeByVillage(array, string) {
    
    
    return array.filter((o) =>
    o.pincode != null ? Object.keys(o.pincode.village).some((k) =>
        o.pincode.village[k].toLowerCase().includes(string.toLowerCase())
      ) : Object.keys(o).some((k) => {})
    );
  }
  function filterByValue(array, string) {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        o[k].toLowerCase().includes(string.toLowerCase())
      )
    );
  }

  function badgeColorChange(object, color) {
    console.log("106", object);
    console.log("107", tableDatas);
    console.log("107", dataObj);
    dataObj.rows[object.sno].badgeColor = color;
    setLoading(true);

    setTableDatas(dataObj);
    setLoading(false);
    // for (let i = 0; i <= dataObj.rows.length; i++) {
    //   if (dataObj.rows[i].sno === object.sno) {
    //     dataObj.rows[i].badgeColor = color;
    //   }
    // }
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  function dateFormater(date) {
    return new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString();

  }
  function onFileUpload(mmucode, uploadBy) {
    setLoading(true)
    setSubmitLoading(true);
    setErrorAlert(false);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", files[0]);
    formData.append("uploadedby", uploadBy);
    formData.append("resourcecode", "DPF");
    formData.append("mmucode", mmucode);
    formData.append("uploadeddate", new Date());

    axios
      .post(
        "bulk-import/api/bulk_import/v1/upload",
        formData
      )

      .then((response) => {
        console.log(response);
        setResponse(response.data);
        if (response.data.status === "NOT_UPLOADED") {
          setErrorMsg(response.data.errormsg);
          setErrorAlert(true);
        } else {
          myTimeoutForSuccess(
            "File uploaded Successfully !!"
          );
          var csvResList = response.data.csvdatalist;
          console.log("csv", csvResList);
          response.data.csvdatalist.map((res, index) => {
            res.badgeColor = "success";
            res.sno = index;
            // for (let i = 0; i <= csvResList.length; i++) {
              console.log(res.startdate);
              console.log(res.enddate);
            res.startdateFormat = res.startdate
            res.enddateFormat = res.enddate
            // res.startdateFormat = new Date(res.startdate).toDateString();
            // res.enddateFormat = new Date(res.enddate).toDateString();
            console.log("138", projectDetail.pincodelist);
            var pinCodeList = [];
            var pincodeValue = null;
            var defaultPincode = null;

            pinCodeList = getPincodeByVillage(
              projectDetail.pincodelist,
              res.villagename
            );

            console.log("140", pinCodeList);

            if (pinCodeList.length === 0) {
              pinCodeList = projectDetail.pincodelist;
              defaultPincode = null;
              res.badgeColor = "error";
            } else {
              defaultPincode = pinCodeList[0];
            }
            res.pincodeValue = defaultPincode;
            console.log("def", defaultPincode);
            const defaultPropPincode = {
              options: !pinCodeList
                ? [
                    {
                      id: 0,
                      pincode: {
                        state: {
                          shortname: "--",
                          name: "Loading...",
                        },
                        district: {
                          shortname: "",
                          name: "",
                        },
                        village: {
                          shortname: "",
                          name: "",
                        },
                        subdistrict: {
                          shortname: "",
                          name: "",
                        },
                        pincode: "",
                      },
                    },
                  ]
                : pinCodeList,

              getOptionLabel: (option) =>
                option.pincode?.village?.name +
                " " +
                option.pincode?.subdistrict?.name +
                " (" +
                option.pincode?.pincode +
                ") " +
                option.pincode?.district?.name +
                " " +
                option.pincode?.state?.name,
              isOptionEqualToValue: (option, value) => option.id === value.id,
            };
            var dropName = 1;
            console.log("211 dropName", res.villagename);
            res.badge = (
              <MDBadgeDot badgeContent="" color={res.badgeColor} size="lg" />
            );
            res.pincodeDropDown = (
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <MDBadgeDot
                    badgeContent=""
                    color={res.badgeColor}
                    size="lg"
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Autocomplete
                    {...defaultPropPincode}
                    defaultValue={defaultPincode}
                    style={{ width: "250px" }}
                    value={res[pincodeValue]}
                    name={res.villagename}
                    onChange={(event, newValue) => {
                      // setPincodeValue(newValue);
                      setLoading(true);
                      res.pincodeValue = newValue;

                      console.log("233", tableDatas);
                      // tableDatas.rows[res.sno].badgeColor = "success";
                      // setTableDatas(dataObj);
                      console.log("224", res);
                      console.log("224", tableDatas);
                      badgeColorChange(res, "success");

                      res.badgeColor = "success";
                      // res[badgeColor] = "success";
                      console.log(newValue);
                      // setLoading(false);
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label="Select Location"
                      />
                    )}
                  />{" "}
                </Grid>
              </Grid>
            );
            console.log("res", res);

            dataObj.rows.push(res);
            dropName = dropName + 1;
          });
        }
        console.log("238", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
        setSubmitLoading(false);
      })
      .catch((error) => {
        setErrorMsg("Something went wrong !!");
        setErrorAlert(true);
        setLoading(true);
        setSubmitLoading(false);
        console.log(error);
      });
  }

  async function onFinalUpload(request) {
    for(let i=0; i<request.csvdatalist.length; i++){
      delete request.csvdatalist[i].badge;
      delete request.csvdatalist[i].pincodeDropDown;
    }
    console.log(request);
    setFinalSubmitLoading(true);
    if (
      window.confirm("Are you sure that you want to upload final record !!")
    ) {
      request.status = "UPLOADED";
      await axios
        .put(
          `bulk-import/api/bulk_import/v1/update`,
          // `/api/bulk_import/v1/updateV2/?id${request.id}`
          request
        )
        .then((response) => {
          console.log(response);
          myTimeoutForSuccess("Data uploaded Successfully !!");
          setFinalSubmitLoading(false);
          setLoading(true);
        })
        .catch((error) => {
          setFinalSubmitLoading(false);
          setErrorMsg("Something went wrong !!");
          setErrorAlert(true);
          console.log(error);
        });
    }
    setFinalSubmitLoading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {errorAlert && (
        <MDAlert color="error" dismissible>
          {errorMsg}
        </MDAlert>
      )}
      {successAlert && <MDAlert color="success">{successMsg}</MDAlert>}

      <MDBox pt={3} mt={0}>
        <MDBox>
          <Card>
            <MDBox
              className="Upload"
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                marginTop: "10px",
              }}
            >
              <h3>Schedule File Upload</h3>
              <h5>Please Select file upload component from Your System</h5>
              <Autocomplete
                defaultValue={defaultMMU}
                disablePortal
                id="combo-box-demo"
                sx={{ width: 250 }}
                style={{ margin: "7px" }}
                {...defaultPropsMMU}
                onChange={(event, newValue) => {
                  setMmucode(newValue?.code);
                }}
                renderInput={(params) => <TextField {...params} label="MMU" />}
              />

              <FileUpload
                buttonText={<span style={{ color: "white" }}>Upload</span>}
                value={files}
                onChange={onfileChange}
              />
              <MDButton
                disabled={!submitFlag}
                style={{ margin: "10px" }}
                type="submit"
                onClick={() => onFileUpload(mmucode, uploadBy)}
                variant="gradient"
                color="dark"
              >
                {submitLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Submit"
                )}
              </MDButton>
            </MDBox>

            {!loading && (
              <MDBox mt={8}>
                <Grid container spacing={3}>
                  <Grid
                    className="datatable"
                    item
                    xs={12}
                    sm={12}
                    mx={5}
                    style={{
                      borderRadius: "10px",
                      marginTop: "25px",
                      marginRight: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    <MDTypography
                      variant="h5"
                      fontWeight="medium"
                      className="Upload"
                    >
                      Uploaded Schedule
                    </MDTypography>
                    <DataTable
                      showTotalEntries={false}
                      entriesPerPage={false}
                      table={tableDatas}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            )}
            {!loading && (
              <MDButton
                disabled={!submitFlag}
                style={{ margin: "10px" }}
                type="submit"
                onClick={() => onFinalUpload(response)}
                variant="gradient"
                color="dark"
              >
                {finalSubmitLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Final Upload"
                )}
              </MDButton>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default UploadSchedule;
