// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
// NewUser page components
import FormField from "layouts/pages/Lab/new-lab/componets/FormField";
import { Autocomplete, Link } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function ProjectteamInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [projectValue, setProjectValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [designationValue, setDesignationValue] = useState(null);
  const [profileValue, setProfileValue] = useState(null);

  const [projectList, setProjectList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const [profileList, setProfileList] = useState("");

  const { formField, values, errors, touched } = formData;

  const defaultPropsprojectselection = {
    options: !projectList ? [{ name: "Loading...", id: 0 }] : projectList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentdepartment = {
    options: !departmentList ? [{ name: "Loading...", id: 0 }] : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentdesignation = {
    options: !designationList
      ? [{ name: "Loading...", id: 0 }]
      : designationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentprofile = {
    options: !profileList
      ? [{ firstname: "Loading...", lastname: "", id: 0 }]
      : profileList,
    getOptionLabel: (option) => option.firstname + " " + (option.lastname?option.lastname:""),
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const dataObj = {
    columns: [
      { Header: "project", accessor: "projectname" },
      {
        Header: "department",
        accessor: "projectteams.profileentity.departmentname",
      },
      {
        Header: "designation",
        accessor: "projectteams.profileentity.designationname",
      },
      { Header: "Name", accessor: "projectteams.profileentity.fullname" },
      { Header: "Role", accessor: "projectteams.profileentity.rolename" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  const removeItem = function (id) {
    const objWithIdIndex = tableData.rows.findIndex(
      (obj) => obj.projectteams?.profileentity?.id === id
    );
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.projectTeamList = tableData.rows;
  };

  const { project, department, profile, designation } = formField;
  var {
    address: addressV,
    createproject: createprojectV,
    parenthierachical: parenthierachicalV,
  } = values;

  async function getDepartmentData() {
    await axios
      .get(
        "department-service-mongo/api/department/v2/get"
      )
      .then((response) => {
        setDepartmentList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDesignationData(deptCode) {
    await axios
      .get(
        `designation-service-mongo/api/designation/v2/get?deptcode=${deptCode}`
      )
      .then((response) => {
        setDesignationList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getProfileDataByDeptCode(departmentcode) {
    await axios
      .get(
        `profile-service-mongo/api/ProfileEntity/v2/get?departmentcode=${departmentcode}`
      )
      .then((response) => {
        setProfileList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getProfileDataByDsgCode(designationcode) {
    await axios
      .get(
        `profile-service-mongo/api/ProfileEntity/v2/get?designationcode=${designationcode}`
      )
      .then((response) => {
        setProfileList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getData() {
    //setting project===========

    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setProjectList(response?.data?.data?.dtoList);
        getDepartmentData();
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const addData = () => {
    console.log("165", projectValue);
    profileValue.fullname =
      profileValue.firstname + " " + profileValue.lastname;
    const projectTeam = {
      projectcode: projectValue?.code,
      projectname: projectValue?.name,
      projectteams: { profileentity: profileValue },
    };
    projectTeam.action = (
      <span>
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() =>
              removeItem(projectTeam?.projectteams?.profileentity?.id)
            }
          />
        </Link>
      </span>
    );
    console.log("181", projectTeam);
    let filter = tableData.rows.filter(
      (a) =>
        a.projectteams.profileentity.id ==
        projectTeam.projectteams.profileentity.id
    );

    if (filter.length < 1) {
      tableData.rows.push(projectTeam);

      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.projectTeamList = tableData.rows;
      console.log("value", tableData.rows);
    }
    setDepartmentValue(null)
    setDesignationValue(null)
    setProfileValue(null)
    setProjectValue(null)
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Project</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsprojectselection}
                value={projectValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setProjectValue(newValue);
                  // values.project = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={project.label}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentdepartment}
                value={departmentValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDepartmentValue(newValue);
                  getDesignationData(newValue?.code);
                  getProfileDataByDeptCode(newValue?.code);
                  // values.department =newValue?.designationname;
                  // values.department = newValue?.code;
                  // values.parenthierarchical=newValue?.hierarchicalcode;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={department.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentdesignation}
                value={designationValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDesignationValue(newValue);
                  getProfileDataByDsgCode(newValue?.code);
                  // values.designation =newValue?.designationname;
                  // console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.designation && touched.designation}
                    success={designation.length > 0 && !errors.designation}
                    variant="standard"
                    label={designation.label}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                {...defaultPropsparentprofile}
                value={profileValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setProfileValue(newValue);

                  values.profile = newValue?.firstname;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={profile.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "25px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={tableData}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectteamInfo
ProjectteamInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectteamInfo;
