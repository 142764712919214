import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

const DesignationList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [itemtList, setItemList] = useState("");
  const [err, setErr] = useState("");
  const [selectdes, setSelectDes] = useState("");
  const [selectdep, setSelectDep] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDsignationList] = useState("");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("mmu-auth")));
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsDepartment = {
    options: !departmentList
      ? [{ name: "Loading...", code: "0" }]
      : departmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };

  const defaultPropDesignation = {
    options: !designationList
      ? [{ name: "Loading...", code: "0" }]
      : designationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };
  async function getDepartmentData() {
    await axios
      .get(
        `department-service-mongo/api/department/v2/get`
      )
      .then((response) => {
        setDepartmentList(response?.data?.data?.dtoList);
        getDesignationData(response?.data?.data?.dtoList);
        console.log("82", response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDesignationData(deptlist) {
    await axios
      .get(
        `designation-service-mongo/api/designation/v2/get`
      )
      .then((response) => {
        setDsignationList(response?.data?.data?.dtoList);
        getData(deptlist, response?.data?.data?.dtoList);
        console.log("96", response?.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  async function onUpdatedata(object, deptlist, deslist) {
    for (let i = 0; i < deptlist.length; i++) {
      if (deptlist[i].code === object.deptcode) {
        console.log("900", deptlist[i]);
        setSelectDep(deptlist[i]);
      }
    }

    console.log("112", deslist);

    for (let i = 0; i < deslist.length; i++) {
      if (deslist[i].hierarchicalcode === object.parenthierarchicalcode ) {
        console.log("122", deslist[i]);
        setSelectDes(deslist[i]);
      }
    }
    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setOpen(true);
  }

  const handleClose = () => {
    
    setOpen(false);
  };

  async function onDelete(item) {
    item.modifiedby = user?.firstname + " " + user?.lastname + " (" + user?.username + ")";
    item.modifieddate = new Date();
    if (window.confirm("Are you sure that you want to delete that user record"))
      await axios
        .put(
          "designation-service-mongo/api/designation/v2/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }

  async function updateData() {
    //  item.deptcode=selectdep;
    item.name = Updatename;
    item.description = description;
    // item.deptcode=selectdep;

    item.modifiedby = user?.firstname + " " + user?.lastname + " (" + user?.username + ")";
    item.modifieddate = new Date();
    delete item.action;
    await axios
      .put(
        "designation-service-mongo/api/designation/v2/update",
        item
      )
      .then((response) => {
        setOpen(false);

        getDepartmentData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(deptlist, deslist) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name", width: "25%" },
        { Header: "description", accessor: "description" },
        { Header: "Department code", accessor: "deptcode" },
        { Header: "Designation code", accessor: "code" },
        {
          Header: "hierarchicalCode",
          accessor: "hierarchicalcode",
          width: "15%",
        },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `designation-service-mongo/api/designation/v2/get`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item, deptlist, deslist);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getDepartmentData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Designation List
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/profile/create-designation"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Designation List</DialogTitle>
        <DialogContent style={{ width: 550, height: 250 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropsDepartment}
              value={selectdep}
              onChange={(event, newValue) => {
                // handleChange(event);
                // item.type = newValue?.code;
                item.deptcode = newValue?.code;
                setSelectDep(newValue);
                // values.department = newValue?.hierarchicalcode;
                console.log("281", selectdes);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="Department" />
              )}
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultPropDesignation}
              value={selectdes}
              onChange={(event, newValue) => {
                // handleChange(event);
                // item.parentdepcode=newValue?.code;
           item.parenthierarchicalcode=newValue?.hierarchicalcode;
                item.hierarchicalcode = newValue?.hierarchicalcode+"-"+item.code;

                setSelectDes(newValue);

                console.log("281", selectdes);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label=" Parent Desgionation"
                />
              )}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};
export default DesignationList;
