import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newmmucreation page components
import MmucreationInfo from "layouts/pages/mmucreation/new mmucreation/componets/mmucreationInfo";
// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/mmucreation/new mmucreation/schemas/validations";
import form from "layouts/pages/mmucreation/new mmucreation/schemas/form";
import initialValues from "layouts/pages/mmucreation/new mmucreation/schemas/initialValues";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";

function getSteps() {
  return ["Mmucreation Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <MmucreationInfo formData={formData} />;
    default:
      return null;
  }
}

function MmuCreation() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0);
  const [equipmentList, setEquipmentList] = useState([]);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [loading,setLoading]=useState(false)
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

  function creatingEquipList(values) {

    if(typeof  values.EquipmentList!=="undefined"){
       for (let i = 0; i < values.EquipmentList.length; i++) {
      let equipmentModel = {
        assetno: "",
        item: "",
        quantity: "",
      };
      equipmentModel.quantity = values.EquipmentList[i].inputQty;
      equipmentModel.assetno = values.EquipmentList[i].inputSerialNo;
      delete values.EquipmentList[i].action;
      delete values.EquipmentList[i].inputQty;
      delete values.EquipmentList[i].inputSerialNo;
      equipmentModel.item = values.EquipmentList[i];
      equipmentList.push(equipmentModel);
    }
  };
    // values.EquipmentList.map((obj) => {

    //   obj.quantity = obj.inputQty;
    //   obj.assetno = obj.inputSerialNo;
    //   obj.item = obj;

    //   delete obj.action;
    //   delete obj.inputQty;
    //   delete obj.inputSerialNo;
    //   equipmentList.push(equipmentModel);
    // });

  }
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  async function getmmu() {
   

    await axios
    .get(
      `mmucreation-service/api/MMUCreation/v1/get`
    )
    .then((response) => {


      localStorage.setItem(
        "project-Detail",
       JSON.stringify({mmulist:response?.data?.data?.dtoList})
      );
      dispatch({
        type: "UPDATE_PROFILE_DETAILS",
        payload: response?.data?.data?.dto,
      });
    })
    .catch((e) => {
      console.log("error", e);
    });
   
   
  }
  const submitForm = async (values, actions) => {
    setLoading(true)
    creatingEquipList(values);
    const getData = {
      approvedby: "",
      approveddate: "",
      classname: "",
      code: "",
      createdby: "",
      createddate: "",
      description: values.MMUDescription,
      documentofproof: "",
      id: null,
      latlong: "",
      location: "",
      modifiedby: "",
      modifieddate: "",
      name: values.MMUName,
      recstatus: "OPEN",
      status: "Active",
      syncstatus: "SYNCED",
      vehicle: values.VehicleAssign,
      raspberry: values.RaspberryAssign,
      warehouse: values.mapware,
      equipmentlist: equipmentList,
    };
 
    await axios
      .post(
        "mmucreation-service/api/MMUCreation/v1/create",
        getData
      )

      .then((response) => {
        setLoading(false)
        navigate("/pages/users/mmu-list");
        getmmu()
     
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
        swal("Oops!", 
            "Sonething want wrong!.",
          "warning"
        );
      });
   



    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          MMU Creation Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                    
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? <> {loading ? <MoonLoader color="#f2fefa" size={16} /> : 'Submit'} </> : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default MmuCreation;
