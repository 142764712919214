import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";


import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";







const IndentRequest = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
 
 
  
  const [getResponse, setResponse] = useState([]);
 
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
 
 

 
 


 


  async function updateTxnMedReq(object) {
    await axios
      .put(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/updateRequisition`,object
      )
      .then((response) => {
        // setFrequencyList(response?.data?.data?.dtoList)
        getIndentAggregationData(mmucode);
      
      })
      .catch((e) => {
        console.log("error", e);
      });
  }




  async function getIndentAggregationData(mmucode) {
    setLoading(true);

    await axios
      .get(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?mmucode=${mmucode}&recstatus=OPEN`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {


          const dataObj = {
            columns: [
              { Header: "medicine description", accessor: "name" },
              { Header: "uom", accessor: "uom" },
              { Header: "quantity", accessor: "quantityrqst" },


            ],
            rows: item.medrequisitiongendtoList,
          };


          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  

  function handleApprove(object) {


    object.recstatus = "APPROVED"
    updateTxnMedReq(object);

    console.log("317", object);
    // updateDosesList(object);
  }
  function handleChange(object) {


    object.recstatus = "CHANGED"
    updateTxnMedReq(object);
   

    console.log("325", object);
    // updateDosesList(object);
  }
  function handleReject(object) {


    object.recstatus = "REJECT"
    updateTxnMedReq(object);

    console.log("334", object);
    // updateDosesList(object);
  }
 
  useEffect(() => {
    getIndentAggregationData(mmucode);
    
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Indent Request
                  </MDTypography>
                </span>




              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Request ID :
                          </text>
                          {" " + values.medrequisitiongentxndto?.mrnumber}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Submitted by :
                          </text>
                          {" " + values.medrequisitiongentxndto?.createdby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Status :</text>
                          {" " + values.medrequisitiongentxndto?.recstatus}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Request Date :</text>

                          {" " + values.medrequisitiongentxndto?.createddate}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Comment:</text>
                          {values.medrequisitiongentxndto?.comment === null ? " NA" : " " + values.medrequisitiongentxndto?.comment}
                          {/* {" " + values.medrequisitiongentxndto?.comment} */}
                        </div>


                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                    <MDBox style={{
                      textAlign: "center",
                      marginTop: "5px"

                    }}>
                      <MDButton
                        circular="true"
                        color="success"
                        size="small"
                        style={{

                          marginRight: "15px"
                        }}
                        onClick={() => handleApprove(values.medrequisitiongentxndto)}
                        variant="gradient"
                      >
                        Approve
                      </MDButton>
                      <MDButton
                        size="small"
                        variant="gradient"
                        circular="true"
                        color="success"
                        style={{

                          marginRight: "15px"
                        }}

                        onClick={() => handleChange(values.medrequisitiongentxndto)}

                        
                      >
                        change
                      </MDButton>
                      <MDButton
                        size="small"
                        variant="gradient"
                        circular="true"
                        color="success"

                        onClick={() => handleReject(values.medrequisitiongentxndto)}
                      >
                        Reject
                      </MDButton>

                    </MDBox>
                    <MDBox style={{
                      textAlign: "center",
                      marginTop: "12px"

                    }}>
                      <MDInput
                        type="text"
                        label="Comment by doctor"
                        name={values.medrequisitiongentxndto.id}
                        style={{ width: "320px" }}
                      value={values.medrequisitiongentxndto.comment}
                        onChange={(e) => { values.medrequisitiongentxndto.comment = e.target.value }}
                      />
                    </MDBox>
                  </AccordionDetails>
                </Accordion>

              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
     

      <Footer />
    </DashboardLayout>
  );
};


export default IndentRequest;