import { useState, useEffect } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/warehouse/new warehouse/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

import axios from "axios";

import { BarLoader } from "react-spinners";

function WareHouseInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [value, setValue] = React.useState(null);
  const [typeList, setTypelist] = useState("");

  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);

  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");

  const { formField, values, errors, touched } = formData;
  const defaultPropstype = {
    options: !typeList ? [{ name: "Loading...", id: 0 }] : typeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentpincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    name,
    type,
    sapcode,
    firstline,
    state,
    district,
    subDistrict,
    pincode,
  } = formField;
  var {
    name: nameV,

    sapcode: sapcodeV,
    firstline: firstlineV,
  } = values;
  async function getDistrictData(stateCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getData() {
    await axios
      .get(
        `typemaster-service/api/TypeMaster/v1/get?category=WAREHOUSE`
      )
      .then((response) => {
        setTypelist(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
    //state
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Warehouse Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={sapcode.type}
                label={sapcode.label}
                name={sapcode.name}
                value={sapcodeV}
                placeholder={sapcode.placeholder}
                error={errors.sapcode && touched.sapcode}
                success={sapcodeV.length > 0 && !errors.sapcode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropstype}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);

                  values.type = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.type && touched.type}
                    success={type.length > 0 && !errors.type}
                    variant="standard"
                    label={type.label}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  getDistrictData(newValue?.state?.code);
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label={state.label} />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={(event, newValue) => {
                  setdistrictValue(newValue);
                  getSubDistrictData(
                    newValue?.state?.code,
                    newValue?.district?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={district.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  setSubDistrictValue(newValue);
                  getVillageData(
                    newValue?.state?.code,
                    newValue?.district?.code,
                    newValue?.subdistrict?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={subDistrict.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentpincode}
                value={pincodeValue}
                onChange={(event, newValue) => {
                  setPincodeValue(newValue);
                  values.pincode = newValue;
                  console.log("village", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={pincode.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
      <FormField
          type={firstline.type}
          label={firstline.label}
          name={firstline.name}
          value={firstlineV}
          placeholder={firstline.placeholder}
          error={errors.firstline && touched.firstline}
          success={firstlineV.length > 0 && !errors.firstline}
        />
      </Grid> 
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for WareHouseInfo
WareHouseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default WareHouseInfo;
