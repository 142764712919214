import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// @mui material components
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import MDButton from "components/MDButton";

const initialValues = {
  name: "",
  description: "",
  projecthirarichalcode: "",
  warehouse: "",
  address: "",
  pincode: ""


}

const Projectcomponet = (props) => {
  const { tittle, children, childToProject, openpopup, setopenpopup } = props

  const [warehouseList, setWarehouseList] = useState("");
  const [warehouseValue, setWarehouseValue] = useState(null)
  const [parenthierachicalValue, setparenthierachicalValue] = useState(null);
  const [parenthierachicalList, setparenthierachicalList] = useState("");
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setdistrictValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);

  const [stateList, setStateList] = useState("");
  const [districtList, setDistrictList] = useState("");
  const [subDistrictList, setSubDistrictList] = useState("");
  const [pincodeList, setPincodeList] = useState("");
  function autocompleteValuenull(){
    setWarehouseValue(null);
    setStateValue(null);
    setdistrictValue(null);
    setPincodeValue(null);
    setSubDistrictValue(null);
    setparenthierachicalValue(null);
  }
  async function createproject(projectObj) {
    axios
      .post(
        "project-service/api/Project/v1/create",
        projectObj
      )

      .then((response) => {
        console.log("gfgfgf", response);

        setopenpopup(false)
        autocompleteValuenull()
        if (response) {
          console.log("38", response.data.data.dto)
          childToProject(response.data.data.dto)

        }




      })
      .catch((error) => {
        console.log(error);


      });

  }


  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values)
      const projectObj = {
        name: values.name.trim(),
        desc: values.description.trim(),
        parentproject: values.projecthirarichalcode,
        projecthirarichalcode: values.projecthirarichalcode,
        pinCode: values.pincode,
        address: "",
        approvedby: "",
        approveddate: "",
        classname: "",
        code: "",
        createdby:
          JSON.parse(localStorage.getItem("mmu-auth")).firstname +
          " " +
          JSON.parse(localStorage.getItem("mmu-auth")).lastname,
        createddate: null,
        warehouse: values.warehouse,
        documentofproof: "",
        id: null,
        latlong: "",
        location: null,
        mmulist: null,
        modifiedby: "",
        modifieddate: null,

        projectteam: null,
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "synced",
        tempid: "",
      };

      createproject(projectObj)
      swal("Alert!", "...your project has been sucessfully create!", "success")
        .then((value) => {
          // window.location.reload(true)

          action.resetForm();

        });

    }
  })


  const defaultPropsState = {
    options: !stateList
      ? [{ id: 0, state: { name: "Loading...", shortname: "" } }]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: !districtList
      ? [{ id: 0, district: { name: "Loading...", shortname: "" } }]
      : districtList,
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: !subDistrictList
      ? [{ id: 0, subdistrict: { name: "Loading...", shortname: "" } }]
      : subDistrictList,
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparentpincode = {
    options: !pincodeList
      ? [{ id: 0, village: { name: "Loading..." }, pincode: "" }]
      : pincodeList,
    getOptionLabel: (option) =>
      option.village?.name + " (" + option.pincode + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsparenthierachical = {
    options: !parenthierachicalList
      ? [{ name: "Loading...", id: 0 }]
      : parenthierachicalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropswarehouse = {
    options: !warehouseList
      ? [{ name: "Loading...", id: 0 }]
      : warehouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  }

  async function getData() {
    // hierachical code=======================
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setparenthierachicalList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
    // warehouse list------------------
    await axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
      )
      .then((response) => {
        setWarehouseList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });

  }
  async function getData() {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=STATE`
      )
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `project-service/api/Project/v1/get`
      )
      .then((response) => {
        setparenthierachicalList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
    // warehouse list------------------
    await axios
      .get(
        `wareHouseMaster-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
      )
      .then((response) => {
        setWarehouseList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });

  }

  async function getDistrictData(stateCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
      )
      .then((response) => {
        setDistrictList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getSubDistrictData(stateCode, districtCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=SUB-DISTRICT&state.code=${stateCode}&district.code=${districtCode}`
      )
      .then((response) => {
        setSubDistrictList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVillageData(stateCode, districtCode, subDistrictCode) {
    //setting pincode===========
    await axios
      .get(
        `pincode-service/api/pincode/v1/get?type=VILLAGE&state.code=${stateCode}&district.code=${districtCode}&subdistrict.code=${subDistrictCode}`
      )
      .then((response) => {
        setPincodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setopenpopup(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={openpopup} onClose={handleClose} >
      <MDBox mx={2} mt={2}>

        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-2}
          p={2}
          mb={-2}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="small"
            color="white"
            mt={1}
          >
            Project
          </MDTypography>
        </MDBox>
      </MDBox>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ width: 550, height: 370 }}>

          <Grid container spacing={3} mt={1} >
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                autocomplete="off"
                name="name"
                label="Name"
                id="name"
                variant="standard"
                fullWidth
                values={values.name}
                onChange={handleChange}
                onBlur={handleBlur}

              />
              {/* <span style={{ color: "red" }}>{err}</span> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                autocomplete="off"
                name="description"
                label="Description"
                id="description"
                variant="standard"
                fullWidth
                values={values.description}
                onChange={handleChange}
                onBlur={handleBlur}

              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={1} >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparenthierachical}
                value={parenthierachicalValue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  console.log("134", newValue)
                  setparenthierachicalValue(newValue);
                  values.projecthirarichalcode =
                    newValue?.projecthirarichalcode;
                  // setparenthierachicalValue(newValue?.projecthirarichalcode);



                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type="text"
                    autocomplete="off"
                    name="projecthirarichalcode"
                    label="Projecthirarichalcode"
                    id="projecthirarichalcode"
                    // values={values.projecthirarichalcode}
                    onChange={handleChange}
                    onBlur={handleBlur}

                    variant="standard"

                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropswarehouse}
                value={warehouseValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setWarehouseValue(newValue);

                  values.warehouse = newValue;
                  console.log("133", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type="text"
                    autocomplete="off"
                    name="warehouse"
                    label="warehouse"
                    id="warehouse"
                    values={values.warehouse}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"


                  />
                )}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3} mt={1} >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={(event, newValue) => {
                  setStateValue(newValue);
                  getDistrictData(newValue?.state?.code);
                  console.log(newValue);
                }}
                renderInput={(params) => (

                  <MDInput
                    {...params}
                    type="text"
                    label="State"
                    variant="standard"


                  />
                )}
              />

            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={(event, newValue) => {
                  setdistrictValue(newValue);
                  getSubDistrictData(
                    newValue?.state?.code,
                    newValue?.district?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="District"
                  />
                )}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3} mt={1} >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subDistrictValue}
                onChange={(event, newValue) => {
                  setSubDistrictValue(newValue);
                  getVillageData(
                    newValue?.state?.code,
                    newValue?.district?.code,
                    newValue?.subdistrict?.code
                  );
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="SubDistrict"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsparentpincode}
                value={pincodeValue}
                onChange={(event, newValue) => {
                  setPincodeValue(newValue);
                  values.pincode = newValue;
                  console.log("village", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type="text"
                    autocomplete="off"
                    name="pincode"
                    id="pincode"
                    variant="standard"
                    label="Pincode"
                  />
                )}
              />
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >create </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Projectcomponet;




