// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/Association/new Association/componets/FormField";
import { Autocomplete, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";
import DataTable from "examples/Tables/DataTable";
import { FaRegTimesCircle } from "react-icons/fa";
import MDButton from "components/MDButton";

function AssociationInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [mmuValue, setMmuValue] = React.useState(null);
  const [roleValue, setrolevalue] = React.useState(null);
  const [profileValue, setprofileValue] = React.useState(null);

  const [vehicleType, setVehicleType] = React.useState("");
  const [vehicleno, setVehicleNo] = React.useState("");
  const [mmuName, setMmuName] = React.useState("");
  const [mmuCode, setMmuCode] = React.useState("");
  const [mmuDesc, setMmuDesc] = React.useState("");
  const [wareHouse, setWareHouse] = React.useState("");

  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist
  );
  const [roleList, setrolelist] = useState("");

  const [profileList, setprofileList] = useState("");

  const { formField, values, errors, touched } = formData;
  const associationModel = {
    mmucode: mmuCode,
    mmuname: mmuName,
    rolecode: "",
    rolename: "",
    fullname: "",
    firstname: "",
    lastname: "",
    classname: "",
    createdby: "",
    createddate: "",
    modifiedby: "",
    approvedby: "",
    approveddate: "",
    latlong: "",
    recstatus: "OPEN",
    syncstatus: "SYNCED",
    status: "ACTIVE",
    documentofproof: "",
    profileid: "",
  };
  const associationObj = {
    columns: [
      { Header: "MMU", accessor: "mmuname", width: "6%" },
      {
        Header: "Role",
        accessor: "rolename",
        width: "15%",
      },
      { Header: "Name", accessor: "fullname", width: "8%" },
      { Header: "Action", accessor: "action", width: "8%" },
    ],
    rows: [],
  };

  const [associationData, setAssociationData] = React.useState(associationObj);

  const equipmentObj = {
    columns: [
      { Header: "sapcode", accessor: "sapcode", width: "25%" },
      { Header: "Equiment Name", accessor: "name", width: "30%" },
      { Header: "Qty", accessor: "inputQty", width: "12%" },
      { Header: "Asset No", accessor: "inputSerialNo" },
    ],
    rows: [],
  };
 
  const [equipmentDataList, setEquipmentDataList] =
    React.useState(equipmentObj);

  function onSelectionMMU(mmuObj) {
    setMmuName(mmuObj?.name);
    if (
      typeof mmuObj.description === "undefined" ||
      mmuObj.description === null
    ) {
      setMmuDesc("NA");
    } else {
      setMmuDesc(mmuObj?.description);
    }
    if (
      typeof mmuObj?.vehicle === "undefined" ||
      mmuObj?.vehicle === null ||
      typeof mmuObj?.vehicle?.name === "undefined" ||
      mmuObj?.vehicle?.name === null
    ) {
      setVehicleType("NA");
      setVehicleNo("NA");
    } else {
      setVehicleType(mmuObj?.vehicle?.name);
      setVehicleNo(mmuObj?.vehicle?.sapcode);
    }
    if (
      typeof mmuObj?.warehouse === "undefined" ||
      mmuObj?.warehouse === null ||
      typeof mmuObj?.warehouse?.name === "undefined" ||
      mmuObj?.warehouse?.name === null
    ) {
      setMmuDesc("NA");
    } else {
      setWareHouse(mmuObj?.warehouse?.name);
    }

    mmuObj?.equipmentlist?.map((obj) => {
      if(obj.item != null){
      if(obj.item?.inputQty != null){
      obj.item.inputQty = obj.quantity;
      }
      if(obj.item?.inputSerialNo != null){
      obj.item.inputSerialNo = obj.assetno;
      }
      equipmentObj.rows.push(obj.item);
    }
    });
    setEquipmentDataList(equipmentObj);
  }
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option?.vehicle?.name ? option?.name + " (" + option?.vehicle?.name + ")":option?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsRole = {
    options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsprofileList = {
    options: !profileList
      ? [{ firstname: "Loading...", lastname: "", id: 0 }]
      : profileList,
    getOptionLabel: (option) =>
      option?.lastname
        ? option?.firstname + " " + option?.lastname+" ("+option?.username+")"
        : option?.firstname,

    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    mmu,
    vechicleassigned,
    vehicleNo,
    role,
    profile,
    mmuname,
    mmudescription,
    equipmentassigned,
    warehouse,
    associationList,
  } = formField;
  var {
    vechicleassigned: vechicleassignedV,
    mmuname: mmunameV,
    vehicleNo: vehicleNoV,
    mmudescription: mmudescriptionV,
    equipmentassigned: equipmentassignedV,
    warehouse: warehouseV,
    associationList: associationListV,
  } = values;

  function addProfileInAssociationObj(profileObj) {
    associationModel.rolecode = profileObj.roleObj.rolecode;
    associationModel.username = profileObj.username;
    associationModel.rolename = profileObj.roleObj.name;
    associationModel.profileid = profileObj.profileid;
    associationModel.firstname = profileObj.firstname;
    associationModel.lastname = profileObj.lastname;
    associationModel.fullname =
      profileObj.firstname + " " + profileObj.lastname;
    
    console.log(associationModel);
  }
  const removeItem = function (id) {
    const objWithIdIndex = associationData.rows.findIndex(
      (obj) => obj.id === id
    );
    associationData.rows.splice(objWithIdIndex, 1);
    setAssociationData(associationData);
    values.associationList = associationData.rows;
  };

  function addAssociation() {
 if(!mmuValue){
  swal("Oops!", 
    "Please select a mmu first!",
    "warning"
);
 }else if(!roleValue){
  swal("Oops!", 
    "Please select a role first!",
    "warning"
);
}else if(!associationModel.firstname){
  swal("Oops!", 
    "Please select a profile first!",
    "warning"
);
}
 else{
    let filter = associationData.rows.filter(
      (a) =>
        a.fullname == associationModel.fullname ||
        typeof associationModel.fullname === "undefined"
    );
  

    if (filter.length < 1) {
      associationModel.action = (
        <FaRegTimesCircle
          style={{
            color: "#e91e63",
            width: "28px",
            height: "28px",
            cursor: "pointer",
          }}
          onClick={() => removeItem(associationModel.id)}
        />
      );
      associationData.rows.push(associationModel);
      associationObj.rows = associationData.rows;
      setAssociationData(associationObj);
      values.associationList = associationData.rows;
      console.log(associationData);
    }
    setrolevalue(null)
    setprofileValue(null)
  }
  }
  async function getProfileByRoleCode(roleObj) {
    await axios
      .get(
        `profile-service-mongo/api/ProfileEntity/v2/get?rolecode=${roleObj?.rolecode}&recstatus=APPROVED`
      )
      .then((response) => {
        for (let i = 0; i < response?.data?.data?.dtoList?.length; i++) {
          response.data.data.dtoList[i].roleObj = roleObj;
        }
        // response?.data?.data?.dtoList?.map((obj) => {
        //   response?.data?.data?.dtoList.roleObj = roleObj;
        // });
        setprofileList(response?.data?.data?.dtoList);
        console.log("pro", profileList);
        // setprofileList(profileList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getData() {
    await axios
      .get(
        `role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        setrolelist(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
    setLoading(false);
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">MMU Association</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsMMU}
                value={mmuValue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  onSelectionMMU(newValue);
                  setMmuCode(newValue?.code);
                  setMmuValue(newValue);

                  // values.mmu = newValue?.code;
               
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.mmu && touched.mmu}
                    success={mmu.length > 0 && !errors.mmu}
                    variant="standard"
                    label={mmu.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={vechicleassigned.type}
                label={vechicleassigned.label}
                name={vechicleassigned.name}
                value={vehicleType}
                disabled="true"
                placeholder={vechicleassigned.placeholder}
                // error={errors.vechicleassigned && touched.vechicleassigned}
                // success={
                //   vechicleassignedV.length > 0 && !errors.vechicleassigned
                // }
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            
           <Grid item xs={12} sm={6}>
              <FormField
                type={mmuname.type}
                label={mmuname.label}
                name={mmuname.name}
                value={mmuName}
                placeholder={mmuname.placeholder}
                disabled="true"
                // error={errors.mmuname && touched.mmuname}
                // success={mmunameV.length > 0 && !errors.mmuname}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormField
                type={vehicleNo.type}
                label={vehicleNo.label}
                name={vehicleNo.name}
                value={vehicleno}
                placeholder={vehicleNo.placeholder}
                disabled="true"
                // error={errors.vehicleNo && touched.vehicleNo}
                // success={vehicleNoV.length > 0 && !errors.vehicleNo}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={mmudescription.type}
                label={mmudescription.label}
                name={mmudescription.name}
                value={mmuDesc}
                placeholder={mmudescription.placeholder}
                disabled="true"
                // error={errors.mmudescription && touched.mmudescription}
                // success={mmudescriptionV.length > 0 && !errors.mmudescription}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>Assigned Equipment List</h4>
            </Grid>
          </Grid>
          <Grid container spacing={3} gap={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={warehouse.type}
                label={warehouse.label}
                name={warehouse.name}
                value={wareHouse}
                placeholder={warehouse.placeholder}
                disabled="true"
                // error={errors.warehouse && touched.warehouse}
                // success={warehouseV.length > 0 && !errors.warehouse}
              />
              <Grid container spacing={3}>
              
                <Grid item xs={12} sm={12} mb={2} >
                  <Autocomplete
                    {...defaultPropsRole}
                    value={roleValue}
                    onChange={(event, newValue) => {
                      // handleChange(event);
                      getProfileByRoleCode(newValue);
                      setrolevalue(newValue)
                      // setrolevalue(newValue);

                      // values.roleValue =
                      //   newValue?.firstName + " " + newValue?.lastName;
                      // console.log(newValue);
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        error={errors.role && touched.role}
                        success={role.length > 0 && !errors.role}
                        variant="standard"
                        label={role.label}
                      />
                      // <TextField {...params} label={role.label} />
                    )}
                  />
                </Grid>
         
               
              
              </Grid>
              <Grid container spacing={3} >
          
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  {...defaultPropsprofileList}
                  value={profileValue}
                  onChange={(event, newValue) => {
                    // console.log("setprofileValue",newValue)
                    addProfileInAssociationObj(newValue);
                    // setprofileValue(newValue)
                  }}
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      error={errors.profile && touched.profile}
                      success={profile.length > 0 && !errors.profile}
                      variant="standard"
                      label={profile.label}
                    />
                    // <TextField {...params} label={profile.label} />
                  )}
                />
              </Grid>
             
            
            </Grid>
            <Grid container spacing={3} mb={2} style={{ display:"flex",justifyContent:"flex-end"}}>
            <Grid item xs={12} sm={3}   >
                  <MDButton
                    color="info"
                    size="small"
                    value="submit"
                    style={{ marginTop: "10px" }}
                    onClick={addAssociation}
                  >
                    ADD
                  </MDButton>
                  </Grid>
        
         
        
        </Grid>
              <Grid container spacing={3}>
        
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    border: "1px solid #727375",
                    borderRadius: "5px",
                    marginTop: "25px",
                  }}
                >
                  <DataTable
                    showTotalEntries={false}
                    entriesPerPage={false}
                    table={associationData}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5.8}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "25px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={equipmentDataList}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for association info
AssociationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AssociationInfo;
