import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

//icon
import HeightIcon from '@mui/icons-material/Height';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// List regarding items 
import Box from '@mui/material/Box';
import { ListItem, ListSubheader } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
//import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';



const ChangeRequest= () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);



  
  const [color] = useState("#344767");


  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);



  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);


  const [getResponse, setResponse] = useState([]);
  const [givenQty, setGivenQty] = useState("");

  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };
  // Reject close====================

  const handleCloseReject = () => {
    console.log("dsfsdfs");
    setOpenReject(false);
  };


  
  async function getpatient(mmucode) {
    setLoading(true);

    await axios
      .get(
        `patient-service/api/patient/v1/get?patientid=PA201222054134512`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {

        });
        setResponse(response?.data?.data?.dtoList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response?.data?.data?.dtoList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  //  list of History View 
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Medicine Change Request
                  </MDTypography>
                </span>

                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={7}>

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginLeft: "20px" }}
                      sx={{ width: 300 }}
                      {...defaultPropsprojectMMU}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getpatient(newValue?.code)
                        setMmuvalue(newValue);
                        console.log(newValue);
                      }}

                      renderInput={(params) => (
                        <TextField {...params} label="Select-mmu" />
                      )}
                    />
                  </Grid>


                </Grid>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/stock/create-requisition"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Name :
                          </text>
                          {" " + "Check_Name"}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Age :
                          </text>
                          {" " + "chck_Age"}

                        </div>
                      </div>
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sm={3}>

                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text>
                          Prescription Id :
                          </text>
                          {" " + "ID_1234"}

                        </div>
                        <div>
                        {" __ "  + "Change to"  + " __ " }
                          {/* <text>
                          Change to  {" ____ " }
                          </text>
                          {" ____ "}  */}
                        </div>
                      </div>

                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Approve
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          style={{ marginLeft: "-47px" }}
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setOpenReject(true);
                          }}
                        >
                          Reject
                        </MDButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>

      {/* Approve popup================================================= */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Approve Action </DialogTitle>
      </Dialog>


      {/* Reject Action popup================================================= */}
      <Dialog open={openReject} onClose={handleCloseReject}>
        <DialogTitle style={{ textAlign: "centre" }}>Reject Action</DialogTitle>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};


export default ChangeRequest;

