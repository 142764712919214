// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "layouts/pages/vitals/new-vitals/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function VitalsInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [value, setValue] = React.useState(null);
  const [uomvalue, setUomValue] = React.useState(null);
  const [vitalList, setVitaltList] = useState("");
  const [uomList, setUomtList] = useState("");
  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !vitalList ? [{ name: "Loading...", id: 0 }] : vitalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsuom={
    options: !uomList ? [{ name: "Loading...", id: 0 }] : uomList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { vitalsName, vitalsType,vitalrange,uom} = formField;
  var {
    vitalsName: vitalsNameV,
    vitalrange:vitalrangeV
   
    
  } = values;

  async function getData() {
    await axios
      .get(`typemaster-service/api/TypeMaster/v1/get?category=VITAL`)
      .then((response) => {
        setVitaltList(response?.data?.data?.dtoList);

        setLoading(false);
    
      })

      .catch((e) => {
        console.log("error", e);
      });

      await axios
      .get(`typemaster-service/api/TypeMaster/v1/get?category=VITAL_UOM`)
      .then((response) => {
        setUomtList(response?.data?.data?.dtoList);

        setLoading(false);
       
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Vitals Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={vitalsName.type}
                label={vitalsName.label}
                name={vitalsName.name}
                value={vitalsNameV}
                placeholder={vitalsName.placeholder}
                error={errors.vitalsName && touched.vitalsName}
                success={vitalsNameV.length > 0 && !errors.vitalsName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
                 <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);

                  values.vitalsType = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                   error={errors.vitalsType && touched.vitalsType}
                    success={vitalsType.length > 0 && !errors.vitalsType}
                    variant="standard"
                    label={vitalsType.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
            <FormField
                type={vitalrange.type}
                label={vitalrange.label}
                name={vitalrange.name}
                value={vitalrangeV}
                placeholder={vitalrange.placeholder}
                error={errors.vitalrange && touched.vitalrange}
                success={vitalrangeV.length > 0 && !errors.vitalrange}
              />
          
            </Grid>
            <Grid item xs={12} sm={6}>
           <Autocomplete
                {...defaultPropsuom}
                value={uomvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setUomValue(newValue);

                  values.uom = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                   error={errors.uom && touched.uom}
                    success={uom.length > 0 && !errors.uom}
                    variant="standard"
                    label={uom.label}
                  />
                )}
              />
         
           </Grid>
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for VitalsInfo
VitalsInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default VitalsInfo;




