import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd, MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

const Results = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);

  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  const dataObj = {
    columns: [
      { Header: "name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Item Type", accessor: "itemtype" },
      { Header: "mmu code", accessor: "mmucode" },

      // { Header: "action", accessor: "action" }
    ],
    rows: [],
  };
  const dataObj2 = {
    columns: [

      { Header: "patient id", accessor: "patientid" },
      { Header: "patient name", accessor: "name" },
      { Header: "age", accessor: "range" },
      { Header: "village", accessor: "value" },
      { Header: "Test Result", accessor: "teststresult" },

      { Header: "status", accessor: "status" },
      { Header: "Lab Techician", accessor: "Labtechician" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const [tableDatas2, setTableDatas2] = useState(dataObj2);

  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };



  async function getMMUAssociation(profileId) {
    setLoading(true);

    await axios
      .get(

        `mmuassociation-service/api/MMUAssociation/v1/getAllByProfileId/${profileId}`
      )
      .then((response) => {
        console.log(response?.data?.data?.dtoList);

        if (response?.data?.data?.dtoList.length > 0) {
          // setMmucode(response?.data?.data?.dtoList[0].mmucode);
          getLabresult(response?.data?.data?.dtoList[0].mmucode)
        }

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getLabresultview(item){
    console.log("130", item)
    for(let i=0; i<item.labtestslist.length; i++){
      item.labtestslist[i].investigation = item.labtestslist[i]?.investigationlist[0]
    }
    const dataObj = {
      columns: [
        { Header: "name", accessor: "investigation.name" },
  
        { Header: "UOM", accessor: "investigation.uom" },
        { Header: "range", accessor: "investigation.range" },
        { Header: "value", accessor: "investigation.value" },
        { Header: "status", accessor: "recstatus" },
        { Header: "test result", accessor: "testresult" },
        { Header: "lab technician", accessor: "modifiedby" }
      ],
      rows: item.labtestslist,
    };
    
    console.log("144", item.labtestslist);
    setTableDatas(dataObj)
  }


  async function getLabresult(mmucode) {
    console.log("88", mmucode);

    // setLoading(true);
    const dataObj2 = {
      columns: [

        { Header: "patient id", accessor: "patient.patientid" },
        { Header: "patient name", accessor: "patient.name" },
        { Header: "age", accessor: "patient.age" },
        { Header: "village", accessor: "patient.village" },
        { Header: "prescription id", accessor: "visit.presid" },

        { Header: "doctor", accessor: "visit.createdby" },
        { Header: "test date", accessor: "testDate" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };


    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/getLabTestByVisit?recstatus=PRESCRIBED&mmucode=${mmucode}`
      )
      .then((response) => {
        console.log(response.data.data);
        response.data.data.labTestAggregationList.map((item) => {
          if (item.labtestslist.length > 0) {
            item.testDate = new Date(item.labtestslist[0]?.createddate).toDateString();
          }
          item.action = (
            <span>
              <Link to={{}}>
                <MdOutlinePreview
                  style={iconStyle}
                  onClick={() => {
                    setOpen(true);
                    getLabresultview(item)
                  }}
                />
              </Link>


            </span>
          );
          dataObj2.rows.push(item);
        });
        console.log("res70", dataObj2);
        setTableDatas2(dataObj2);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getMMUAssociation(loginUser.profileid);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Lab-Result
                  </MDTypography>
                </span>

                {/* <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginLeft: "20px" }}
                      sx={{ width: 300 }}
                      {...defaultPropsprojectMMU}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getLabresult(newValue?.code)
                        setMmuvalue(newValue);
                        console.log(newValue);
                      }}

                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>

                </Grid> */}


              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas2} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
      
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            backgroundColor: "blue",
            color: "#FFFFFF",
          }}
        >
        Lab result view
        </DialogTitle>
        <DialogContent style={{ minWidth: 500, minHeight: 350 }}>

           <DataTable table={tableDatas} canSearch={false} entriesPerPage={false} showTotalEntries={false} />
        </DialogContent>
      
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};

export default Results;