/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";


// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";


import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";
import MDInput from "components/MDInput";

import moment from "moment-timezone";
import MDButton from "components/MDButton";
import { CSVLink } from "react-csv";
import { getFilter } from "service/reporting";



function StockReport() {
  function stringTokenizer(string, delim) {
    const st = string.split(delim);
    return st;
  }

  const { sales, tasks, patient, prescription } = reportsLineChartData;

  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentyear, setCurrentYear] = useState(moment().format('YYYY'));
  const [currentyearvalue, setCurrentYearvalue] = useState(null);
  const [month, setMonth] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[1] : 0);
  const [year, setYear] = useState(currentMonth ? stringTokenizer(currentMonth, "-")[0] : 0);
  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();
  const [color] = useState("#344767");
  const [projectDetail, setProjectDetail] = useState(localStorage.getItem("project-Detail"));



  const [wareHouseList, setWarehouseList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).childwarehouselist
  );
  const dataObj = {
    columns: [
      { Header: "name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Item Type", accessor: "itemtype" },
      { Header: "warehouse code", accessor: "whid" },
      { Header: "mmu code", accessor: "mmucode" },
      { Header: "quantity pending", accessor: "quantitypndg" },
      { Header: "quantity rcvd", accessor: "quantityrcvd" },
      { Header: "quantity Rqst", accessor: "quantityrqst" },

    ],
    rows: [],
  };


  const [tableDatas, setTableDatas] = useState(dataObj);
  const [warevalue, setWarevalue] = useState(null);
  const [filters, setFilters] = useState(getFilter("", ""));
  const [mmuvalue, setMmuvalue] = useState(null);
  const [typevalue, settypevalue] = useState(null);
  const [days, setDays] = useState(null);
  const [datas, setDatas] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [filename, setFilename] = useState("Patient_Repot.csv");
  const [engagementList, setEngagementList] = useState();
  const [showhide, setShowhide] = useState("");
  const [yearlist, setYearList] = useState(null);
  const [mmuCodeList, setmmuCodeList] = useState(null);
  // Action buttons for the BookingCard




  function buildFilenameV2(type, code) {
    setFilename(type + "_" + code + "_" + moment().format('YYYY-MM-DD') + ".csv");
  }
  const warehouseObject = {
    "page": "STOCK-REPORT-BY-WHID-1",
    "queries": {
      "0": [
        {
          "field": "whid",
          "operation": "EQUAL",
          "values": [
            warevalue?.whmmucode
          ]
        }
      ]
    }
  }
  const mmuObject = {
    "page": "STOCK-REPORT-BY-MMU-1",
    "queries": {
      "0": [
        {
          "field": "mmucode",
          "operation": "EQUAL",
          "values": [
            mmuvalue?.code
          ]
        }

      ]
    }
  }

  async function getData(object) {
    setLoading(true);
    await axios
      .post(`reporting-service/api/Reporting/v1/`, object)

      .then((response) => {

        console.log("322", response.data);
        dataObj.rows = response.data
        setHeaders([
          { label: "name", key: "name" },

          { label: "UOM", key: "uom" },
          { label: "current stock", key: "currentstock" },
          { label: "Item Type", key: "itemtype" },
          { label: "warehouse code", key: "whid" },
          { label: "mmu code", key: "mmucode" },
          { label: "quantity pending", key: "quantitypndg" },
          { label: "quantity rcvd", key: "quantityrcvd" },
          { label: "quantity Rqst", key: "quantityrqst" },
        ]);

        buildFilenameV2("stock", "");
        setDatas(response.data);
        setTableDatas(dataObj)
        setLoading(false);

      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  function handleFilter() {
    console.log(mmuvalue);
    console.log(warevalue);
    if (mmuvalue == null && warevalue != null) {
      console.log(warehouseObject)
      getData(warehouseObject)
    }
    if (mmuvalue != null) {
      console.log(mmuObject)
      getData(mmuObject)
    }



  }

  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.name + " (" + option.vehicle.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsType = {
    options: [{ type: "Yearly", code: "x" }, { type: "Monthly", code: "y" },
    { type: "Datewise", code: "z" },
    ],
    getOptionLabel: (option) => option?.type,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };

  const defaultPropsYear = {
    options: !yearlist ? [{ name: "Loading...", code: 0 }] : yearlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };


  function getYearList() {
    console.log("533", currentyear)
    var yearlist = []
    for (let i = 2020; i <= currentyear; i++) {
      yearlist.push({ name: i.toString(), code: i })

    }
    console.log("540", yearlist)
    return yearlist;



  }
  function dateFormat(date) {

    const d = new Date(date);

    return moment(d).format('YYYY-MM-DD');

  }


  function getStartEndDateByYear(year) {
    // var yearStart = moment().format('01/01/' + year);
    var yearStart = dateFormat('01/01/' + year)
    setStartDate(yearStart)
    console.log("549", yearStart)
    var yearEnd = dateFormat('12/31/' + year);
    console.log("END", yearEnd)
    setEndDate(yearEnd)


  }

  function getStartDateEndDatebymonth(month, year) {
    console.log("569", month, year)

    var firstDay = dateFormat(month + '/01/' + year)
    var lastDay = dateFormat(month + '/31/' + year)

    setStartDate(firstDay)
    setEndDate(lastDay)

  }
  function getStartDateEndDatebydatewise(date) {
    console.log(date)

    setStartDate(date)
    setEndDate(date)
    console.log("585", startDate)
  }
  const csvReport = {
    data: datas,
    headers: headers,
    filename: filename
  };

  useEffect(() => {

    setYearList(getYearList())

    console.log("138", filters);

  }, []);
  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        console.log("610", response)
        setmmuCodeList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  function changestatus(code) {
    console.log("557", code);
    setShowhide(code);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />


        <Grid container spacing={3} my={1}>

          <Grid item xs={12} sm={2.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280 }}
              {...defaultPropsWharehouse}
              value={warevalue}
              onChange={(event, newValue) => {
                console.log("248", newValue);
                getMmuDataByWarehouse(newValue?.whmmucode);
                setWarevalue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select WareHouse" />
              )}
            />

          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280, marginLeft: "24px" }}
              {...defaultPropsmmuCode}
              value={mmuvalue}
              onChange={(event, newValue) => {
                console.log(newValue);
                setMmuvalue(newValue);
                // getPatientDataByMmu(newValue?.mmu?.code, newValue?.mmu?.name);
                // getDataBymmu(newValue?.code);
                // setMmuvalue(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select MMU" />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={2.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // sx={{ width: 280, marginLeft: "24px" }}
              {...defaultPropsType}
              value={typevalue}
              onChange={(event, newValue) => {
                console.log(newValue?.code);
                settypevalue(newValue);
                changestatus(newValue?.code);
               
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Data Type" />
              )}
            />
          </Grid>
          {showhide === "x" && (
            <Grid item xs={12} sm={3}>


              <Autocomplete
                disablePortal
                id="combo-box-demo"
                // sx={{ width: 280, marginLeft: "24px" }}
                {...defaultPropsYear}
                value={currentyearvalue}
                onChange={(event, newValue) => {
                  
                  setCurrentYearvalue(newValue)
                  getStartEndDateByYear(newValue?.name)
                  // yearEndtDate(newValue?.name)
                  console.log("680", newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} label="year" />
                )}
              />

            </Grid>
          )}
          {showhide === "y" && (
            <Grid item xs={12} sm={3}>


              <MDInput type="month" label="Month" value={currentMonth} onChange={(e) => {
                setCurrentMonth(e.target.value)
                console.log(e.target.value);

                // monthEndDate
                var st = stringTokenizer(e.target.value, "-");
                setYear(st[0]);
                setMonth(st[1]);
                getStartDateEndDatebymonth(st[1], st[0])
                // console.log(st);
              }} />

            </Grid>
          )}
          {showhide === "z" && (
            <Grid item xs={12} sm={3}>

              <MDInput type="date" label="Date" value={currentDate}
                onChange={(e) => {
                  setCurrentDate(e.target.value)
                  getStartDateEndDatebydatewise(e.target.value)
                  console.log(e.target.value);
                }} />

            </Grid>
          )} */}
          <Grid item xs={12} sm={3.5}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleFilter}
            >
              Filter
            </MDButton>
          </Grid>
        </Grid>


        <MDBox mt={6}>
          <Grid container spacing={3}>


            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>

                <MDBox m={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"


                  >
                    <CSVLink {...csvReport} style={{ color: "white" }}>Export to CSV</CSVLink>
                  </MDButton>
                </MDBox>
                <DataTable table={tableDatas} canSearch={true} />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}





export default StockReport;
