import * as React from 'react';
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete } from "@mui/material";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";


//EDit button

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

function LabList() {
  const comp = "SM";
 
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [mmuList, setMmuList] = useState("");
  const [testresult, setTestresult] = useState("");
  const [Updatename, setUpdatename] = useState("");
  const [detailresult, setDetailresult] = useState("");
  const [presid, setPresid] = useState("");
  const [item, setItem] = useState({});
  const [selectmmu, setSelectMmu] = useState("")
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };

  const [err, setErr] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultProps = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getmmuData() {
    await axios
      .get(`mmucreation-service/api/MMUCreation/v1/get`)
      .then((response) => {
        setMmuList(response?.data?.data?.dtoList);
        getData(response?.data?.data?.dtoList);
       
     
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  async function onUpdatedata(object) {
    console.log("85",object);
    setSelectMmu(object.mmucode);

    // console.log("86",departmentList);
    // setMmuList(departmentList);
    // // const departmentHeraic = object.hierarchicalcode.split('-');
    // // console.log(departmentHeraic);
    // console.log("hii", object);
    // for (let i=0;i<departmentList.length;i++){

    //   if(departmentList[i].code===object.parentdepcode){
    //     console.log("900",departmentList[i])
    //     setSelectMmu(departmentList[i]);
    //   }
    // }
  //  let depobj= departmentList.map((obj) => obj.code ===
  //     object.parentdepcode)
      // console.log("91",selectmmu);
  //   setSelectDep(depobj)
  


    setItem(object)

    setUpdatename(object.testname);
    setTestresult(object.testresult);
    setPresid(object.presid);
    setDetailresult(object.detailedresult);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that Lab record"))
      await axios
        .put("labtests-serv/api/labTests/v1/delete", item)
        .then((response) => {
          console.log(response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function updateData() {

    item.testname = Updatename;
    item.testresult = testresult;
    item.presid = presid;
    item.detailedresult = detailresult;
    item.modifiedby= JSON.parse(localStorage.getItem('mmu-auth')).firstname+" "+JSON.parse(localStorage.getItem('mmu-auth')).lastname;
          delete item.action
    await axios
      .put(
        "department-service-mongo/api/department/v2/update",
        item
      )
      .then((response) => {

        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "test name", accessor: "testname", width: "15%" },
        { Header: "test result", accessor: "testresult", width: "20%" },
        { Header: "prescription", accessor: "presid" },
        { Header: "MMU", accessor: "mmucode", width: "10%" },
        { Header: "detail result", accessor: "detailedresult" },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(`labtests-serv/api/labTests/v1/get`)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
 
  useEffect(() => {
  getmmuData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Lab Test LIST
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/users/create-lab"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Update Lab</DialogTitle>
        <DialogContent style={{ width: 550, height: 290 }}>
          <MDBox mb={2}>

            <MDInput
              type="text"
              label="Test Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Test Result"
              variant="standard"
              fullWidth
              value={testresult}
              onChange={(e) => setTestresult(e.target.value)}

            />

          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Prescrption Id"
              variant="standard"
              fullWidth
              value={presid}
              onChange={(e) => setPresid(e.target.value)}

            />

          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultProps}
              value={selectmmu}
              onChange={(event,newValue) => {
                // handleChange(event);
                item.name=newValue?.mmucode;
          //  item.parentdepcode=newValue?.code;
          //  item.parenthierarchicalcode=newValue?.hierarchicalcode;
          //  item.hierarchicalcode=newValue?.hierarchicalcode+"-"+item.code;
                setSelectMmu(newValue);

                // values.department = newValue?.hierarchicalcode;
                console.log("281", selectmmu)

              }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  variant="standard"
                  label="Mmu"
                />
              )}
            />

          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Test Result"
              variant="standard"
              fullWidth
              value={detailresult}
              onChange={(e) => setDetailresult(e.target.value)}

            />

          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>Update</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}
export default LabList;
