import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";
import MDBadge from "components/MDBadge";

const RequsitionList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [wareHouseList, setWarehouseList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).childwarehouselist
  );
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);

  const [getResponse, setResponse] = useState([]);
  const [givenQty, setGivenQty] = useState("");

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const dataObj = {
    columns: [
      {
        Header: "Sub-district name",
        accessor: "subdistrict.name",
        width: "25%",
      },
      { Header: "short name", accessor: "subdistrict.shortname" },
      { Header: "code", accessor: "subdistrict.code" },

      {
        Header: "district",
        accessor: "districtFullname",
        width: "15%",
      },
      {
        Header: "state",
        accessor: "stateFullname",
        width: "15%",
      },
      { Header: "action", accessor: "action", width: "12%" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  //   const defaultPropsState = {
  //     options: !wareHouseList
  //       ? [
  //           {
  //             state: {
  //               shortname: "- -",
  //               name: "Loading...",
  //             },
  //             id: 0,
  //           },
  //         ]
  //       : wareHouseList,
  //     getOptionLabel: (option) =>
  //       option.state?.name + " (" + option.state?.shortname + ")",
  //     isOptionEqualToValue: (option, value) => option.id === value.id,
  //   };
  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + " (" + option.vehicle?.name + ")":option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onUpdatedata(object) {
    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  //   async function onDelete(item) {
  //     if (
  //       window.confirm("Are you sure that you want to delete that user record")
  //     ) {
  //       await axios
  //         .put(
  //           "https://apithf.softwaremathematics.com/pincode-service/api/pincode/v1/delete",
  //           item
  //         )
  //         .then((response) => {
  //           console.log(response);
  //           getVillageData(item.state?.code, item.district?.code);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   }

  //   async function updateData() {
  //     item.name = Updatename;
  //     item.description = description;
  //     await axios
  //       .put(
  //         "https://apithf.softwaremathematics.com/designation-service-mongo/api/designation/v2/update",
  //         item
  //       )
  //       .then((response) => {
  //         setOpen(false);
  //         getData();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // function handleSubmit(obj) {
  //     console.log("obj", obj);
  //     for (var i = 0; i < getResponse.length; i++) {
  //       if (getResponse[i].medRequisitionGenTxnDTO.mrnumber === obj.mrnumber) {
  //         console.log("done", getResponse[i]);
  //         for (
  //           var j = 0;
  //           j < getResponse[i].medRequisitionGenDTOList.length;
  //           j++
  //         ) {
  //           if (getResponse[i].medRequisitionGenDTOList[j].id === obj.id) {
  //             console.log("item", getResponse[i].medRequisitionGenDTOList[j]);
  //             getResponse[i].medRequisitionGenDTOList[j].quantityRcvd = givenQty;
  //             console.log(getResponse);
  //             setResponse(getResponse);
  //             getData();
  //           }
  //         }
  //       }
  //     }
  //   }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);

    await axios
      .get(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?owhid=${wharehouse}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "owhname" },
              { Header: " type", accessor: "itemtype" },

              {
                Header: "Requested Qty",
                accessor: "quantityrqst",
              },
              {
                Header: "Qty Approved",
                accessor: "quantityapproved",
              },

              // {
              //   Header: "Given Qty ",
              //   accessor: "input",
              //   width: "5%",
              // },
              // { Header: "action", accessor: "action", width: "10%" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            i.input = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                onChange={(e) => setGivenQty(e.target.value)}
                style={{ width: "70px" }}
              />
            );

            // i.action =
            //   i.quantityapproved === i.quantityrcvd &&
            //   i.quantityapproved !== "" &&
            //   i.quantityrcvd !== "" &&
            //   typeof i.quantityapproved !== "undefined" &&
            //   typeof i.quantityrcvd !== "undefined" ? (
            //     <MDButton
            //       variant="gradient"
            //       color="info"
            //       size="small"
            //       value="Submit"
            //       disabled
            //     >
            //       Submit
            //     </MDButton>
            //   ) : (
            //     <MDButton
            //       variant="gradient"
            //       color="info"
            //       size="small"
            //       value="Submit"
            //       onClick={() => {
            //         handleSubmit(i);
            //       }}
            //     >
            //       Submit
            //     </MDButton>
            //   );
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `medrequisitiongen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "mmu", accessor: "mmucode" },
              { Header: " type", accessor: "itemtype" },
              { Header: "Uom", accessor: "uom" },

              {
                Header: "Requested Qty",
                accessor: "quantityrqst",
              },
              {
                Header: "Qty Approved",
                accessor: "quantityapproved",
              },

              // {
              //   Header: "Given Qty ",
              //   accessor: "input",
              //   width: "5%",
              // },
              // { Header: "action", accessor: "action", width: "10%" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            if (
              i.quantityapproved === i.quantityrcvd &&
              i.quantityapproved !== "" &&
              i.quantityrcvd !== "" &&
              typeof i.quantityapproved !== "undefined" &&
              typeof i.quantityrcvd !== "undefined"
            ) {
              i.input = (
                <MDInput
                  type="number"
                  variant="outlined"
                  size="small"
                  disabled="true"
                  style={{ width: "70px" }}
                />
              );
            } else {
              i.input = (
                <MDInput
                  type="number"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setGivenQty(e.target.value)}
                  style={{ width: "70px" }}
                />
              );
            }

            // i.action =
            //   i.quantityapproved === i.quantityrcvd &&
            //   i.quantityapproved !== "" &&
            //   i.quantityrcvd !== "" &&
            //   typeof i.quantityapproved !== "undefined" &&
            //   typeof i.quantityrcvd !== "undefined" ? (
            //     <MDButton
            //       variant="gradient"
            //       color="info"
            //       size="small"
            //       value="Submit"
            //       disabled
            //     >
            //       Submit
            //     </MDButton>
            //   ) : (
            //     <MDButton
            //       variant="gradient"
            //       color="info"
            //       size="small"
            //       value="Submit"
            //       onClick={() => {
            //         handleSubmit(i);
            //       }}
            //     >
            //       Submit
            //     </MDButton>
            //   );
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `mmucreation-service/api/MMUCreation/v1/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  // function getWarehouseData() {
  //   setLoading(true);

  //   axios
  //     .get(
  //       `https://apithf.softwaremathematics.com/wareHouseMaster-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
  //     )
  //     .then((response) => {
  //       setTableDatas(dataObj);
  //       setWarehouseList(response?.data?.data?.dtoList);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }

  // function getMMUData() {
  //   axios
  //     .get(
  //       `https://apithf.softwaremathematics.com/mmucreation-service/api/MMUCreation/v1/get`
  //     )
  //     .then((response) => {
  //       setmmuCodeList(response?.data?.data?.dtoList);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }

  useEffect(() => {
    // getData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Requsition List
                  </MDTypography>
                </span>

                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getDataBywharehouse(newValue?.whmmucode);
                        getMmuDataByWarehouse(newValue?.whmmucode);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280, marginLeft: "24px" }}
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/stock/create-requisition"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            REQUESTED ID :
                          </text>
                          {" " + values.medrequisitiongentxndto.mrnumber}
                          {values.medrequisitiongentxndto.ponumber && (
                            <MDBadge
                              badgeContent="PO Request"
                              size="md"
                              style={{ marginLeft: "12px" }}
                              color="primary"
                              gradient
                            />
                          )}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            SUBMITED by :
                          </text>
                          {" " + values.medrequisitiongentxndto.createdby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>STATUS :</text>
                          {values.medrequisitiongentxndto.recstatus ==
                          "OPEN" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#f0a326",
                              }}
                            >
                              {" " + values.medrequisitiongentxndto.recstatus}
                            </text>
                          ) : values.medrequisitiongentxndto.recstatus ==
                            "RECIEVED" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#05f221",
                              }}
                            >
                              {" " + values.medrequisitiongentxndto.recstatus}
                            </text>
                          ) : values.medrequisitiongentxndto.recstatus ==
                            "APPROVED" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#057ff2",
                              }}
                            >
                              {" " + values.medrequisitiongentxndto.recstatus}
                            </text>
                          ) : (
                            " " + values.medrequisitiongentxndto.recstatus
                          )}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>DATE :</text>

                          {" " +
                            new Date(
                              values.medrequisitiongentxndto.createddate
                            ).toDateString()}
                        </div>
                        {values.medrequisitiongentxndto.ponumber && (
                          <div>
                            <text style={{ fontWeight: "bold" }}>
                              PO Number :
                            </text>

                            {" " + values.medrequisitiongentxndto.ponumber}
                          </div>
                        )}
                        {values.medrequisitiongentxndto.ponumber && (
                          <div>
                            <text style={{ fontWeight: "bold" }}>Vendor :</text>

                            {" " + values.medrequisitiongentxndto.vendor}
                          </div>
                        )}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Designation List</DialogTitle>
        <DialogContent style={{ width: 550, height: 150 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Designation Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog> */}
      <Footer />
    </DashboardLayout>
  );
};

export default RequsitionList;
