// import React from 'react'

// // @mui material components
// import Card from "@mui/material/Card";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// // Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// // Data
// // import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// import { useEffect, useState } from "react";
// // import { getRoles } from "@testing-library/react";

// import axios from "axios";
// import { BarLoader } from "react-spinners";
// // import { item } from "examples/Sidenav/styles/sidenavItem";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaRegEdit } from "react-icons/fa";
// import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";


// import Roleedit from "./Roleedit"

// // import Button from '@mui/material/Button';

// // import Dialog from '@mui/material/Dialog';
// // import DialogActions from '@mui/material/DialogActions';
// // import DialogContent from '@mui/material/DialogContent';

// // import DialogTitle from '@mui/material/DialogTitle';
// import swal from 'sweetalert';

// const RolesListTable = () => {
//   const comp = "SM";
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [open, setOpen] = React.useState(false);
//   const [color] = useState("#344767");
//   const [tableDatas, setTableDatas] = useState({});
//   const [item, setItem] = useState({});
//   const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
//   const [openpopup,setopenpopup]=useState(false);
//   const [Updatename, setUpdatename] = useState("");
 
  
//   const [description, setDescription] = useState("");
//   const [err, setErr] = useState("");
//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };

//   async function onUpdatedata(object) {
//     setItem(object)

//     setUpdatename(object.name);
//     setDescription(object.description);
  
//     setOpen(true);
//   }

//   const handleClose = () => {
//     console.log("dsfsdfs");
//     setOpen(false);
//   };

//   async function onDelete(item) {

//     swal({
//       title: "Are you sure?",
//       text: "Once deleted, you will not be able to recover this role record!",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     })
//     .then((willDelete) => {
//       console.log(willDelete)
//       if (willDelete) {
//             axios
//         .put(
//           'https://apithf.softwaremathematics.com/role-service-mongo/api/role/v2/delete',
//           item
//         )
//             .then((response) => {
//           console.log(response);
//           getData();
//         })
//         swal("Poof! Your role record has been deleted!", {
//           icon: "success",
          
//         });
//       } else {
//         swal("Your role record is safe!");
//       }
//     });




//     // if (window.confirm("Are you sure that you want to delete that role record"))
//     //   await axios
//     //     .put(
//     //       'https://apithf.softwaremathematics.com/role-service-mongo/api/role/v2/delete',
//     //       item
//     //     )
//     //     .then((response) => {
//     //       console.log(response);
//     //       getData();
//     //     })
//     //     .catch((error) => {
//     //       console.log(error);
//     //     });
//   }



//   async function updateData() {

//     item.name = Updatename;
//     item.description = description;
   
//     await axios
//       .put(
//         'https://apithf.softwaremathematics.com/role-service-mongo/api/role/v2/update',
//         item
//       )
//       .then((response) => {

//         setOpen(false);
//         getData();
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//   }



//   function getData() {
//     setLoading(true);
//     const dataObj = {
//            columns: [
//         { Header: "name", accessor: "name", width: "15%" },
//         { Header: "code", accessor: "rolecode", width: "10%" },
//         { Header: "description", accessor: "description" },
//         { Header: "created by", accessor: "createdby" },
//         { Header: "created date", accessor: "createddateV2" },
//         { Header: "modified by", accessor: "modifiedby" },
//         { Header: "action", accessor: "action", width: "12%" },
//       ],
//       rows: [],
//     };
//     axios
//       .get(
//         `https://apithf.softwaremathematics.com/role-service-mongo/api/role/v2/get`
//       )
//       .then((response) => {
//         response?.data?.data?.dtoList?.map((item) => {
//           item.createddateV2 = new Date(item.createddate).toDateString();
//           item.action = (
//             <span>
//               <Link to={{}}>
//                 <FaRegEdit
//                   style={iconStyle}
//                   onClick={() => {
//                     setopenpopup(true);
//                   }}
//                 />
//               </Link>
//               <Link to={{}}>
//                 <MdDelete
//                   style={iconStyle}
//                   onClick={() => {
//                     onDelete(item);
//                   }}
//                 />

//               </Link>

//             </span>
//           );
//           dataObj.rows.push(item);
//         });

//         setTableDatas(dataObj);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   useEffect(() => {
//     getData();
//   }, []);
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={3}>
//         <MDBox>
//           <Card>
//             <MDBox pl={3} pr={3} lineHeight={1}>
//               <div style={addButtonStyle}>
//                 <span
//                   style={{
//                     alignSelf: "flex-start",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <MDTypography variant="h5" fontWeight="medium">
//                     Role List

//                   </MDTypography>
//                 </span>

//                 <Link
//                   style={{
//                     alignSelf: "flex-end",
//                     marginRight: "10px",
//                     marginTop: "-23px",
//                     color: "#344767",
//                   }}
//                   to={"/pages/profile/create-role"}
//                 >
//                   <MdOutlineLibraryAdd />
//                 </Link>
//               </div>
//             </MDBox>
//             <BarLoader
//               color={color}
//               loading={loading}
//               aria-label="Loading Spinner"
//             />
//             {!loading && <DataTable table={tableDatas} canSearch={true} />}
//           </Card>
//         </MDBox>
//       </MDBox>



//       {/* <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Update Role Table</DialogTitle>
//        <DialogContent style={{ width: 550, height: 150 }}>
//          <MDBox mb={2}>
//              <MDInput
//               type="text"
//               label="Role Name"
//               variant="standard"
//               fullWidth
//               value={Updatename}
//               onChange={(e) => setUpdatename(e.target.value)}
//             />
//             <span style={{ color: "red" }}>{err}</span>
//           </MDBox>
//           <MDBox mb={2}>
//             <MDInput
//               type="text"
//               label="Role Description"
//               variant="standard"
//               fullWidth
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//             />
//           </MDBox>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={updateData} disabled={!Updatename}>
//             Update
//           </Button>
//         </DialogActions>
//       </Dialog> */}
// <Roleedit
// openpopup={openpopup}
// setopenpopup={setopenpopup}>

// </Roleedit>

//       <Footer />
//     </DashboardLayout>
//   );
// }



// export default RolesListTable;


import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

function RolesListTable() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("mmu-auth")));
  const [open, setOpen] = React.useState(false);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  async function onUpdatedata(object) {
    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    item.modifiedby = user?.firstname + " " + user?.lastname + " (" + user?.username + ")";
      item.modifieddate = new Date()
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this role",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      console.log(willDelete)
      if (willDelete) {
        
            axios
        .put(
          "role-service-mongo/api/role/v2/delete",
          item
        )
            .then((response) => {
          console.log(response);
          getData();
        })
        swal("Poof! Your role record has been deleted!", {
          icon: "success",
          
        });
      } else {
        swal("Your role record is safe!");
      }
    });
  }

 

  async function updateData() {
    item.name = Updatename;
    item.description = description;
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    item.modifieddate = new Date();
    await axios
      .put(
        "role-service-mongo/api/role/v2/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name", width: "15%" },
        { Header: "code", accessor: "rolecode", width: "10%" },
        { Header: "description", accessor: "description" },
        { Header: "created by", accessor: "createdby" },
        { Header: "created date", accessor: "fcreateddate" },
        { Header: "modified by", accessor: "modifiedby" },
        { Header: "action", accessor: "action", width: "12%" },
      ],
      rows: [],
    };
    axios
      .get(
        `role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();
         
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Role Table
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/profile/create-role"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Type List</DialogTitle>
        <DialogContent style={{ width: 550, height: 150 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Role Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Role Description"
              variant="standard"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}
export default RolesListTable;