import { useEffect, useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// @mui material components
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";


const initialValues = {
    state: "",
    district:"",
    subdistricname:"",
    shortname: "",
    subdistriccode:""
}
const Subdistriccomponet = (props) => {
    const { tittle, children,childToSubdistric, openpopupsubdistric, setopenpopupsubdistric } = props
    const [subdistriccode, setSubdistriccode] = useState("");
    const [subdistricname, setSubdistricname] = useState("");
    const [shortname, setShortname] = useState("");
    const [stateValue, setStateValue] = useState(null);
    const [stateList, setStateList] = useState("");
    const [districtValue, setDistrictValue] = useState(null);
    const [districtList, setDistrictList] = useState("");

    function autocompleteValuenull(){
        
        setStateValue(null);
        setDistrictValue(null);
      
      }

    async function createSubdistric(pinCodeObject) {
        axios
            .post(
                "pincode-service/api/pincode/v1/create",
                pinCodeObject
            )
    
          .then((response) => {
            console.log("gfgfgf", response);
    
            setopenpopupsubdistric(false)
            autocompleteValuenull()
            if (response) {
              console.log("40", response.data.data.dto)
              childToSubdistric(response.data.data.dto)
    
            }
    
    
    
    
          })
          .catch((error) => {
            console.log(error);
    
    
          });
    
      }
    

    const defaultPropsState = {
        options: !stateList
            ? [
                {
                    state: {
                        shortname: "- -",
                        name: "Loading...",
                    },
                    id: 0,
                },
            ]
            : stateList,
        getOptionLabel: (option) =>
            option.state?.name + " (" + option.state?.shortname + ")",
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };

    const districtDefaultProp = {
        options: !districtList
            ? [
                {
                    district: {
                        shortname: "- -",
                        name: "Loading...",
                    },
                    id: 0,
                },
            ]
            : districtList,

        getOptionLabel: (option) =>
            option.district?.name + " (" + option.district?.shortname + ")",
        isOptionEqualToValue: (option, value) => option.id === value.id,
    };
    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
          console.log("72", values)
          const pinCodeObject = {
            id: null,
            createdby:
              JSON.parse(localStorage.getItem("mmu-auth")).firstname +
              " " +
              JSON.parse(localStorage.getItem("mmu-auth")).lastname,
      
            modifiedby: "",
            approvedby: "",
      
            recstatus: "OPEN",
            syncstatus: "synced",
            status: "ACTIVE",
            documentofproof: "",
      
            tempid: "",
            state: values.state,
            district: values.district,
            subdistrict: {
              code: values.subdistriccode,
              shortname: values.shortname,
              name: values.subdistricname,
            },
            pincode: values.pincode,
      
            resourcecode: "DPF",
            type: "SUB-DISTRICT",
          };
    
        
      createSubdistric(pinCodeObject)
      swal("Alert!", "...your Sub-Distric has been sucessfully create!", "success")
        .then((value) => {
          // window.location.reload(true)

          action.resetForm();

        });

    }
  })


    const handleClose = () => {
        console.log("dsfsdfs");
        setopenpopupsubdistric(false);
    };

    async function getDistrictData(stateCode) {
        await axios
            .get(
                `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${stateCode}`
            )
            .then((response) => {
                setDistrictList(response?.data?.data?.dtoList);
           
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    async function getData() {
        await axios
            .get(
                `pincode-service/api/pincode/v1/get?type=state`
            )
            .then((response) => {
                setStateList(response?.data?.data?.dtoList);
          
            })

            .catch((e) => {
                console.log("error", e);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Dialog open={openpopupsubdistric} onClose={handleClose} >
            <MDBox mx={2} mt={2}>

                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-2}
                    p={2}
                    mb={-2}
                    textAlign="center"
                >
                    <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                    >
                        Sub-Distric
                    </MDTypography>
                </MDBox>
            </MDBox>
            <form onSubmit={handleSubmit}>
            <DialogContent style={{ width: 550, height: 250 }}>
                <Grid container spacing={3} mt={.5}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            {...defaultPropsState}
                            value={stateValue}
                            onChange={(event, newValue) => {
                                console.log("132",newValue);
                                setStateValue(newValue);
                  // setValue(newValue);
                  getDistrictData(newValue?.state?.code);
                  values.state = newValue?.state;
                               
                            }}
                            renderInput={(params) => (
                                <MDInput
                                    {...params}

                                    type="text"
                            autocomplete="off"
                            name="state"
                            label="State"
                            id="state"
            
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="standard"
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                    <Autocomplete
                {...districtDefaultProp}
                value={districtValue}
                onChange={(event, newValue) => {
                    setDistrictValue(newValue);
                  values.district = newValue?.district;
                 
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                  type="text"
                  autocomplete="off"
                  name="district"
                  id="district"
                    variant="standard"
                    label="District"
                    onChange={handleChange}
                            onBlur={handleBlur}
                  />
                )}
              />
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={.5}>
                    <Grid item xs={12} sm={6}>
                    <MDInput
                            type="text"
                            autocomplete="off"
                            name="subdistricname"
                            id="subdistricname"
                            label="Subdistricname"
                            variant="standard"
                            fullWidth
                            value={values.subdistricname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                       
                         <MDInput
                            type="text"
                            autocomplete="off"
                            name="shortname"
                            id="shortname"
                            label="Shortname"
                            variant="standard"
                            fullWidth
                            value={values.shortname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={3} mt={.5}>
                    <Grid item xs={12} sm={6}>
                    <MDInput
                            type="text"
                            autocomplete="off"
                            name="subdistriccode"
                            id="subdistriccode"
                            label="Subdistriccode"
                            variant="standard"
                            fullWidth
                            value={values.subdistriccode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" >create </Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}




export default Subdistriccomponet;